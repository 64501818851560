import { Avatar, Divider, Grid, IconButton, TextField } from '@mui/material';

import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { createNotes } from '../../../redux/slices/appSlice';
import { addHint } from '../../../redux/slices/hintsSlice';

export default function Notes({
  notes,
  name,
  entity_id,
}: {
  notes: { createdAt: string; text: string; _id?: string | number }[];
  name: 'Vacancy' | 'Applicant';
  entity_id: string | undefined;
}) {
  const dispatch = useAppDispatch();
  const user = useAppSelector((s) => s.user.data);
  const [note, setNote] = useState('');
  const [ns, setNs] = useState(notes);

  useEffect(() => {
    setNs(notes);
  }, [notes, entity_id]);

  const sendNoteHandler = () => {
    if (!(entity_id && name))
      dispatch(
        addHint('The CV of the candidate has not included any summary.')
      );
    dispatch(
      createNotes({
        notes: [
          {
            entity_id,
            collectionName: name,
            text: note,
          },
        ],
      })
    );
    setNote('');
  };
  const sendNoteHandlerKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendNoteHandler();
    }
  };

  return (
    <div style={{ marginTop: '16px', fontSize: '14px' }}>
      <b>Notes</b>
      <Divider sx={{ margin: '8px 0' }} />
      {ns.length > 0 ? (
          ns.map((note, i) => (
              <Grid container style={{ padding: '1rem 0' }} key={note._id || i}>
                <Grid item xs={'auto'} style={{ padding: '0 0 1rem 0' }}>
                  <Avatar
                      alt={(user && user?.name[0] + user?.surname[0]) || 'User'}
                      src="/static/images/avatar/2.jpg"
                  />
                  <Divider
                      orientation="vertical"
                      style={{ marginTop: '2px', marginRight: '49%', height: '60%' }}
                  />
                </Grid>
                <Grid item xs style={{ padding: '0.5rem 0 0 1rem' }}>
                  <div
                      style={{
                        padding: '0 0 1rem 0',
                        fontSize: '12px',
                        fontWeight: '700',
                      }}
                  >
                    {`${user?.name || 'user'}`}
                  </div>
                  <div style={{ padding: '0 0 1rem 0', fontSize: '14px' }}>
                    {`${note.text}`}
                  </div>
                  <div style={{ fontSize: '12px' }}>
                    {moment(note.createdAt).calendar().replaceAll('/', '.')}
                  </div>
                </Grid>
              </Grid>
          ))
      ) : (
          <p>Create your first note here</p>
      )}

      <div
        className="d-flex justify-content-start align-items-end"
        style={{ background: '#F9F9F9', borderRadius: '4px', padding: '2px' }}
      >
        <TextField
          multiline
          size="small"
          sx={{
            width: '100%',
            border: 'none',
            '& fieldset': { border: 'none' },
          }}
          value={note || ''}
          onChange={(e) => setNote(e.target.value)}
          onKeyDown={sendNoteHandlerKeyPress}
        />
        <IconButton aria-label="send" onClick={sendNoteHandler}>
          <SendOutlinedIcon />
        </IconButton>
      </div>
    </div>
  );
}
