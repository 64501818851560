import {
    Button,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    TextField,
} from '@mui/material';
import {ReactComponent as LogoSvg} from '../../../static/Logo.svg';
import useTo from '../../hooks/useTo';
import {useEffect, useState} from 'react';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {useAppDispatch, useAppSelector} from '../../../redux/store';
import {login} from '../../../redux/slices/userSlice';
import validator from 'validator';
import Box from '@mui/material/Box';
import Pop from '../Pop';
import AreYouShure from '../pices/AreYouShure';

export default function Login() {
  const dispatch = useAppDispatch();
  const to = useTo();
  const userData = useAppSelector((state) => state.user.data);
  const [form, setForm] = useState({ email: '', pass: '' });
  const [valid, setValid] = useState({ email: true, pass: true });
  const [showPassword, setShowPassword] = useState(false);

  const loginHandler = () => {
    const [email, pass] = [
      validator.isEmail(form.email),
      !!validator.isStrongPassword(form.pass, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 0,
      }),
    ];
    setValid({ email, pass });
    if (!email || !pass) return;
    dispatch(login({ email: form.email, password: form.pass }));
    // dispatch(setSession({checked: true}));
  };

  useEffect(() => {
    if (userData?.email) to('/dashboard');
  });

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '4rem 0',
        }}
      >
        <Box
          sx={{
            boxShadow: 2,
            borderRadius: 2,
            minWidth: 300,
            maxWidth: 500,
            background: '#ffffff',
          }}
        >
          <Box
            sx={{ p: 4 }}
            style={{ cursor: 'pointer' }}
            onClick={() => to('/welcome')}
          >
            <Box
              justifyContent="flex-start"
              alignItems="center"
              display={'inline-flex'}
            >
              <LogoSvg />
              <span
                className="text"
                style={{
                  paddingLeft: '10px',
                  fontSize: '20px',
                  lineHeight: '24px',
                  letterSpacing: '0.15px',
                  fontWeight: 'bold',
                  color: '#333333',
                }}
              >
                Talent Popcorn
              </span>
            </Box>
          </Box>

          <Divider sx={{ my: 0.5 }} />

          <Box sx={{ p: 4 }}>
            <Grid container rowSpacing={1} columnSpacing={2}>
              <Grid item xs={12}>
                <h1 style={{ fontSize: '1.5rem', padding: 0, margin: 0 }}>
                  Sing In
                </h1>
                <p
                  style={{
                    fontSize: '1rem',
                    color: '#333333',
                    margin: '1rem 0 0 0',
                  }}
                >
                  Please sign in to access the service.
                </p>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="standard"
                  type="email"
                  label="Email"
                  autoComplete="on"
                  placeholder="example@mailbox.com"
                  value={form.email}
                  error={!valid.email}
                  helperText="Your email here"
                  onChange={(e) =>
                    setForm((prev: any) => ({ ...prev, email: e.target.value }))
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth variant="standard" error={!valid.pass}>
                  <InputLabel htmlFor="standard-adornment-password">
                    Password
                  </InputLabel>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    value={form.pass}
                    error={!valid.pass}
                    onChange={(e) =>
                      setForm((prev: any) => ({
                        ...prev,
                        pass: e.target.value,
                      }))
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword((prev: any) => !prev)}
                          onMouseLeave={() => setShowPassword(false)}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText error={!valid.pass}>
                    {valid.pass
                      ? 'Your password here'
                      : "Must be longer than 8 char's and contains lowercase and uppercase letters and numbers"}
                  </FormHelperText>
                </FormControl>
                <p>
                  <a
                    style={{ color: '#333333', cursor: 'pointer' }}
                    href="#"
                    onClick={() => to('recoveryrequest')}
                  >
                    Forgot your password?
                  </a>
                </p>
              </Grid>

              <Grid item sx={{ mt: 2 }} xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={{ fontSize: '1rem' }}
                  onClick={loginHandler}
                >
                  Sign In
                </Button>
              </Grid>

              <Grid item xs={12} sx={{ mt: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Divider sx={{ flex: 1 }} />
                  <div
                    style={{
                      padding: '0 1rem',
                      color: '#999999',
                      fontSize: '0.75rem',
                    }}
                  >
                    Don't have an account?
                  </div>
                  <Divider sx={{ flex: 1 }} />
                </Box>
              </Grid>

              <Grid item xs={12} sx={{ mt: 2 }}>
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  color="warning"
                  sx={{
                    color: '#333333',
                    background: '#F7D446',
                    fontSize: '1rem',
                  }}
                  onClick={() => to('/registration')}
                >
                  Sign Up
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Pop scene="password-reset" dark>
        <AreYouShure
          header={<span className="ntf_header">TALENT POPCORN</span>}
          text={
            <div className="ntf_text">
              <header>Password successfully reset!</header>
              <p>
                You have successfully changed your password. You will be
                redirected to the Login Page shortly. Thank you!
              </p>
            </div>
          }
          btn={null}
        />
      </Pop>
    </>
  );
}
