import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { herald, urls } from '../urls';
import { addProcess, removeProcess } from './praocessSlice';
import { addHint } from './hintsSlice';
import { UserData, getUser, setUser } from './userSlice';

export interface query {
  page?: number;
  size?: number;
  vacancy_id?: string;
  entity_id?: string;
  _id?: string;
  type?: 'text' | 'file' | 'object';
  switch?: string;
  search?: string;
}

/**     *** Workflows ***     */

export const getWorkflows = createAsyncThunk(
  'app/getWorkflows',
  async (query: query | null, { dispatch }) => {
    try {
      const response = await herald(urls.workflows(query || {}));
      dispatch(setLoading(true));
      if (response.status === 403) dispatch(setUser({ email: null }));
      if (!response.ok) throw Error('app/getWorkflows REQUEST ERROR');

      const jsonData = await response.json();

      if (Array.isArray(jsonData) && jsonData.length === 0)
        dispatch(clearAppData());

      return jsonData;
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const createWorkflows = createAsyncThunk(
  'app/createWorkflows',
  async (workflow: Flow) => {
    try {
      const response = await herald(urls.workflows(), {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ workflow }),
      });

      if (!response.ok) throw Error('app/createWorkflows REQUEST ERROR');

      return await response.json();
    } catch (error) {
      console.error(error);
    }
  }
);

export const copyWorkflows = createAsyncThunk(
  'app/copyWorkflows',
  async (_id: string, { dispatch }) => {
    try {
      const response = await herald(urls.workflows(), {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ _id }),
      });

      if (!response.ok) throw Error('app/copyWorkflows REQUEST ERROR');

      const jsonData = await response.json();

      if (Array.isArray(jsonData) && jsonData.length === 0)
        dispatch(clearAppData());

      return jsonData;
    } catch (error) {
      console.error(error);
    }
  }
);

export const removeWorkflows = createAsyncThunk(
  'app/removeWorkflows',
  async (_id: string, { dispatch }) => {
    try {
      const response = await herald(urls.workflows(), {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ _id }),
      });

      if (!response.ok) throw Error('app/removeWorkflows REQUEST ERROR');

      const jsonData = await response.json();

      if (Array.isArray(jsonData) && jsonData.length === 0)
        dispatch(clearAppData());

      return jsonData;
    } catch (error) {}
  }
);

export const getWorkflow = createAsyncThunk(
  'app/getWorkflow',
  async (query: query | null) => {
    try {
      const response = await herald(urls.workflow(query || {}));

      if (!response.ok) throw Error('app/getWorkflow REQUEST ERROR');

      const jsonData = await response.json();

      return jsonData;
    } catch (error) {
      console.error(error);
    }
  }
);

export const createStep = createAsyncThunk(
  'app/createStep',
  async (flow: { _id: string; newFlow: Flow }) => {
    try {
      const response = await herald(urls.workflow(), {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(flow),
      });

      if (!response.ok) throw Error('app/createStep REQUEST ERROR');

      return await response.json();
    } catch (error) {
      console.error(error);
    }
  }
);

export const copyStep = createAsyncThunk(
  'app/copyStep',
  async (flow: { _id: string; newFlow: Flow; stepId: string }) => {
    try {
      const response = await herald(urls.workflow(), {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(flow),
      });

      if (!response.ok) throw Error('app/copyStep REQUEST ERROR');

      return await response.json();
    } catch (error) {
      console.error(error);
    }
  }
);

export const removeStep = createAsyncThunk(
  'app/removeStep',
  async (flow: { _id: string; step: string }) => {
    try {
      const response = await herald(urls.workflow(), {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(flow),
      });

      if (!response.ok) throw Error('app/removeStep REQUEST ERROR');

      return response.json();
    } catch (error) {}
  }
);

export const updateStep = createAsyncThunk(
  'app/updateStep',
  async (flow: { _id: string; updatedFlow: Flow }) => {
    try {
      const response = await herald(urls.workflow(), {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(flow),
      });

      if (!response.ok) throw Error('app/updateStep REQUEST ERROR');

      return await response.json();
    } catch (error) {
      console.error(error);
    }
  }
);

/**     *** Vacancies ***     */

export const getVacancies = createAsyncThunk(
  'app/getVacancies',
  async (query: query | null, { dispatch }) => {
    try {
      const switcher = window.localStorage.getItem('switcher');
      const response = await herald(
        urls.vacancies({ ...query, switch: switcher } || {})
      );
      if (response.status === 403) dispatch(setUser({ email: null }));
      if (!response.ok) throw Error('app/getVacancies REQUEST ERROR');

      dispatch(setLoading(true));

      await dispatch(getUser());

      const jsonData = await response.json();

      if (Array.isArray(jsonData) && jsonData.length === 0)
        dispatch(clearAppData());

      return jsonData;
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const createVacancies = createAsyncThunk(
  'app/createVacancy',
  async (
    { vacancies, query }: { vacancies: any[]; query: query },
    { dispatch }
  ) => {
    try {
      if (!query.type)
        throw new Error(
          'createVacancy ERROR: query must have "type" parameter'
        );
      for (let _ of vacancies) dispatch(addProcess({ key: 'new.vacancy' }));
      let response = null;
      if (query.type === 'object') {
        response = await herald(urls.vacancies(query), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(vacancies),
        });
      }
      if (query.type === 'text') {
        response = await herald(urls.vacancies(query), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(vacancies),
        });
      }
      if (query.type === 'file') {
        const formData = new FormData();
        for (let file of vacancies) formData.append(file.name, file);
        response = await herald(urls.vacancies(query), {
          method: 'POST',
          // headers:{ 'Content-Type': 'multipart/form-data' },
          body: formData,
        });
      }
      for (let _ of vacancies) dispatch(removeProcess({ key: 'new.vacancy' }));
      if (response.status === 403) dispatch(setUser({ email: null }));
      await dispatch(getUser());
      if (!response.ok) throw Error('app/createVacancies REQUEST ERROR');
      return await response.json();
    } catch (error) {}
  }
);

export const updateVacancies = createAsyncThunk(
  'app/updateVacancies',
  async (
    vacancies: { vacancy_id: string; vacancy: object }[],
    { dispatch }
  ) => {
    try {
      const response = await herald(urls.vacancies(), {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(vacancies),
      });
      if (response.status === 403) dispatch(setUser({ email: null }));
      await dispatch(getUser());
      if (!response.ok) throw Error('app/updateVacancies REQUEST ERROR');
      return await response.json();
    } catch (error) {}
  }
);

export const changeVacancy = createAsyncThunk(
  'app/changeVacancy',
  async (vacancy: { _id: string; status: string }, { dispatch }) => {
    try {
      const response = await herald(urls.vacancyChangeStatus(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ _id: vacancy._id, status: vacancy.status }),
      });
      dispatch(getVacancies({}));
      if (response.status === 403) dispatch(setUser({ email: null }));
      await dispatch(getUser());
      if (!response.ok) throw Error('app/changeVacancy REQUEST ERROR');
      return await response.json();
    } catch (error) {}
  }
);

export const favoriteVacancy = createAsyncThunk(
  'app/favoriteVacancy',
  async (vacancy: { _id: string; is_favorite: boolean }, { dispatch }) => {
    try {
      const response = await herald(urls.vacancyIsFavorite(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          _id: vacancy._id,
          is_favorite: vacancy.is_favorite,
        }),
      });
      dispatch(getVacancies({}));
      if (response.status === 403) dispatch(setUser({ email: null }));
      if (!response.ok) throw Error('app/favoriteVacancy REQUEST ERROR');
      return await response.json();
    } catch (error) {}
  }
);

export const removeVacancies = createAsyncThunk(
  'app/removeVacancies',
  async (ids: string[], { dispatch }) => {
    try {
      const response = await herald(urls.vacancies(), {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ vacancies: ids }),
      });
      if (response.status === 403) dispatch(setUser({ email: null }));
      await dispatch(getUser());
      if (!response.ok) throw Error('app/removeVacancies REQUEST ERROR');
      return ids;
    } catch (error) {}
  }
);

/**     *** Applicants ***     */

export const getApplicants = createAsyncThunk(
  'app/getApplicants',
  async (query: query | undefined, { dispatch }) => {
    try {
      if (!query.vacancy_id)
        throw new Error(
          'createVacancy ERROR: query must have "vacancy_id" parameter'
        );

      const response = await herald(urls.applicants(query));
      if (response.status === 403) dispatch(setUser({ email: null }));
      if (!response.ok) throw Error('app/getApplicants REQUEST ERROR');
      return await response.json();
    } catch (error) {}
  }
);

export const createApplicants = createAsyncThunk(
  'app/createApplicants',
  async (
    { applicants, query }: { applicants: any | string[]; query: query },
    { dispatch }
  ) => {
    try {
      if (!query.type || !query.vacancy_id)
        throw new Error(
          'createVacancy ERROR: query must have "type" end "vacancy_id" parameters'
        );
      for (let _ of applicants)
        dispatch(addProcess({ key: query.vacancy_id + '.applicant' }));
      let response = null;
      if (query.type === 'text') {
        response = await herald(urls.applicants(query), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(applicants),
        });
      }
      if (query.type === 'file') {
        const formData = new FormData();
        for (let file of applicants) formData.append(file.name, file);
        response = await herald(urls.applicants(query), {
          method: 'POST',
          // headers:{ 'Content-Type': 'multipart/form-data' },
          body: formData,
        });
      }
      for (let _ of applicants)
        dispatch(removeProcess({ key: query.vacancy_id + '.applicant' }));
      if (response.status === 403) dispatch(setUser({ email: null }));
      if (!response.ok) throw Error('app/createApplicants REQUEST ERROR');
      // return await response.json();
      dispatch(getApplicants({ vacancy_id: query.vacancy_id }));
    } catch (error) {
      // @ts-ignore
      dispatch(
        addHint({
          text: 'oops, failed to create candidate, please try another file or text',
          type: 'info',
        })
      );
    }
  }
);

export const updateApplicants = createAsyncThunk(
  'app/updateApplicants',
  async (
    {
      applicants,
      query,
    }: {
      applicants: {
        applicant_id: string;
        resume?: object;
        applicant?: object;
      }[];
      query?: query;
    },
    { dispatch }
  ) => {
    try {
      //  if(!query.type) throw new Error('createVacancy ERROR: query must have "type" parameter');
      const response = await herald(urls.applicants(query), {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(applicants),
      });
      if (response.status === 403) dispatch(setUser({ email: null }));
      if (!response.ok) throw Error('app/updateApplicants REQUEST ERROR');
      return await response.json();
    } catch (error) {}
  }
);

export const updateScoring = createAsyncThunk(
  'app/updateScoring',
  async (
    { resume_id, vacancy_id }: { resume_id: string; vacancy_id: string },
    { dispatch }
  ) => {
    try {
      dispatch(setLoading(true));
      const response = await herald(urls.scoring(), {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ resume_id, vacancy_id }),
      });

      if (response.status === 403) dispatch(setUser({ email: null }));
      if (!response.ok) throw Error('app/updateScoring REQUEST ERROR');

      return await response.json();
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const favoriteApplicant = createAsyncThunk(
  'app/favoriteApplicant',
  async (applicant_id: string, { dispatch }) => {
    try {
      //  if(!query.type) throw new Error('createVacancy ERROR: query must have "type" parameter');
      const asyncRequest = async () => {
        const response = await herald(urls.favorites(), {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ applicant_id }),
        });
        if (response.status === 403) dispatch(setUser({ email: null }));
        if (!response.ok) throw Error('app/favoriteApplicant REQUEST ERROR');
      };
      asyncRequest(); // потому что нам не нужно дожитдаться ответа что бы поменять состояние
      return { applicant_id };
    } catch (error) {}
  }
);

export const removeApplicants = createAsyncThunk(
  'app/removeApplicants',
  async (
    { ids, vacancy_id }: { ids: string[]; vacancy_id: string },
    { dispatch }
  ) => {
    try {
      const response = await herald(urls.applicants(), {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ applicants: ids }),
      });
      if (response.status === 403) dispatch(setUser({ email: null }));
      if (!response.ok) throw Error('app/removeApplicants REQUEST ERROR');
      return { ids, vacancy_id };
    } catch (error) {}
  }
);

/**     *** Notes ***     */

export const getNotes = createAsyncThunk(
  'app/getNotes',
  async (query: query | undefined, { dispatch }) => {
    // { entity_id }
    try {
      const response = await herald(urls.notes(query));
      if (response.status === 403) dispatch(setUser({ email: null }));
      if (!response.ok) throw Error('app/getNotes REQUEST ERROR');
      return { notes: await response.json(), vacancy_id: query.vacancy_id };
    } catch (error) {}
  }
);

export const createNotes = createAsyncThunk(
  'app/createNotes',
  async (
    {
      notes,
      query,
    }: {
      notes: {
        entity_id: string;
        collectionName: 'Vacancy' | 'Applicant';
        text: string;
      }[];
      query?: query;
    },
    { dispatch }
  ) => {
    try {
      const response = await herald(urls.notes(query), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(notes),
      });
      if (response.status === 403) dispatch(setUser({ email: null }));
      if (!response.ok) throw Error('app/createNotes REQUEST ERROR');
      return { notes: await response.json() };
    } catch (error) {}
  }
);

export const updateNotes = createAsyncThunk(
  'app/updateNotes',
  async (notes: { _id: string; text: string }[], { dispatch }) => {
    try {
      const response = await herald(urls.notes(), {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ notes }),
      });
      if (response.status === 403) dispatch(setUser({ email: null }));
      if (!response.ok) throw Error('app/updateNotes REQUEST ERROR');
      return { notes: await response.json() };
    } catch (error) {}
  }
);

export const removeNotes = createAsyncThunk(
  'app/removeNotes',
  async ({ notes }: { notes: string[] }, { dispatch }) => {
    // notes = [...ids]
    try {
      const response = await herald(urls.notes(), {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ notes }),
      });
      if (response.status === 403) dispatch(setUser({ email: null }));
      if (!response.ok) throw Error('app/removeNotes REQUEST ERROR');
      return { notes };
    } catch (error) {}
  }
);

/**     *** SLICE ***     */
export interface Flow {
  _id?: string;
  name: string;
  description?: string;
  user_id?: UserData;
  system: boolean;
  vacancies?: any[];
  flow: {
    code: string;
    type: string;
    description?: string;
    name: string;
    editable: boolean;
    _id?: string;
  }[];
}

export interface workState {
  vacancies: any[];
  workflows: Flow[];
  workflow?: Flow;
  loading: boolean;
}

const initialState: workState = {
  vacancies: [],
  workflows: [],
  workflow: undefined,
  loading: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setStats: (
      state,
      action: PayloadAction<{ vacancy_id: string; stats: string[] }>
    ) => {
      if (!state.vacancies) return;
      const { vacancy_id, stats } = action.payload;
      state.vacancies = [
        ...state.vacancies.map((v) =>
          v._id !== vacancy_id ? v : { ...v, stats }
        ),
      ];
    },
    clearAppData: (state) => {
      state.vacancies = [];
    },
    clearWorkflow: (state) => {
      state.workflow = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkflow.fulfilled, (state, action) => {
        if (action.payload) state.workflow = action.payload;
        else console.log('getWorkflow incorect action.payload');
      })
      .addCase(copyStep.fulfilled, (state, action) => {
        if (action.payload) state.workflow = action.payload;
        else console.log('copyStep incorect action.payload');
      })
      .addCase(createStep.fulfilled, (state, action) => {
        if (action.payload) state.workflow = action.payload;
        else console.log('createStep incorect action.payload');
      })
      .addCase(updateStep.fulfilled, (state, action) => {
        if (action.payload) state.workflow = action.payload;
        else console.log('updateStep incorect action.payload');
      })
      .addCase(removeStep.fulfilled, (state, action) => {
        if (action.payload) state.workflow = action.payload;
        else console.log('removeStep incorect action.payload');
      })
      .addCase(getWorkflows.fulfilled, (state, action) => {
        if (action.payload?.length) state.workflows = action.payload;
        else console.log('getWorkflows incorect action.payload');
      })
      .addCase(copyWorkflows.fulfilled, (state, action) => {
        if (action.payload?.length) state.workflows = action.payload;
        else console.log('copyWorkflows incorect action.payload');
      })
      .addCase(removeWorkflows.fulfilled, (state, action) => {
        if (action.payload?.length) state.workflows = action.payload;
        else console.log('removeWorkflows incorect action.payload');
      })
      .addCase(getVacancies.fulfilled, (state, action) => {
        if (action.payload?.length) state.vacancies = action.payload;
        else console.log('getVacancies incorect action.payload');
      })
      .addCase(createVacancies.fulfilled, (state, action) => {
        if (action.payload?.length)
          state.vacancies = [...state.vacancies, ...action.payload];
        else console.log('createVacancies incorect action.payload');
      })
      .addCase(updateVacancies.fulfilled, (state, action) => {
        if (action.payload?.length) {
          state.vacancies = state.vacancies.map((v) => ({
            ...v,
            ...(action.payload.find((p) => p._id === v._id) || {}),
          }));
        } else console.log('updateVacancies incorect action.payload');
      })
      .addCase(removeVacancies.fulfilled, (state, action) => {
        if (action.payload?.length)
          state.vacancies = [
            ...state.vacancies.filter((el) => !action.payload.includes(el._id)),
          ];
        else console.log('removeVacancies incorect action.payload');
      })
      .addCase(getApplicants.fulfilled, (state, action) => {
        if (action.payload?.length && action.payload[0].vacancy_id)
          state.vacancies = [
            ...state.vacancies.map((v) =>
              v._id !== action.payload[0].vacancy_id
                ? v
                : { ...v, applicants: action.payload }
            ),
          ];
        else
          console.log('getApplicants incorect action.payload', action.payload);
      })
      .addCase(createApplicants.fulfilled, (state, action) => {
        // if(action.payload?.length && action.payload[0]?.vacancy_id)
        //   state.vacancies = [...state.vacancies.map(v => v._id !== action.payload[0].vacancy_id? v : {
        //     ...v,
        //     applicants: [...(v.applicants || []), ...action.payload]
        //   })];
        // else console.log('getApplicants incorect action.payload');
        // TODO изза дедубликации мы не добовляем нового апликанта а запрашиваем всех снова. можно оптимизировать проверяя кандидата на месте
      })
      .addCase(updateApplicants.fulfilled, (state, action) => {
        if (action.payload?.length && action.payload[0].vacancy_id) {
          state.vacancies = [
            ...state.vacancies.map((v) =>
              v._id !== action.payload[0].vacancy_id
                ? v
                : {
                    ...v,
                    applicants: v.applicants.map(
                      (a) => action.payload.find((p) => p._id === a._id) || a
                    ),
                  }
            ),
          ];
        } else console.log('getApplicants incorect action.payload');
      })
      .addCase(favoriteApplicant.fulfilled, (state, action) => {
        if (!!action.payload?.applicant_id) {
          state.vacancies = [
            ...state.vacancies.map((v) => ({
              ...v,
              applicants: v.applicants?.map((a) =>
                action.payload?.applicant_id !== a._id
                  ? a
                  : { ...a, favorite: !a.favorite }
              ),
            })),
          ];
        } else console.log('getApplicants incorect action.payload');
      })
      .addCase(removeApplicants.fulfilled, (state, action) => {
        if (action.payload?.ids?.length && action.payload.vacancy_id) {
          state.vacancies = [
            ...state.vacancies.map((v) =>
              v._id !== action.payload.vacancy_id
                ? v
                : {
                    ...v,
                    applicants: v.applicants.filter(
                      (a) => !action.payload.ids.includes(a._id)
                    ),
                  }
            ),
          ];
        } else console.log('getApplicants incorect action.payload');
      })
      .addCase(getNotes.fulfilled, (state, action) => {
        if (
          action.payload?.notes?.length &&
          action.payload.notes[0]._id &&
          action.payload.vacancy_id
        ) {
          const notes = action.payload?.notes;
          state.vacancies = [
            ...state.vacancies.map((v) =>
              v._id !== action.payload?.vacancy_id
                ? v
                : {
                    ...v,
                    notes: notes.filter((n) => n.entity_id === v._id),
                    applicants: (v.applicants || []).map((a) => ({
                      ...a,
                      notes: notes.filter((n) => n.entity_id === a._id),
                    })),
                  }
            ),
          ];
        }
      })
      .addCase(createNotes.fulfilled, (state, action) => {
        if (action.payload?.notes?.length && action.payload.notes[0]._id) {
          const notes = action.payload?.notes;
          state.vacancies = [
            ...state.vacancies.map((v) => ({
              ...v,
              notes: [
                ...(v.notes || []),
                ...notes.filter((n) => n.entity_id === v._id),
              ],
              applicants: (v.applicants || []).map((a) => ({
                ...a,
                notes: [
                  ...(a.notes || []),
                  ...notes.filter((n) => n.entity_id === a._id),
                ],
              })),
            })),
          ];
        } else console.log('createNotes incorect action.payload');
      })
      .addCase(updateNotes.fulfilled, (state, action) => {
        if (action.payload?.notes?.length && action.payload.notes[0]._id) {
          const notes = action.payload?.notes;
          state.vacancies = [
            ...state.vacancies.map((v) => ({
              ...v,
              notes: notes.filter((n) => n.entity_id === v.id),
              // applicants: (v.applicants || []).map(a => ({...a, notes: notes.filter(n => n.entity_id === a._id)}))
            })),
          ];
        } else console.log('updateNotes incorect action.payload');
      })
      .addCase(removeNotes.fulfilled, (state, action) => {
        if (
          action.payload?.notes?.length &&
          typeof action.payload.notes[0] === 'string'
        ) {
          const notes = action.payload?.notes;
          state.vacancies = [
            ...state.vacancies.map((v) => ({
              ...v,
              notes: notes.filter((n) => n !== v.id),
              applicants: (v.applicants || []).map((a) => ({
                ...a,
                notes: notes.filter((n) => n !== a._id),
              })),
            })),
          ];
        } else console.log('updateNotes incorect action.payload');
      });
  },
});

export const { setStats, clearAppData, clearWorkflow, setLoading } =
  appSlice.actions;
export default appSlice;
