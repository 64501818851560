import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import styles from './TariffSelection.module.css';
import useTo from '../../../hooks/useTo';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import ErrorIcon from '@mui/icons-material/Error';

export const TariffsError: React.FC<{
  close?: () => void;
}> = ({ close = () => {} }) => {
  const to = useTo();

  return (
    <div className={styles.content_wrap}>
      <div className={styles.buttons_wrap}>
        <Button
          color="inherit"
          endIcon={<CloseIcon />}
          onClick={() => close()}
          sx={{ fontWeight: 'normal', textTransform: 'none' }}
        >
          Close
        </Button>
      </div>
      <div className={styles.tariff_error}>
        <p>
          <ErrorIcon />
          Payment service currently unavailable, please try later or{' '}
          <a href="mailto:support@talentpopcorn.ai">contact support</a>.
        </p>
        <Button
          variant="contained"
          className={styles.btn_yellow}
          startIcon={<KeyboardReturnIcon />}
          onClick={() => to('/dashboard')}
        >
          Return
        </Button>
      </div>
    </div>
  );
};
