
import useTo from "../../hooks/useTo";
import { useEffect } from "react";

export default function Welcome(){
    const to = useTo()

    useEffect(()=> {
        to('/login');
    })
   
    return <></>
}