import Pop from '../components/Pop';
import {Tariffs} from '../components/pices/tariff-selection/Tariffs';
import {TariffsError} from '../components/pices/tariff-selection/TariffsError';

export const Popups = () => {
  return (
    <>
      <Pop
        scene="tariff-selection-error"
        dark
        top="0"
        right="0"
        left="0"
        bottom="0"
      >
        <TariffsError />
      </Pop>

      <Pop scene="tariff-selection" dark top="0" right="0" left="0" bottom="0">
        <Tariffs
          title={'Pricing Table'}
          subscription={
            'You don\'t have an active subscription yet. Select one of our subscription plans to unlock more features with Talent Popcorn.'
          }
        />
      </Pop>
    </>
  );
};
