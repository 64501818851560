import { Card } from '@mui/material';
import React from 'react';
import useWidth from '../hooks/useWidth';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 *
 * @param props - component props
 * @param props.scene - //url?scene=<nameOfScene>
 * @param props.children - react.component.children
 * @param props.dark - fore focus to content
 * @param props.top - css for indent from top or "centerFore400" for children hieght 400px
 * @param props.right - css for indent from right or "centerFore400" for children width 400px
 * @param props.bottom - css for indent from bottom or "centerFore400" for children hieght 400px
 * @param props.left - css for indent from left or "centerFore400" for children width 400px
 * @param props.locked - locked if cannot be closed without selecting an option
 * @returns
 */
export default function Pop({
  scene,
  children,
  dark,
  top,
  right,
  left,
  bottom,
  locked = false,
}: {
  scene: string;
  children: any;
  dark?: boolean;
  top?: string;
  right?: string;
  left?: string;
  bottom?: string;
  locked?: boolean;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [vw, vh] = useWidth();

  const { scene: q } = Object.fromEntries(new URLSearchParams(location.search));
  const position: any = {};

  const calculatePos = (param, vw, w) =>
    param.includes('centerFore') ? `${(vw - (vw > w ? w : vw)) / 2}px` : param;

  if (right)
    position.right = calculatePos(
      right,
      vw,
      Number(right.replace('centerFore', ''))
    );
  if (left)
    position.left = calculatePos(
      left,
      vw,
      Number(left.replace('centerFore', ''))
    );
  if (top)
    position.top = calculatePos(top, vh, Number(top.replace('centerFore', '')));
  if (bottom)
    position.bottom = calculatePos(
      bottom,
      vh,
      Number(bottom.replace('centerFore', ''))
    );

  const closeOrOpenPop = () => {
    const queryParams = new URLSearchParams(location.search);
    const existingParams = new URLSearchParams(queryParams.toString());

    if (existingParams.get('scene') === scene) {
      document.body.style.overflow = '';
      existingParams.delete('scene');
    } else {
      existingParams.set('scene', scene);
    }
    navigate(`${location.pathname}?${existingParams}`);
  };

  const stylePopup = top
    ? { ...position, borderRadius: 'unset' }
    : {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: 'fit-content',
        right: 0,
        width: 'fit-content',
        borderRadius: '8px',
      };

  return (
    <>
      {q === scene && (
        <div
          style={{
            position: 'fixed',
            left: 0,
            top: 0,
            width: '99.99vw',
            height: '99.99vh',
            background: dark ? 'rgba(99,99,99,0.7)' : 'rgba(0,0,0,0)',
            zIndex: 9999,
            overflow: 'auto',
          }}
          onClick={() => {
            if (!locked) closeOrOpenPop();
          }}
        >
          <div
            style={{
              position: 'fixed',
              overflowY: 'unset',
              ...stylePopup,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Card variant="outlined">
              {React.cloneElement(
                children,
                { ...children.props, close: closeOrOpenPop },
                children.children
              )}
            </Card>
          </div>
        </div>
      )}
    </>
  );
}
