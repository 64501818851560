import CloseIcon from '@mui/icons-material/Close';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Button, Card, CardActions, CardContent } from '@mui/material';
import styles from './TariffSelection.module.css';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import {
  createPaymentIntent,
  createPaymentSession,
} from '../../../../redux/slices/userSlice';
import useTo from '../../../hooks/useTo';

const TARIFF_STRIPE_QUATERLY = process.env.REACT_APP_STRIPE_TARIFF_QUATERLY;
const TARIFF_STRIPE_MOUNTHLY = process.env.REACT_APP_STRIPE_TARIFF_MOUNTHLY;

export const Tariffs: React.FC<{
  title: string;
  subscription: string;
  close?: () => void;
}> = ({ title, subscription, close = () => {} }) => {
  const dispatch = useAppDispatch();
  const to = useTo();
  const user = useAppSelector((state) => state.user.data);
  const tariffs = [
    {
      title: 'Quarterly',
      description:
        'Early Bird Professional Subscription. Save 25$ and get full professional access.',
      cost: 125,
      period: '/ quarterly',
      includeTitle: 'Includes',
      includes: [
        'Unlimited vacancies',
        'Unlimited candidates',
        'All features access',
        'Direct Support',
        'Developer resources',
      ],
      button: 'SUBSCRIBE',
      price: TARIFF_STRIPE_QUATERLY,
    },
    {
      recommended: 'recommended',
      title: 'Monthly',
      description:
        'Early Bird Professional Subscription. You will get full professional access to the Talent Popcorn.',
      cost: 50,
      period: '/ monthly',
      includeTitle: 'Includes',
      includes: [
        'Unlimited vacancies',
        'Unlimited candidates',
        'All features access',
        'Direct Support',
        'Developer resources',
      ],
      button: 'SUBSCRIBE',
      price: TARIFF_STRIPE_MOUNTHLY,
    },
    {
      title: 'Enterprise',
      description:
        'We provide high scalable enterprise solutions with custom integrations for your business needs. Contact us to discuss.',
      includeTitle: 'Opportunities',
      includes: [
        'Exclusive integration with your ATS, HRMS, Job Boards and other',
        'Project Team',
        'Extra features access',
        'Customer Support',
        'White Label',
      ],
      button: 'Contact US',
    },
  ];

  const handlerManageSubscription = () => {
    dispatch(createPaymentSession({ _id: user._id }));
    // close();
  };

  const handlerTariffSubscription = (price: string) => {
    dispatch(
      createPaymentIntent({
        _id: user._id,
        price,
        quantity: '1',
      })
    );
  };

  return (
    <div className={styles.content_wrap}>
      <div className={styles.buttons_wrap}>
        <Button
          color="inherit"
          startIcon={<CreditCardIcon />}
          onClick={handlerManageSubscription}
          sx={{ fontWeight: 'normal', textTransform: 'none' }}
          disabled={!!!user?.is_subscription_active}
        >
          Manage Subscription
        </Button>
        <span> | </span>
        <Button
          color="inherit"
          endIcon={<CloseIcon />}
          onClick={() => close()}
          sx={{ fontWeight: 'normal', textTransform: 'none' }}
        >
          Close
        </Button>
      </div>
      <title>{title}</title>
      <p>{subscription}</p>
      <section className="wrapper">
        {tariffs.map((item, index) => (
          <Card
            variant="outlined"
            key={`${item.title}-${index}`}
            className={styles.card}
            style={item.recommended && { background: '#f7d446' }}
          >
            <CardContent>
              {item.recommended && (
                <div className={styles.mark}>{item.recommended}</div>
              )}
              <title>{item.title}</title>
              <p className={styles.description}>{item.description}</p>
              {item.cost && (
                <div className={styles.cost}>
                  <span>${item.cost}</span> {item.period}
                </div>
              )}
              <div className={styles.include_title}>{item.includeTitle}</div>
              <div>
                {item.includes.map((el, index) => (
                  <p key={index}>{el}</p>
                ))}
              </div>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                className={styles.btn}
                style={
                  item.recommended
                    ? {
                        backgroundColor: '#fff',
                        border: 'none',
                      }
                    : {
                        backgroundColor: '#F7D446',
                      }
                }
                onClick={() =>
                  item?.price
                    ? handlerTariffSubscription(item.price)
                    : to('/contact-us')
                }
              >
                {item.button}
              </Button>
            </CardActions>
          </Card>
        ))}
      </section>
    </div>
  );
};
