import { Button, Grid, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { BaseSyntheticEvent, useState } from 'react';
import { useAppDispatch } from '../../../../../redux/store';
import { getWorkflows, query } from '../../../../../redux/slices/appSlice';
import { useDebouncedCallback } from 'use-debounce';
import useTo from '../../../../hooks/useTo';

export const SearchFlowBlock: React.FC<{}> = ({}) => {
  const to = useTo();
  const dispatch = useAppDispatch();
  const debouncedelay = 1000;
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);
  const [searchWorkflows, setSearchWorkflows] = useState<any>();

  /** Search */
  const handleChange = (params: query) => {
    dispatch(getWorkflows(params));
  };

  const handleSearchChange = (e: BaseSyntheticEvent) => {
    handleChange({ search: e.target.value });
  };

  const handleChangeDebounce = useDebouncedCallback(
    handleSearchChange,
    debouncedelay
  );

  const handleTextFieldFocus = () => {
    setIsTextFieldFocused(true);
  };

  const handleTextFieldBlur = () => {
    setIsTextFieldFocused(false);
  };

  const handleEnterKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter' && isTextFieldFocused) {
      event.preventDefault();
      handleChange({ search: searchWorkflows });
    }
  };

  return (
    <Grid container gap={2}>
      <Grid item xs>
        <TextField
          id="outlined-multiline-flexible"
          label={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '0.75rem',
              }}
            >
              <SearchIcon sx={{ mr: '8px' }} />
              <span>Search through vacancies</span>
            </div>
          }
          multiline
          size="small"
          sx={{ width: '100%', backgroundColor: 'white' }}
          defaultValue={searchWorkflows}
          onChange={(e) => {
            setSearchWorkflows(e.target.value);
            handleChangeDebounce(e);
          }}
          onFocus={handleTextFieldFocus}
          onBlur={handleTextFieldBlur}
          onKeyDown={handleEnterKeyPress}
        />
      </Grid>
      <Grid item>
        <Button
          variant="text"
          startIcon={<AddCircleIcon />}
          onClick={() => to('/workflows/new-workflow')}
        >
          New Workflow
        </Button>
      </Grid>
    </Grid>
  );
};
