import SearchIcon from '@mui/icons-material/Search';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import HdrAutoIcon from '@mui/icons-material/HdrAuto';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SortIcon from '@mui/icons-material/Sort';
import {
  Button,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { createApplicants } from '../../../redux/slices/appSlice';
import { BaseSyntheticEvent, useEffect, useState } from 'react';
import DropFilesWidget from './DropFilesWidget';
import useTo from '../../hooks/useTo';
import { DataType } from './VacancyCard';
import { VacancyPopups } from './VacancyPopups';
import { useDebouncedCallback } from 'use-debounce';

export const FiltersBlockVacancy: React.FC<{
  data: DataType;
  onSwitch: (value: boolean) => void;
  onSearch: (value: object) => void;
  vacancyUpdate: (value: boolean) => void;
  flowTableProps: any;
  setFlowTableProps: React.Dispatch<
    React.SetStateAction<{
      applicants: any;
      vacancy: any;
    }>
  >;
}> = ({
  data,
  onSearch,
  onSwitch,
  vacancyUpdate,
  flowTableProps,
  setFlowTableProps,
}) => {
  const user = useAppSelector((state) => state.user.data);
  const switcher = window.localStorage.getItem('switcher-declined');
  const dispatch = useAppDispatch();
  const debouncedelay = 1000;
  const [searchText, setSearchText] = useState<any>();
  const [switched, setSwitched] = useState(switcher === 'true');
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);
  const to = useTo();
  const vacancy_id = data._id;

  /** Switch */
  useEffect(() => {
    window.localStorage.setItem(
      'switcher-declined',
      switched ? 'true' : 'false'
    );
    onSwitch(switched);
  }, [switched]);

  /** Search */
  const handleTextFieldFocus = () => {
    setIsTextFieldFocused(true);
  };

  const handleTextFieldBlur = () => {
    setIsTextFieldFocused(false);
  };

  const handleSearchChange = (e: BaseSyntheticEvent) => {
    onSearch((p: any) => ({
      ...p,
      search: [e.target.value],
    }));
  };

  const handleChangeDebounce = useDebouncedCallback(
    handleSearchChange,
    debouncedelay
  );

  const handleEnterKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter' && isTextFieldFocused) {
      event.preventDefault();
      if (!!searchText) {
        onSearch((p: any) => ({
          ...p,
          search: [searchText],
        }));
      }
    }
  };

  /** menu */
  const openPop = (scene, options = {}) => {
    to(null, { scene, ...options, vacancy_id });
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = (key) => {
    switch (key) {
      case 'Active':
        openPop(`active-vacancy-${data._id}`);
        break;
      case 'Delete':
        openPop(`delete-vacancy-${data._id}`);
        break;
      case 'Edit':
        openPop(`update-vacancy-${data._id}`);
        break;
      case 'Settings':
        to(`/workflows/:${data.flow_id}`);
        break;
      case 'Cancel':
        openPop(`cancel-vacancy-${data._id}`);
        break;
      case 'Complete':
        openPop(`complete-vacancy-${data._id}`);
        break;
      default:
        break;
    }
    setAnchorEl(null);
  };

  /** download file */
  /*
  const loadFilesHandler = (files, clear) => {
    dispatch(
      createApplicants({
        query: { type: 'file', vacancy_id } as query,
        applicants: files,
      })
    );
    clear();
  };
*/

  const loadFilesHandler = async (files, clear) => {
    for (const file of files) {
      try {
        dispatch(
          createApplicants({
            query: { type: 'file', vacancy_id },
            applicants: [file],
          })
        );
      } catch (error) {
        console.error('Ошибка при загрузке файла: ', error);
      }
    }
    clear();
  };

  /** Sort menu */
  const [anchorSortEl, setAnchorSortEl] = useState<null | HTMLElement>(null);

  const handleOpenSortMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorSortEl(event.currentTarget);
  };

  const handleCloseSortMenu = (key) => {
    let sortedApplicants = [...flowTableProps.applicants];

    switch (key) {
      case 'name-asc':
        sortedApplicants.sort((a, b) =>
          `${a.resume.personal.first_name} ${a.resume.personal.last_name}`.localeCompare(
            `${b.resume.personal.first_name} ${b.resume.personal.last_name}`
          )
        );
        break;
      case 'name-desc':
        sortedApplicants.sort((a, b) =>
          `${b.resume.personal.first_name} ${b.resume.personal.last_name}`.localeCompare(
            `${a.resume.personal.first_name} ${a.resume.personal.last_name}`
          )
        );
        break;
      case 'score-asc':
        sortedApplicants.sort(
          (a, b) => a.scoring.general_score - b.scoring.general_score
        );
        break;
      case 'score-desc':
        sortedApplicants.sort(
          (a, b) => b.scoring.general_score - a.scoring.general_score
        );
        break;
      case 'recent':
        sortedApplicants.sort(
          (a, b) =>
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
        break;
      default:
        // sortedApplicants.sort((a, b) => a._id.localeCompare(b._id));
        sortedApplicants.sort(
          (a, b) => b.scoring.general_score - a.scoring.general_score
        );
        break;
    }

    setFlowTableProps({
      ...flowTableProps,
      applicants: sortedApplicants,
    });

    setAnchorSortEl(null);
  };

  return (
    <Grid container gap={2}>
      <Grid item xs>
        <TextField
          id="outlined-multiline-flexible"
          label={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '0.75rem',
              }}
            >
              <SearchIcon sx={{ mr: '8px' }} />
              <span>Search name, email, or etc.</span>
            </div>
          }
          multiline
          size="small"
          sx={{ width: '100%', backgroundColor: 'white' }}
          defaultValue={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            handleChangeDebounce(e);
          }}
          onFocus={handleTextFieldFocus}
          onBlur={handleTextFieldBlur}
          onKeyDown={handleEnterKeyPress}
        />
      </Grid>
      {/*<Grid item xs="auto">
        <FormControlLabel
          control={
            <Switch
              checked={switched}
              onChange={(e) => setSwitched(e.target.checked)}
            />
          }
          label="Hide Declined"
        />
      </Grid>*/}
      <Grid item>
        <Button
          variant="text"
          color="inherit"
          onClick={handleOpenSortMenu}
          sx={{ textTransform: 'unset' }}
          startIcon={<SortIcon />}
        >
          Sort
        </Button>
        <Menu
          id="sort_menu"
          anchorEl={anchorSortEl}
          open={Boolean(anchorSortEl)}
          onClose={handleCloseSortMenu}
          sx={{ zIndex: 11 }}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }}
        >
          <MenuItem onClick={() => handleCloseSortMenu('name-asc')}>
            <ListItemText>Name (asc)</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleCloseSortMenu('name-desc')}>
            <ListItemText>Name (desc)</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleCloseSortMenu('score-asc')}>
            <ListItemText>Score (asc)</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleCloseSortMenu('score-desc')}>
            <ListItemText>Score (desc)</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleCloseSortMenu('recent')}>
            <ListItemText>Recent</ListItemText>
          </MenuItem>
        </Menu>
      </Grid>
      {/* <Grid item>
        <Button
          variant="text"
          color="inherit"
          sx={{ padding: '10px', minWidth: 'unset' }}
          size="large"
          onClick={() => {}}
        >
          <Arrows />
        </Button>
      </Grid> */}
      <Grid item>
        {user.free_candidate_limit_reached ? (
          <Button
            variant="contained"
            startIcon={<UploadFileIcon />}
            onClick={() => openPop('tariff-selection')}
          >
            New Candidate
          </Button>
        ) : (
          <DropFilesWidget text="New Candidate" onLoad={loadFilesHandler} />
        )}
      </Grid>
      <Grid item sx={{ position: 'relative' }}>
        <Button
          variant="contained"
          color="inherit"
          sx={{ padding: '6px', minWidth: 'unset' }}
          onClick={handleOpenMenu}
        >
          <MoreHorizIcon />
        </Button>
        <Menu
          id="menuCard"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseUserMenu}
          sx={{ zIndex: 11 }}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }}
        >
          <MenuItem onClick={() => handleCloseUserMenu('Edit')}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleCloseUserMenu('Settings')}>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Settings</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => handleCloseUserMenu('Active')}>
            <ListItemIcon>
              <HdrAutoIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Active</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleCloseUserMenu('Cancel')}>
            <ListItemIcon>
              <CancelIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Cancel</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleCloseUserMenu('Complete')}>
            <ListItemIcon>
              <CheckCircleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Complete</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => handleCloseUserMenu('Delete')}>
            <ListItemIcon>
              <DeleteForeverIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Menu>
      </Grid>

      <VacancyPopups data={data} vacancyUpdate={vacancyUpdate} />
    </Grid>
  );
};
