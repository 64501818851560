import data from './sepData';

const mode = process.env.REACT_APP_MODE;
const origin =
  mode === 'dev'
    ? process.env.REACT_APP_DEV_ORIGIN
    : mode === 'prod'
      ? process.env.REACT_APP_ORIGIN
      : null;

export const urls = {
  signup: () => `user/signup`,
  login: () => `user/login`,
  logout: () => `user/logout`,
  getUser: () => `user/auth`,
  generateApiKey: () => `user/generate-api-key`,
  getUserStatus: (id: string) => `user/status?_id=${id}`,
  editUserData: () => `user/edit-user-data`,
  changeAvatar: () => `user/avatar`,
  changeNotifications: () => `user/change-notifications`,
  changePassword: () => `user/change-password`,
  deleteAccount: () => `email/delete-account`,
  downloadAccount: () => `email/download-account`,
  createPaymentIntent: () => `stripe/create-payment-intent`,
  createPaymentSession: () => `stripe/create-session`,
  local: () => `local`,
  requestOnResetPassword: () => `email/password-reset`,
  resetPassword: () => `email/password-changed`,
  scoring: () => `applicants/scoring`,
  example: (query: any) =>
    `example/try?${new URLSearchParams(
      Object.entries(query || {})
    ).toString()}`,
  vacancies: (query?: any) =>
    `vacancies/list?${new URLSearchParams(
      Object.entries(query || {})
    ).toString()}`,
  workflows: (query?: any) =>
    `workflows/list?${new URLSearchParams(
      Object.entries(query || {})
    ).toString()}`,
  workflow: (query?: any) =>
    `workflows/flow${
      query
        ? `?${new URLSearchParams(Object.entries(query || {})).toString()}`
        : ''
    }`,
  vacancyChangeStatus: () => `vacancies/change-status`,
  vacancyIsFavorite: () => `vacancies/favorite-status`,
  applicants: (query?: any) =>
    `applicants/index?${new URLSearchParams(
      Object.entries(query || {})
    ).toString()}`,
  favorites: (query?: any) =>
    `applicants/favorite?${new URLSearchParams(
      Object.entries(query || {})
    ).toString()}`,
  feedback: (query?: any) =>
    `feedback/index?${new URLSearchParams(
      Object.entries(query || {})
    ).toString()}`,
  notes: (query?: any) =>
    `notes/index?${new URLSearchParams(
      Object.entries(query || {})
    ).toString()}`,
};

/** 
 * herald - fetch owerwrite -
    The person who reports the news to the king is called the herald or herald of the royal court. 
    Heralds were a common practice in many historical monarchies to keep the king informed of events, news, and various messages. 
    They acted as a liaison between the ruler and the people, conveying decisions and orders from the king, 
    as well as passing reports and information to him.
*/
export const herald = async (url: string, options?: any): Promise<Response> => {
  if (mode === 'sep') {
    // в этом шфе мог бы импортироваться Mirage.js а не это вот все, но он так же не поддерживает заголовки
    const method = options?.method?.toUpperCase() || 'GET';
    const [uri, query, body] = [
      ...url.split('?'),
      options?.body ? JSON.parse(options.body) : null,
    ];
    // @ts-ignore
    const result = data[`[${method}]${uri}`]
      ? await data[uri]({ query, body })
      : null;
    if (result)
      if (result.body)
        return new Response(
          JSON.stringify(result.body),
          (({ body, ...r }) => r)(result)
        );
      else return new Response(result);
    return new Response('not found', { status: 404 });
  }
  return await fetch(`${origin}/${url}`, {
    credentials: 'include',
    ...options,
  });
};
