import { useNavigate } from 'react-router';
import { languages } from '../../redux/slices/localeSlice';
import { store } from '../../redux/store';

export default function useTo() {
  const navigate = useNavigate();
  const locale = store.getState().locale.lang;

  return (path: string | null, query?: object, add?: boolean) => {
    if (path && path?.[0] !== '/') path = '/' + path;

    navigate({
      pathname: !path
        ? window.location.pathname
        : window.location.pathname !== '/'
          ? `/${window.location.pathname.split('/')[1]}${path}`
          : `/${locale || languages[0]}${path}`,
      search: add
        ? `?${new URLSearchParams({
            ...Object.fromEntries(new URLSearchParams(window.location.search)),
            ...query,
          } as Record<string, string>).toString()}`
        : `?${new URLSearchParams(query as Record<string, string>).toString()}`,
    });
  };
}
