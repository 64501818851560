export default function MainTitle({
  header,
    subheader,
  subtext,
  mb,
}: {
  header?: string;
  subheader?: string;
  subtext?: string;
  mb?: string;
}) {
  return (
    <div
      style={{
        display: 'block',
        marginBottom: mb || '24px',
      }}
    >
      {header && <h1>{header}</h1>}
        {subheader && <h2>{subheader}</h2>}
      {subtext && <div className="text-light">{subtext}</div>}
    </div>
  );
}
