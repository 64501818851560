import {Box, Breadcrumbs, Button, Divider, Link, TextField, Typography,} from '@mui/material';
import MainTitle from '../../pices/MainTitle';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {useAppDispatch, useAppSelector} from '../../../../redux/store';
import useTo from '../../../hooks/useTo';
import {useLocation} from 'react-router-dom';
import TileInner from '../../pices/TileInner';
import {useEffect, useMemo, useState} from 'react';
import {createWorkflows, Flow, getWorkflow, updateStep,} from '../../../../redux/slices/appSlice';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {WorkflowSteps} from './components/WorkflowSteps';
import EntityModal from '../../EntityModal';
import {NewStep} from './components/NewStep';
import {defaultFlows} from '../../../../assets/constants';

export const WorkFlowAction = () => {
  const dispatch = useAppDispatch();
  const to = useTo();
  const workflow = useAppSelector((state) => state.app).workflow;
  const location = useLocation();
  const id = location.pathname.includes(':')
    ? location.pathname.split(':')[1]
    : null;
  const [loading, setLoading] = useState(true);
  const stepList = useMemo(() => workflow?.flow || [], [workflow]);

  const [form, setForm] = useState<Flow>({
    _id: workflow?._id,
    name: workflow?.name,
    description: workflow?.description,
    flow: workflow?.flow || [],
    system: !!workflow?.system,
  });

  const [valid, setValid] = useState({
    name: true,
  });

  const openPop = (scene, options = {}) => {
    to(null, { scene, ...options });
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (id) {
        try {
          await dispatch(getWorkflow({ _id: id }));
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    setForm({ ...workflow });
  }, [workflow]);

  const saveHandler = () => {
    const name = form?.name?.length > 2;

    setValid({ name });

    if (!name) return;

    const new_flow = {
      description: form?.description,
      name: form.name,
      flow: stepList.length === 0 ? defaultFlows : stepList,
      system: false,
    };

    if (id) {
      dispatch(updateStep({ _id: id, updatedFlow: new_flow }));
    } else {
      dispatch(createWorkflows(new_flow));
    }

    to('workflows');
  };

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon />} sx={{ mb: '32px' }}>
        <Link
          underline="hover"
          key="1"
          color="inherit"
          href=""
          onClick={(e) => {
            e.preventDefault();
            to('/dashboard');
          }}
        >
          Home
        </Link>
        <Link
          underline="hover"
          key="2"
          color="inherit"
          href=""
          onClick={(e) => {
            e.preventDefault();
            to('/workflows');
          }}
        >
          Workflows
        </Link>
        <Typography key="3" color="text.primary">
          {id ? 'Edit workflow' : 'New workflow'}
        </Typography>
      </Breadcrumbs>

      <MainTitle header={id ? 'Edit workflow' : 'New workflow'} />

      <Divider sx={{ mb: '1.5rem' }} />
      <div style={{ display: 'flex' }}>
        <Button
          variant="text"
          startIcon={<SaveIcon />}
          onClick={saveHandler}
          style={{ marginLeft: 'auto' }}
          disabled={!!workflow?.system}
        >
          Save
        </Button>
      </div>
      <Divider sx={{ marginBlock: '1.5rem' }} />

      <div className="page_details">
        <div className="content_right">
          <TileInner text="Description" line>
            {loading ? (
              <div>Loading...</div>
            ) : (
              <Box
                component="form"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <TextField
                  id="standard-helperText-1"
                  fullWidth
                  variant="standard"
                  type="text"
                  label="Scheme Name"
                  placeholder="Scheme Name"
                  defaultValue={workflow?.name}
                  error={!valid.name}
                  helperText="Choose a name for the scheme that makes it easy to tell and remember what job type it's for."
                  onChange={(e) =>
                    setForm((prev: any) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                  disabled={!!workflow?.system}
                />
                <TextField
                  id="standard-helperText-2"
                  fullWidth
                  variant="standard"
                  type="text"
                  label="Description"
                  placeholder="Talent Popcorn Funnel"
                  defaultValue={workflow?.description}
                  helperText="Provide a brief description if necessary."
                  onChange={(e) =>
                    setForm((prev: any) => ({
                      ...prev,
                      description: e.target.value,
                    }))
                  }
                  disabled={!!workflow?.system}
                />
              </Box>
            )}
          </TileInner>
        </div>
        <div
          className="content_left"
          style={{ backgroundColor: '#ffffff', borderRadius: '8px' }}
        >
          <h2 style={{ padding: '1rem 2rem' }}>Funnel steps</h2>
          <div style={{ backgroundColor: '#f9f9f9', padding: '1rem 1.5rem' }}>
            <Button
              variant="text"
              startIcon={<AddCircleIcon />}
              onClick={() => openPop('new_step')}
              disabled={!form?.name || !!workflow?.system}
            >
              New Step
            </Button>
          </div>
          <div style={{ padding: '0.5rem 2rem 1rem' }}>
            <p style={{ color: '#666' }}>
              Please note that default steps cannot be deleted or modified if there are any candidates associated with this funnel schema.
            </p>

            <WorkflowSteps id={id} data={form} />
          </div>
        </div>
      </div>

      <EntityModal scene="new_step" top="64px" right="0" dark>
        <NewStep id={id} data={form} />
      </EntityModal>
    </>
  );
};
