import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { changeAvatar } from '../../../../../redux/slices/userSlice';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import styles from './Account.module.css';

export const Avatar = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.data);

  const [avatar, setAvatar] = useState(user?.userpic || null);

  // transformation to b64
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      var reader = new FileReader();

      reader.onload = (e: any) => {
        dispatch(changeAvatar({ _id: user._id, userpic: e.target.result }));
      };

      reader.readAsDataURL(file);
      setAvatar(URL.createObjectURL(file));
    } else return;
  };

  return (
    <div className={styles.wrapper}>
      <label htmlFor="image" className={styles.signupcontent_img}>
        {avatar !== null ? (
          <img
            className={styles.signupcontent__avatar}
            src={avatar}
            alt="avatar profile"
          ></img>
        ) : (
          <AccountCircleIcon sx={{ fontSize: 72 }} />
        )}
        <span className={styles.cignupcontent__text}>Change Profile Image</span>
      </label>
      <input
        id="image"
        type="file"
        accept=".gif, .png, .jpeg, .jpg"
        className={styles.signupcontent__addImg}
        value=""
        onChange={handleFileChange}
      />
    </div>
  );
};
