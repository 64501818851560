import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Alert, Box, Typography} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const GettingStarted = () => {
    return (
        <>
            <Box paddingX={4} paddingBottom={2} style={{overflowY: 'auto'}}>

                <Typography variant="body2" color="text.secondary">
                    <p>This guide will help you add your first job listing to the system, several
                        candidates,
                        and familiarize you with the functionality of the hiring funnel and candidate
                        evaluation.
                        We will demonstrate how AI can transform your daily work and save a considerable
                        amount of
                        time on preliminary resume processing.</p>

                    <Alert severity="info" style={{margin: '0 0 16px 0'}}>To get started, you will need a job
                        description and a few candidate resumes.
                        You can use your own materials or take advantage of our demo data designed
                        for learning how to use Talent Popcorn.</Alert>

                </Typography>

                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon/>}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Typography>Step 1: Adding a Job Listing</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body2" color="text.secondary">
                            <ul className={'spaced-list'} style={{listStyle: 'number',}}>
                                <li>
                                    <p>To start working with a job listing and candidates, first, create
                                        a
                                        job listing and add its
                                        description to the system. Click the <b>New Vacancy</b> button.
                                        This
                                        will open the job listing
                                        addition dialog.</p>
                                    <img src={'/images/faq/img-faq-new-vacancy.png'} width={'192px'}
                                         style={{border: '1px solid #dfdfdf'}}/>
                                </li>
                                <li>Fill in the fields with the job title, company, and add the job
                                    text.
                                    Note that having a job
                                    description
                                    is crucial for obtaining accurate candidate evaluation results.
                                </li>

                                <Alert severity="info" style={{margin: '0 0 16px 0'}}>Although Talent
                                    Popcorn is aware of the
                                    skills
                                    and experience required for positions like a Marketer or a Node.js
                                    Full-stack Developer, the
                                    requirements you set
                                    for candidates are significantly important in the assessment and
                                    evaluation process. You can
                                    also include non-public
                                    requirements for candidate experience or other logic in the "Job
                                    Description," for example, if
                                    you are looking for
                                    a specific combination of experience or skills, or if you have not
                                    posted the full job
                                    description in public
                                    sources for any reason.</Alert>
                                <img src={'/images/faq/img-faq-new-vacancy-window.png'} width={'384px'}
                                     style={{border: '1px solid #dfdfdf'}}/>

                                <li>In the form, you will also see a "Hiring flow" selector that allows
                                    you
                                    to choose the type of
                                    hiring funnel,
                                    which determines the statuses and actions available on the candidate
                                    management screen for the
                                    job. The list
                                    of hiring funnel statuses will be displayed on the screen. You can
                                    learn
                                    more about setting up
                                    funnels here,
                                    but for training purposes, we recommend leaving this parameter
                                    unchanged.
                                </li>
                                <li>Click the "Continue" button to create the job listing. The created
                                    job
                                    listing will be
                                    displayed on the dashboard.
                                </li>

                                <img src={'/images/faq/img-faq-new-vacancy-on-dashboard.png'}
                                     width={'384px'}
                                     style={{border: '1px solid #dfdfdf'}}/>
                            </ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon/>}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Typography>Step 2: Adding Candidates to a Job Listing</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body2" color="text.secondary">
                            <p>Once one or more job listings appear on your dashboard, you're ready to
                                work
                                with candidates.</p>
                            <ul className={'spaced-list'} style={{listStyle: 'number',}}>
                                <li>
                                    <p>Find the job listing you're interested in on the dashboard and
                                        click
                                        on the job title to go to the job listing page.</p>
                                </li>

                                <Alert severity="info" style={{margin: '0 0 16px 0'}}>Note that Talent
                                    Popcorn has several data
                                    presentation levels: cards, windows, and pages. For instance, on the
                                    dashboard, you see job
                                    cards and key indicators on them. Clicking on an empty space on the
                                    card
                                    opens a window for a
                                    quick preview of the job listing information for quick access, and
                                    clicking on the job title
                                    takes you to the job listing page where you can access all the
                                    features
                                    related to managing the
                                    job listing.
                                </Alert>

                                <li>
                                    <p>On the job listing page that opens, you'll see an empty kanban
                                        board
                                        representing the
                                        stages of the hiring workflow ("statuses") through which you can
                                        move candidates. The names of the
                                        stages and their order will match the hiring workflow selected
                                        when
                                        creating the job listing.
                                        When you add your schemes, you can choose them when creating or
                                        replace the scheme during the
                                        job listing management process.</p>
                                </li>

                                <img src={'/images/faq/img-faq-vacancy-page-empty.png'} width={'512px'}
                                     style={{border: '1px solid #dfdfdf'}}/>

                                <li>
                                    <p>Let's add a candidate. Prepare the candidate's resume in any
                                        format
                                        or <a href={""}>download our example</a>.
                                        Click the "New Candidate" button and select the file with the
                                        candidate's resume text. After
                                        selecting the file, you will see a loading indicator in the
                                        "New"
                                        column - this means the
                                        candidate has been successfully uploaded and is being processed.
                                    </p>
                                </li>

                                <Alert severity="info" style={{margin: '0 0 16px 0'}}>Note that you can
                                    upload
                                    several resumes at once. They will be processed in
                                    parallel. Handling 100-200 candidates in a few minutes is not the
                                    limit.
                                    Processing candidate data may take from 30 seconds to a couple of
                                    minutes
                                    depending on the complexity of the job listing and the resume.
                                    However,
                                    all
                                    uploaded resumes are processed in parallel.
                                </Alert>


                                <Alert severity="warning" style={{margin: '0 0 16px 0'}}>
                                    Supported file formats: pdf, doc, docx, txt, eml, ppt, pptx, csv,
                                    rtf.
                                </Alert>


                                <img src={'/images/faq/img-faq-vacancy-page-new-candidate.png'}
                                     width={'384px'}
                                     style={{border: '1px solid #dfdfdf'}}/>

                                <Alert severity="info" style={{margin: '0 0 16px 0'}}>
                                    Learn more about AI evaluation and assessment of candidates.
                                </Alert>


                                <li>
                                    <p>Once the resume is processed, you will see the candidate's card.
                                        You
                                        can
                                        familiarize yourself with the details of the resume, evaluation,
                                        and
                                        system
                                        recommendations by clicking on the candidate's card.
                                    </p>
                                </li>

                                <img src={'/images/faq/img-faq-vacancy-page-candidate-ready.png'}
                                     width={'512px'}
                                     style={{border: '1px solid #dfdfdf'}}/>

                            </ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon/>}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Typography>Step 3: Working with the Candidate Card</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body2" color="text.secondary">
                            <ul className={'spaced-list'} style={{listStyle: 'number',}}>
                                <li>
                                    <p>Find the candidate card you wish to view on the dashboard and
                                        click
                                        it with
                                        the left mouse button to open a window with information about
                                        the
                                        candidate.</p>
                                </li>
                                <li>
                                    <p>In the candidate information window, you will see the following
                                        tabs:</p>
                                    <ul style={{listStyle: 'circle'}}>
                                        <li>
                                            <b>Application</b> - contains information about the scoring
                                            evaluation
                                            and recommendations prepared by AI, details of the
                                            candidate's
                                            skills as reflected in their resume, and a section for
                                            notes.
                                        </li>
                                        <li>
                                            <b>Profile</b> - gathers all the details about the candidate
                                            extracted from their resume.
                                        </li>
                                        <li>
                                            <b>History</b> - here, you can view all the information
                                            about
                                            events associated with the candidate's card in the system.
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <p>On the Application tab, you can see the scores the system has
                                        given
                                        the candidate based on three parameters:</p>
                                    <ul style={{listStyle: 'circle'}}>
                                        <li>
                                            <b>Score</b> - the main evaluation of the candidate's match
                                            to
                                            the requirements.
                                        </li>
                                        <li>
                                            <b>Experience</b> - evaluation of the candidate's
                                            experience.
                                        </li>
                                        <li>
                                            <b>Skills</b> - evaluation of the candidate's skills.
                                        </li>
                                    </ul>
                                    <p>Below, you will find a system comment explaining the given
                                        evaluation
                                        and recommendations.</p>
                                </li>

                                <Alert severity="info" style={{margin: '0 0 16px 0'}}>
                                    During your work with the candidate's information and throughout
                                    the process, you can change the system's evaluations by simply
                                    dragging the slider on the Application tab. Also, under the
                                    evaluation and comment block, you can press the "refresh" symbol to
                                    restart the evaluation process. Use this function if you have
                                    changed the requirements described for the job listing (as of March
                                    2024, we do not automatically update all candidate evaluations after
                                    changing the job description).
                                </Alert>


                                <img src={'/images/faq/img-faq-vacancy-page-candidate-windows.png'}
                                     width={'512px'}
                                     style={{border: '1px solid #dfdfdf'}}/>

                                <li>
                                    <p>Additionally, you can use the quick action buttons on the
                                        window's
                                        toolbar to reject the candidate, add them to your favorites, or
                                        mark
                                        them as a finalist.</p>
                                </li>

                            </ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon/>}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Typography>Step 4: Dragging Candidate Cards</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body2" color="text.secondary">
                            <p>To move a candidate between the stages of the hiring process, find the
                                relevant card, click and hold the left mouse button, and then drag it to
                                the
                                desired stage of the hiring funnel. Cards in the "Declined" column
                                shrink in
                                size to save space.</p>

                            <img src={'/images/faq/img-faq-new-vacancy-moving-candidates.gif'}
                                 width={'512px'}
                                 style={{border: '1px solid #dfdfdf'}}/>

                        </Typography>
                    </AccordionDetails>
                </Accordion>

            </Box>


        </>
    );
};

export default GettingStarted;
