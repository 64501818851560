import {Alert, Divider} from '@mui/material';
import {useAppSelector} from '../../../redux/store';
import EntityModal from '../EntityModal';
import AddVacancy from '../pices/AddVacancy';
import ViewApplication from '../pices/ViewApplication';
import MainTitle from '../pices/MainTitle';
import {FiltersBlock} from '../pices/FiltersBlock';
import {VacancyCard} from '../pices/VacancyCard';
import {TariffLimits} from '../pices/TariffLimits';
import Box from "@mui/material/Box";
import React from "react";
import useTo from '../../hooks/useTo';
import FAQ from "../pices/faq/FAQ";
import FaqGettingStarted from "../pices/faq/FaqGettingStarted";

export default function Dashboard() {
    const user = useAppSelector((state) => state.user);
    const vacancies = useAppSelector((state) => state.app).vacancies || [];
    const to = useTo();

    const openPop = (scene, options = {}) => {
        to(null, {scene, ...options});
    };

    return (
        <>
            <div className="text">

                {!vacancies.length ? (
                        <FAQ />
                ) : (
                    <Box>
                        <MainTitle
                            header={`Hello, ${user?.data?.name || 'User'}`}
                            subtext="Your vacancies listed below."
                        />

                        <Divider sx={{mb: '1.5rem'}}/>

                        <FiltersBlock user={user.data}/>

                        <Divider sx={{marginBlock: '1.5rem'}}/>

                        <Box paddingBottom={2}>
                            <Alert severity="info">Read our <b><a href={''} onClick={(e) => {
                                e.preventDefault();
                                openPop('faq-getting-started');
                            }}>Getting Started FAQ</a></b> to learn Talent Popcorn basics!
                            </Alert>
                        </Box>

                        {!user?.data?.is_subscription_active && (
                            <TariffLimits data={user.data}/>
                        )}

                        <div className="cards_block">
                            {vacancies.length > 0 &&
                                vacancies.map((item) => (
                                    <VacancyCard data={item} key={item._id} user={user.data}/>
                                ))}
                        </div>
                    </Box>
                )}
            </div>

            <EntityModal scene="add-vacancy" top="64px" right="0" dark>
                <AddVacancy/>
            </EntityModal>

            <EntityModal scene="view-applicant" top="64px" right="0" dark>
                <ViewApplication/>
            </EntityModal>

            <EntityModal scene="faq-getting-started" top="64px" right="0" dark>
                <FaqGettingStarted/>
            </EntityModal>
        </>
    );
}
