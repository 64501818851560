import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import Tile from '../pices/Tile';
import TileInner from '../pices/TileInner';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { createNotes, getVacancies } from '../../../redux/slices/appSlice';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { addHint } from '../../../redux/slices/hintsSlice';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

// Vertical Tabs support code

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

// Vertical Tabs support code

export default function Candidate() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const v = useAppSelector((state) => state.app).vacancies || [];
  const transformVacancies = (v) =>
    v.map((el) => ({
      ...el,
      applicants: el.stats?.length,
      interviews: el.stats?.filter((s) => s === 'interview').length,
      finalists: el.stats?.filter((s) => s === 'finalist').length,
    }));
  const [vacancies, setVacancies] = useState(transformVacancies(v));

  useEffect(() => {
    setVacancies(transformVacancies(v));
  }, [JSON.stringify(v)]);

  useEffect(() => {
    if (user?.data?._id && !vacancies.length) dispatch(getVacancies(null));
  }, [dispatch, user]);

  // временная поддержка кода --------------->

  const [note, setNote] = useState('');

  const [selectedVacancyId, setSelectedVacancyId] = useState<string | null>(
    null
  );
  const selectedVacancy = vacancies.find((v) => v._id === selectedVacancyId);
  const [selectedApplicantId, setSelectedApplicantId] = useState<string | null>(
    null
  );
  const selectedApplicant = selectedVacancy?.applicants?.find(
    (a) => a._id === selectedApplicantId
  );

  const [view, setView] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setView(newValue);
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            sx={
              {
                // p: 3,
              }
            }
          >
            {children}
          </Box>
        )}
      </div>
    );
  }

  const sendNoteHandlerKeyPress = (e) => {
    if (e.key === 'Enter') sendNoteHandler();
  };

  const sendNoteHandler = () => {
    if (selectedApplicant) {
      if (!note)
        dispatch(
          addHint('The CV of the candidate has not included any summary.')
        );
      dispatch(
        createNotes({
          notes: [
            {
              entity_id: selectedApplicant._id,
              collectionName: 'Applicant',
              text: note,
            },
          ],
        })
      );
      setNote('');
    }
  };

  // временная поддержка кода <-------------

  // Vetrical Tabs Support Code
  const [value, setValue] = React.useState(0);

  const handleChangeVertical = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setValue(newValue);
  };

  // Vetrical Tabs Support Code

  return (
    <>
      <Tile
        text="Marks Epstein"
        subtext="Senior C++ Developer in IBM Systems"
      />

      {/* tab nav */}

      <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 4 }}>
        <Tabs
          value={view}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            style={{ textTransform: 'capitalize' }}
            label="General"
            id={`tab-0`}
            aria-controls={`tab-0`}
          />
          <Tab
            style={{ textTransform: 'capitalize' }}
            label="Resume"
            id={`tab-1`}
            aria-controls={`tab-1`}
          />
        </Tabs>
      </Box>

      {/*  tabs */}

      <Box sx={{ py: '2rem' }}>
        {/* tab 1 General */}

        <CustomTabPanel value={view} index={0}>
          {/* page cols */}

          <Grid container spacing={4}>
            {/* left col */}
            <Grid item xs={12} sm={6}>
              <Grid container direction={'column'} spacing={4}>
                {/* personal */}
                <Grid item>
                  <TileInner text="Personal" line>
                    <Grid container>
                      {/* personal left col */}
                      <Grid item xs={12} md={6}>
                        <b>Contacts</b>
                        <Grid
                          container
                          spacing={2}
                          style={{ padding: '1rem 0' }}
                        >
                          <Grid container item xs="auto">
                            <Grid container direction={'column'} rowSpacing={2}>
                              <Grid item>Emails:</Grid>
                              <Grid item>Phones:</Grid>
                              <Grid item>Socials:</Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs>
                            <Grid container item xs="auto">
                              <Grid
                                container
                                direction={'column'}
                                rowSpacing={2}
                              >
                                <Grid item>
                                  {selectedApplicant?.resume?.emails?.join(
                                    ', '
                                  ) || 'not set'}
                                </Grid>
                                <Grid item>
                                  {selectedApplicant?.resume?.phones?.join(
                                    ', '
                                  ) || 'not set'}
                                </Grid>
                                <Grid item>
                                  {selectedApplicant?.resume?.social?.join(
                                    ', '
                                  ) || 'not set'}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* personal right col */}
                      <Grid item xs={12} md={6}>
                        <b>Personal</b>
                        <Grid
                          container
                          spacing={2}
                          style={{ padding: '1rem 0' }}
                        >
                          <Grid container item xs="auto">
                            <Grid container direction={'column'} rowSpacing={2}>
                              <Grid item>Age:</Grid>
                              <Grid item>Birthday:</Grid>
                              <Grid item>Gender:</Grid>
                              <Grid item>Location:</Grid>
                              <Grid item>Languages:</Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs>
                            <Grid container item xs="auto">
                              <Grid
                                container
                                direction={'column'}
                                rowSpacing={2}
                              >
                                <Grid item>
                                  {selectedApplicant?.resume?.personal?.age ||
                                    'not set'}
                                </Grid>
                                <Grid item>
                                  {selectedApplicant?.resume?.personal
                                    ?.birthday || 'not set'}
                                </Grid>
                                <Grid item>
                                  {selectedApplicant?.resume?.personal
                                    ?.gender || 'not set'}
                                </Grid>
                                <Grid item>
                                  {`${
                                    selectedApplicant?.resume?.location?.city ||
                                    'not set'
                                  }, ${
                                    selectedApplicant?.resume?.location
                                      ?.country || 'not set'
                                  }`}
                                </Grid>
                                <Grid item>
                                  {selectedApplicant?.resume?.languages
                                    ?.map((l) => l.language)
                                    .join(', ') || 'not set'}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TileInner>
                </Grid>

                {/* skills */}
                <Grid item>
                  <TileInner text="Skills" line>
                    <div className="d-flex wrap" style={{ padding: '1rem 0' }}>
                      {(selectedApplicant?.resume?.skills || []).map(
                        (s: string, i) => (
                          <div key={s + i}>
                            <Chip label={s} sx={{ margin: '4px' }} />
                          </div>
                        )
                      )}
                    </div>
                  </TileInner>
                </Grid>

                {/* education */}
                <Grid item>
                  <TileInner text="Education" line>
                    <Grid container>
                      {/* education left col */}
                      <Grid item xs={12} md={6}>
                        <b>Education</b>
                        <Grid container direction={'column'} rowSpacing={2}>
                          {/* TODO тут надо привести в порядок выводы массивов */}
                          <Grid item xs={12}>
                            <div>
                              {selectedApplicant?.resume?.education?.degree ||
                                'not set'}
                              ,
                            </div>
                            <div
                              style={{ color: '#999999', fontSize: '0.875rem' }}
                            >
                              {selectedApplicant?.resume?.education
                                ?.institution || 'not set'}
                              ,&nbsp;
                              {selectedApplicant?.resume?.education
                                ?.graduation_start_date || 'not set'}{' '}
                              -&nbsp;
                              {selectedApplicant?.resume?.education
                                ?.graduation_end_date || 'not set'}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* education right col */}
                      <Grid item xs={12} md={6}>
                        <b>Licenses & Certifications</b>
                        <Grid container direction={'column'} rowSpacing={2}>
                          {/* TODO тут надо привести в порядок выводы массивов, CERTIFICATION! */}
                          <Grid item xs={12}>
                            <div>
                              {selectedApplicant?.resume?.education?.degree ||
                                'not set'}
                              ,
                            </div>
                            <div
                              style={{ color: '#999999', fontSize: '0.875rem' }}
                            >
                              {selectedApplicant?.resume?.education
                                ?.institution || 'not set'}
                              ,&nbsp;
                              {selectedApplicant?.resume?.education
                                ?.graduation_start_date || 'not set'}{' '}
                              -&nbsp;
                              {selectedApplicant?.resume?.education
                                ?.graduation_end_date || 'not set'}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TileInner>
                </Grid>

                {/* summary */}
                {/* TODO закоментировано для обсуждения*/}
                {/*<Grid item>
                                <TileInner text="Summary" line>
                                    <Box py={2}>
                                        {selectedApplicant?.resume?.summary || 'The CV of the candidate has not included any summary.'}
                                    </Box>
                                </TileInner>
                            </Grid>*/}
              </Grid>
            </Grid>

            {/* right col */}
            <Grid item xs={12} sm={6}>
              <Grid container direction={'column'} spacing={4}>
                {/* notes */}
                <Grid item>
                  <TileInner text="History" line>
                    {(selectedApplicant?.notes || []).map((note) => (
                      // TODO При отсутствии комментариев добавить надпись 'Nothing here yet'
                      <Grid
                        container
                        style={{ padding: '1rem 0' }}
                        key={note._id}
                      >
                        <Grid
                          item
                          xs={'auto'}
                          style={{ padding: '0 0 1rem 0' }}
                        >
                          <Avatar
                            alt={
                              (user &&
                                user?.data?.name[0] + user?.data?.surname[0]) ||
                              'User'
                            }
                            src="/static/images/avatar/2.jpg"
                          />
                          {/* TODO Пересмотреть divider, он странно расширяется по вертикали */}
                          <Divider
                            orientation="vertical"
                            style={{
                              marginTop: '1rem',
                              marginRight: '49%',
                              height: '60%',
                            }}
                          />
                        </Grid>
                        <Grid item xs style={{ padding: '0.5rem 0 0 1rem' }}>
                          <div
                            style={{
                              padding: '0 0 1rem 0',
                              fontSize: '12px',
                              fontWeight: '700',
                            }}
                          >
                            {`${user?.data?.name || 'user'}`}
                          </div>
                          <div
                            style={{ padding: '0 0 1rem 0', fontSize: '14px' }}
                          >
                            {`${note.text}`}
                          </div>
                          {/* TODO Вывод времени */}
                          <div style={{ fontSize: '12px' }}>Today 11:40</div>
                        </Grid>
                      </Grid>
                    ))}

                    <div
                      className="d-flex justify-content-start align-items-end"
                      style={{
                        background: '#F9F9F9',
                        borderRadius: '8px',
                        padding: '2px',
                      }}
                    >
                      <TextField
                        multiline
                        size="small"
                        sx={{
                          width: '90%',
                          border: 'none',
                          '& fieldset': { border: 'none' },
                        }}
                        value={note || ''}
                        onChange={(e) => setNote(e.target.value)}
                        onKeyDown={sendNoteHandlerKeyPress}
                      />
                      <IconButton aria-label="send" onClick={sendNoteHandler}>
                        <SendOutlinedIcon />
                      </IconButton>
                    </div>
                  </TileInner>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CustomTabPanel>

        {/* tab 2 Resume */}

        <CustomTabPanel value={view} index={1}>
          <Grid container spacing={4}>
            <Grid item xs="auto">
              {/* resume tab grid container */}
              {/* TODO Выводится по одному экземпляру на каждый добавленный для кандидата резюме, по клику на элемент должно выводить резюме в соседней колонке*/}
              <Grid
                container
                sx={{ borderRadius: 1, background: '#FFFFFF', p: 2, mb: 2 }}
                alignItems="center"
              >
                <Grid item xs="auto" justifyContent="center">
                  <DescriptionOutlinedIcon
                    color="primary"
                    sx={{ fontSize: 48 }}
                  />
                </Grid>
                <Grid item xs sx={{ p: 1 }}>
                  <div>my_resume.pdf</div>
                  <div style={{ fontSize: '12px', color: '#999999' }}>
                    Received Today 11:49
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs>
              <Grid container spacing={4}>
                {/* left col */}
                <Grid item xs={12} sm={6}>
                  <Grid container direction={'column'} spacing={4}>
                    {/* summary */}
                    <Grid item>
                      <TileInner text="Summary" line>
                        <Box py={2}>
                          {selectedApplicant?.resume?.summary ||
                            'The CV of the candidate has not included any summary.'}
                        </Box>
                      </TileInner>
                    </Grid>

                    {/* personal */}
                    <Grid item>
                      <TileInner text="Personal" line>
                        <Grid container>
                          {/* personal left col */}
                          <Grid item xs={12} md={6}>
                            <b>Contacts</b>
                            <Grid
                              container
                              spacing={2}
                              style={{ padding: '1rem 0' }}
                            >
                              <Grid container item xs="auto">
                                <Grid
                                  container
                                  direction={'column'}
                                  rowSpacing={2}
                                >
                                  <Grid item>Emails:</Grid>
                                  <Grid item>Phones:</Grid>
                                  <Grid item>Socials:</Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs>
                                <Grid container item xs="auto">
                                  <Grid
                                    container
                                    direction={'column'}
                                    rowSpacing={2}
                                  >
                                    <Grid item>
                                      {selectedApplicant?.resume?.emails?.join(
                                        ', '
                                      ) || 'not set'}
                                    </Grid>
                                    <Grid item>
                                      {selectedApplicant?.resume?.phones?.join(
                                        ', '
                                      ) || 'not set'}
                                    </Grid>
                                    <Grid item>
                                      {selectedApplicant?.resume?.social?.join(
                                        ', '
                                      ) || 'not set'}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          {/* personal right col */}
                          <Grid item xs={12} md={6}>
                            <b>Personal</b>
                            <Grid
                              container
                              spacing={2}
                              style={{ padding: '1rem 0' }}
                            >
                              <Grid container item xs="auto">
                                <Grid
                                  container
                                  direction={'column'}
                                  rowSpacing={2}
                                >
                                  <Grid item>Age:</Grid>
                                  <Grid item>Birthday:</Grid>
                                  <Grid item>Gender:</Grid>
                                  <Grid item>Location:</Grid>
                                  <Grid item>Languages:</Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs>
                                <Grid container item xs="auto">
                                  <Grid
                                    container
                                    direction={'column'}
                                    rowSpacing={2}
                                  >
                                    <Grid item>
                                      {selectedApplicant?.resume?.personal
                                        ?.age || 'not set'}
                                    </Grid>
                                    <Grid item>
                                      {selectedApplicant?.resume?.personal
                                        ?.birthday || 'not set'}
                                    </Grid>
                                    <Grid item>
                                      {selectedApplicant?.resume?.personal
                                        ?.gender || 'not set'}
                                    </Grid>
                                    <Grid item>
                                      {`${
                                        selectedApplicant?.resume?.location
                                          ?.city || 'not set'
                                      }, ${
                                        selectedApplicant?.resume?.location
                                          ?.country || 'not set'
                                      }`}
                                    </Grid>
                                    <Grid item>
                                      {selectedApplicant?.resume?.languages
                                        ?.map((l) => l.language)
                                        .join(', ') || 'not set'}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </TileInner>
                    </Grid>

                    {/* education */}
                    <Grid item>
                      <TileInner text="Education" line>
                        <Grid container>
                          {/* education left col */}
                          <Grid item xs={12} md={6}>
                            <b>Education</b>
                            <Grid container direction={'column'} rowSpacing={2}>
                              {/* TODO тут надо привести в порядок выводы массивов */}
                              <Grid item xs={12}>
                                <div>
                                  {selectedApplicant?.resume?.education
                                    ?.degree || 'not set'}
                                  ,
                                </div>
                                <div
                                  style={{
                                    color: '#999999',
                                    fontSize: '0.875rem',
                                  }}
                                >
                                  {selectedApplicant?.resume?.education
                                    ?.institution || 'not set'}
                                  ,&nbsp;
                                  {selectedApplicant?.resume?.education
                                    ?.graduation_start_date || 'not set'}{' '}
                                  -&nbsp;
                                  {selectedApplicant?.resume?.education
                                    ?.graduation_end_date || 'not set'}
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>

                          {/* education right col */}
                          <Grid item xs={12} md={6}>
                            <b>Licenses & Certifications</b>
                            <Grid container direction={'column'} rowSpacing={2}>
                              {/* TODO тут надо привести в порядок выводы массивов, CERTIFICATION! */}
                              <Grid item xs={12}>
                                <div>
                                  {selectedApplicant?.resume?.education
                                    ?.degree || 'not set'}
                                  ,
                                </div>
                                <div
                                  style={{
                                    color: '#999999',
                                    fontSize: '0.875rem',
                                  }}
                                >
                                  {selectedApplicant?.resume?.education
                                    ?.institution || 'not set'}
                                  ,&nbsp;
                                  {selectedApplicant?.resume?.education
                                    ?.graduation_start_date || 'not set'}{' '}
                                  -&nbsp;
                                  {selectedApplicant?.resume?.education
                                    ?.graduation_end_date || 'not set'}
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </TileInner>
                    </Grid>
                  </Grid>
                </Grid>

                {/* right col */}
                <Grid item xs={12} sm={6}>
                  <Grid container direction={'column'} spacing={4}>
                    {/* skills */}
                    <Grid item>
                      <TileInner text="Skills" line>
                        <div
                          className="d-flex wrap"
                          style={{ padding: '1rem 0' }}
                        >
                          {(selectedApplicant?.resume?.skills || []).map(
                            (s: string, i) => (
                              <div key={s + i}>
                                <Chip label={s} sx={{ margin: '4px' }} />
                              </div>
                            )
                          )}
                        </div>
                      </TileInner>
                    </Grid>

                    {/* notes */}
                    <Grid item>
                      <TileInner text="Experience" line>
                        {(selectedApplicant?.resume?.experience || []).map(
                          (ex, i) => (
                            <div
                              key={JSON.stringify(ex) + i}
                              style={{ padding: '16px', fontSize: '16px' }}
                            >
                              <b>{ex.position}</b>
                              <div className="text-light">
                                {ex.company}, {ex.location}
                              </div>
                              <br />
                              <div>
                                {ex.start_date} - {ex.end_date}
                              </div>
                              <br />
                              <div>{ex.description}</div>
                              <Divider sx={{ my: 0.5, marginTop: '32px' }} />
                            </div>
                          )
                        )}
                      </TileInner>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CustomTabPanel>
      </Box>
    </>
  );
}
