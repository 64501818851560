import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Alert, Box, Typography} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const SubscriptionPayment = () => {
    return (
        <>
            <Box paddingX={4} paddingBottom={2} style={{overflowY: 'auto'}}>

                <Typography variant="body2" color="text.secondary">
                    <p>Thank you for your interest in subscribing to our service. We strive to make it more convenient
                        and better for every user. If you have decided to subscribe, please follow the instructions
                        below:</p>
                </Typography>

                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon/>}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Typography>How to Subscribe</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body2" color="text.secondary">
                            <ul className={'spaced-list'} style={{listStyle: 'number',}}>

                                <li>
                                    <p>Find the limits section and the Subscribe button on the Dashboard page, or find
                                        the Subscribe button in the user menu. You will be taken to the tariff plan
                                        selection page.</p>
                                    <Box>
                                        <img src={'/images/faq/faq-user-menu-subscribe.png'} width={'256px'}
                                             style={{border: '1px solid #dfdfdf'}}/>
                                    </Box>
                                    <Box>
                                        <img src={'/images/faq/faq-limitations-subscribe.png'} width={'384px'}
                                             style={{border: '1px solid #dfdfdf'}}/>
                                    </Box>
                                </li>

                                <li>
                                    <p>After navigating to the page, you will see a selection of several tariff plans.
                                        Choose the tariff that suits your preferences. Click Subscribe on the card of
                                        the
                                        chosen tariff plan. You will be redirected to the payment service page.</p>

                                    <img src={'/images/faq/faq-subscribtion-pricing-table.png'} width={'384px'}
                                         style={{border: '1px solid #dfdfdf'}}/>
                                </li>

                                <li>

                                    <p>After transitioning to the payment page, enter your card details for payment. If you
                                    have a promo code - enter it in the field that appears after clicking the
                                        "Add promotional code" button.</p>

                                    <Box>
                                        <img src={'/images/faq/faq-subscribtion-easteregg-1.png'} width={'384px'}
                                             style={{border: '1px solid #dfdfdf'}}/>
                                    </Box>
                                    <Box>
                                        <img src={'/images/faq/faq-subscribtion-easteregg-2.png'} width={'384px'}
                                             style={{border: '1px solid #dfdfdf'}}/>
                                    </Box>
                                </li>

                                <Alert severity="info" style={{margin: '0 0 16px 0'}}>Note
                                    that Talent Popcorn does not collect card or payment information; payment occurs
                                    on
                                    the side of the payment service Stripe.</Alert>

                                <li>After successful payment, you will be redirected to the profile page and will be
                                    able to use all the advantages of Talent Popcorn. A receipt will be sent to the
                                    email address provided during profile registration.
                                </li>

                            </ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    );
};

export default SubscriptionPayment;
