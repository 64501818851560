import {
  Box,
  Grid,
  SvgIcon,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../redux/store';
import { getApplicants, getNotes } from '../../../redux/slices/appSlice';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import useTo from '../../hooks/useTo';
import Notes from './Notes';

// TODO взять этот стиль скроллбара
/*const scrollbarStyles = {
    // Firefox scrollbar styles
    '*': {
        scrollbarWidth: 'auto',
        scrollbarColor: '#c9c9c9 #ffffff',
    },
    // Chrome, Edge, and Safari scrollbar styles
    '*::-webkit-scrollbar': {
        width: '12px',
    },
    '*::-webkit-scrollbar-track': {
        background: '#ffffff',
    },
    '*::-webkit-scrollbar-thumb': {
        backgroundColor: '#c9c9c9',
        borderRadius: '5px',
        border: '2px solid #ffffff',
    },
};*/

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default function ViewVacancy({
  vacancy,
  close = () => {},
}: {
  vacancy: any;
  close?: Function;
}) {
  const to = useTo();
  const dispatch = useAppDispatch();
  const [view, setView] = useState(0);

  useEffect(() => {
    if (vacancy && !vacancy.applicants)
      dispatch(getApplicants({ vacancy_id: vacancy._id }));
    if (vacancy && !vacancy.notes)
      dispatch(getNotes({ entity_id: vacancy._id, vacancy_id: vacancy._id }));
  }, [vacancy, dispatch]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setView(newValue);
  };

  const openHandler = () => {
    to('vacancy', { vacancy_id: vacancy._id });
  };

  const openApplicantHandler = (applicant) =>
    to(null, {
      scene: 'view-applicant',
      vacancy_id: vacancy._id,
      applicant_id: applicant._id,
    });

  const styles = {
    chip: {
      borderRadius: '1.5rem',
      color: '#999999',
      background: '#dfdfdf',
      fontSize: '0.75rem',
      fontWeight: '700',
      padding: '0.75rem 1rem',
      textTransform: 'uppercase',
      textAlign: 'center',
    },
  };

  return (
    <Box
      sx={{
        minWidth: '350px',
        maxWidth: '100%',
        maxHeight: '90vh',
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          background: 'white',
          width: 'inherit',
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{ p: 4 }}
        >
          <Grid item>
            <h3 style={{ padding: 0, margin: 0 }}>{vacancy?.position_name}</h3>
            <span style={{ fontSize: '12px', color: '#999999' }}>
              {vacancy?.company_name}
            </span>
          </Grid>

          <Grid item>
            <OpenInNewIcon
              style={{ color: '#999999', cursor: 'pointer' }}
              onClick={openHandler}
            />
            <CancelIcon
              style={{
                color: '#999999',
                cursor: 'pointer',
                marginLeft: '1rem',
              }}
              onClick={() => close()}
            />
          </Grid>
        </Grid>

        {/* ПЕРЕКЛЮЧАТЕЛИ */}

        <Tabs
          value={view}
          onChange={handleChange}
          aria-label="basic tabs example"
          style={{ paddingInline: 32 }}
        >
          <Tab
            style={{ textTransform: 'capitalize' }}
            label="Summary"
            id={`tab-0`}
            aria-controls={`tab-0`}
          />
          <Tab
            style={{ textTransform: 'capitalize' }}
            label="Description"
            id={`tab-1`}
            aria-controls={`tab-1`}
          />
        </Tabs>
      </Box>

      {/* ТАБЫ */}
      <Box
        className="wrapper"
        sx={{
          overflowY: 'auto',
          height: 'calc(100vh - 250px)',
        }}
      >
        <CustomTabPanel value={view} index={0}>
          <Box sx={{ p: 4 }}>
            <Grid
              container
              sx={{
                borderRadius: '0.25rem',
                background: '#F9F9F9',
                fontSize: '0.75rem',
                color: '#333333',
              }}
            >
              {[
                { Applicants: vacancy?.stats?.length },
                {
                  'Final Candidates': vacancy?.stats?.filter(
                    (s) => s === 'offer_sent'
                  ).length,
                },
              ].map((o, i) => (
                <Grid item sx={{ p: 2 }} xs={6} key={Object.keys(o)[0] + i}>
                  <div>{Object.keys(o)[0]}</div>
                  <div
                    style={{
                      fontSize: '1rem',
                      fontWeight: '700',
                      marginTop: '8px',
                    }}
                  >
                    {o[Object.keys(o)[0]]}
                  </div>
                </Grid>
              ))}
            </Grid>

            <Box
              sx={{
                fontSize: '0.9rem',
                color: '#333333',
                fontWeight: '700',
                py: 2,
                paddingLeft: 2,
              }}
            >
              Recent Applicants
            </Box>

            <TableContainer sx={{ width: '100%' }}>
              <Table sx={{}} aria-label="simple table">
                <TableBody>
                  {vacancy?.applicants?.length ? (
                    (vacancy?.applicants?.slice(0, 4) || []).map(
                      (applicant, i) => (
                        <TableRow
                          key={applicant._id + i}
                          sx={{
                            border: 0,
                            p: 0,
                            m: 0,
                          }}
                        >
                          <TableCell
                            sx={{
                              padding: '0 0 0 1rem',
                            }}
                          >
                            <Box
                              style={{
                                fontSize: '14px',
                                color: '#333333',
                                cursor: 'pointer',
                              }}
                              onClick={() => openApplicantHandler(applicant)}
                            >
                              {`${applicant?.resume?.personal?.first_name} ${applicant?.resume?.personal?.last_name}`}
                            </Box>
                            <Box
                              style={{
                                fontSize: '12px',
                                color: '#999999',
                              }}
                            >
                              {`${applicant?.resume?.experience?.[0]?.position} in ${applicant?.resume?.experience?.[0]?.company}`}
                            </Box>
                          </TableCell>

                          <TableCell
                            sx={{
                              display: 'flex',
                              justifyContent: 'end',
                              px: 0,
                            }}
                          >
                            <div
                              style={{
                                ...(styles.chip as React.CSSProperties),
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {vacancy.flow?.find(
                                (f) => f.code === applicant?.flow_code
                              )?.name || ''}
                            </div>
                          </TableCell>

                          <TableCell>
                            <div
                              style={{
                                ...(styles.chip as React.CSSProperties),
                              }}
                            >
                              {applicant?.scoring?.general_score}
                              /10
                            </div>
                          </TableCell>

                          <TableCell
                            style={{
                              width: 'fit-content',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <SvgIcon
                              sx={{
                                cursor: 'pointer',
                              }}
                              onClick={() => openApplicantHandler(applicant)}
                            >
                              <path
                                d="M5 21.0154C4.45 21.0154 3.97917 20.8195 3.5875 20.4279C3.19583 20.0362 3 19.5654 3 19.0154V5.01538C3 4.46538 3.19583 3.99455 3.5875 3.60288C3.97917 3.21121 4.45 3.01538 5 3.01538H19C19.55 3.01538 20.0208 3.21121 20.4125 3.60288C20.8042 3.99455 21 4.46538 21 5.01538V19.0154C21 19.5654 20.8042 20.0362 20.4125 20.4279C20.0208 20.8195 19.55 21.0154 19 21.0154H5ZM14 19.0154V5.01538H5V19.0154H14Z"
                                fill="#999999"
                              />
                            </SvgIcon>
                          </TableCell>
                        </TableRow>
                      )
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <div
                          style={{
                            width: 600 - 64 + 'px',
                            padding: '16px',
                          }}
                        >
                          Nothing here yet
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Notes
              notes={vacancy?.notes || []}
              name="Vacancy"
              entity_id={vacancy?._id}
            />
          </Box>
        </CustomTabPanel>

        <CustomTabPanel value={view} index={1}>
          <Box
            sx={{
              padding: '18px 48px',
              minHeight: '50vh',
              maxHeight: '60vh',
            }}
          >
            <p>
              {vacancy?.position_description || 'vacancy has not description'}
            </p>
          </Box>
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
/* <h2>What You'll Do:</h2>
<ul>
    <li>Lead and oversee the day-to-day operations of a media buyers team and growth product managers,
        providing hands-on guidance, prioritization, and support to drive successful campaigns and
        growth strategies.
    </li>
    <li>Develop and execute comprehensive media buying strategies to maximize ROI across our business
        lines. Own business line P&Ls top to bottom.
    </li>
    <li>Utilize data analysis and insights to identify trends, patterns, and opportunities for
        optimizing user flows and improving campaign performance.
    </li>
    <li>Collaborate and facilitate workflows with cross-functional teams, including Advanced Analytics,
        Data Science, and engineering, to execute media strategies.
    </li>
    <li>Work with the Internal Product team to define requirements and aid in developing our evolving
        in-house managed DSP product.
    </li>
    <li>Stay up-to-date with industry trends, emerging technologies, and best practices in media buying,
        growth marketing, and user acquisition.
    </li>
    <li>Define key performance indicators (KPIs) and establish metrics to measure the success of media
        campaigns and growth initiatives.
    </li>
    <li>Monitor and analyze campaign performance data, generating actionable insights and
        recommendations to drive continuous improvement.
    </li>
    <li>Oversee relationships and business progress of our clients and advertisers.</li>
</ul>
<h2>Requirements:</h2>
<ul>
    <li>5+ years of experience managing teams of Media buyers, Growth Managers, or equivalent positions
        - MUST
    </li>
    <li>In-depth understanding of user flows, conversion rate optimization (CRO), A/B testing, and user
        experience (UX) best practices. Experience with multivariate testing and website optimization
        techniques is advantageous.
    </li>
    <li>Proven expertise in data analysis, interpretation, and reporting. Ability to derive actionable
        insights from complex data scenarios and make data-driven decisions.
    </li>
    <li>Familiarity with data visualization tools and statistical analysis is beneficial.</li>
    <li>Analytical and conversion-based approach to KPI improvements.</li>
    <li>Familiarity with AdTech ecosystems - Mobile SDK ecosystem, attribution models, compliance.</li>
    <li>Strong technical acumen and the ability to work closely with engineering teams to understand and
        optimize the functionality of the in-house developed DSP system. Familiarity with programming
        languages, data APIs, and data integrations is a plus.
    </li>
    <li>Strong communicator with excellent interpersonal skills, collaboration, and delegation skills.
    </li>
    <li>Excellent English communication skills. Written and oral.</li>
    <li>Independent, proactive, and creative mindset. Fun to be around and bash ideas with :)</li>
</ul>
<h2>About Us</h2><p>Arpeely is a Data-Science startup, leveraging data analysis, ML, engineering,
multi-disciplinary thinking to gain a market edge and exploit hidden opportunities in real-time
advertising. Processing over 1M requests per second and serving over 20B sub-second predictions daily,
we build and operate Machine Learning algorithms running on the world’s largest Real-time-bidding (RTB)
Ad-Exchanges.</p><p>Controlling the whole user lifecycle from bid to impression, to click, to funnel
experience, to product, everyone at Arpeely has the potential to lead to business breakthroughs on a
daily basis. With dozens of daily deployments and quick feedback from our millions of hourly users, we
are addicted to fast data feedback loops.</p><p>Our lean cross-disciplinary team of engineers, data, UX,
PMs, and developers work in unison to re-invent market rules and beat the status quo competition. Come
[legally] hack with us the data on the largest exchange that’s running our world. Not NASDAQ; the one
with way more events - the Global Ads Exchanges, where millions of ads are born and clicked every
second.</p><p>Step behind the curtain of algorithms and competitors that move $1T of annual budgets.
Plunge into a world of ISP volumes of traffic, sub-second predictions, and TBs of live, real-world data.
Use cutting-edge analysis, ML, engineering, UX, or just plain hacker-like thinking to out-perform the
market.</p><p>Arpeely is now at an exciting boiling point, still bootstrapped and with a lean and mean
R&D team. This is an amazing opportunity to join a multi-disciplinary A-team while working in a
fast-paced, data-oriented environment.</p><p>Work from our offices in Tel Aviv, Midtown Commerce
Tower.</p> */
