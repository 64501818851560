import {Button, Divider, TextField} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import {useEffect, useState} from "react";
import {useAppDispatch} from "../../../redux/store";
import {createApplicants, query} from "../../../redux/slices/appSlice";
import useWidth from "../../hooks/useWidth";


export default function AddApplicant({vacancy_id, close=()=>{}}:{vacancy_id: string, close?: Function}){
    const dispatch = useAppDispatch();
    const [text, setText] = useState<string | null>(null);
    const [files, setFiles] = useState<any | null>(null);
    const [_, vh] = useWidth();

    useEffect(()=>{
        if(!vacancy_id) close();
    })

    const sendHandler = () => {
        if(files?.length){
            dispatch(createApplicants({query: {type: 'file', vacancy_id} as query, applicants: files}))
        }
        if(text){
            dispatch(createApplicants({query: {type: 'text', vacancy_id} as query, applicants: [text]}))
        }
        close()
    }

    const styles = {
        item: {margin: '31px'},
        input: {margin: '4px'}
    }

    
    return(<>

        <div style={{minWidth: '340px', maxWidth: '750px', zIndex: 100}}>
        <div style={{width: '100vw'}} />
            <div className="d-flex justify-content-between"
                style={styles.item}
            >
                <div className="text"
                    style={{
                        fontSize: '18px',
                        fontWeight: 400
                    }}
                >
                    New Applicant
                </div>
                <div>
                    <CancelIcon style={{color: '#999999', cursor: 'pointer'}}
                        onClick={()=> close()}
                    />
                </div>
            </div>
            <Divider sx={{ my: 0.5 }} />
            <div style={styles.item}>
                

                <label htmlFor="fileInput">
                    <div className="d-flex justify-content-center align-items-center text-light"
                        style={{
                            borderRadius: '8px',
                            border: '1px solid #DFDFDF',
                            background: '#F2F2F2',
                            padding: '16px',
                            marginBottom: '16px'
                        }}
                    >
                        <UploadFileIcon style={{margin: '0 16px'}}/> 
                        <div>{files?.length? [].map.call(files,(f:any)=>f.name).join(', '):`Click to select a CV file`}</div>
                    </div>
                </label>
                <input 
                    id="fileInput" 
                    type="file" 
                    name="files"
                    multiple={true}
                    style={{display: 'none'}}
                    onChange={(e)=>setFiles(e.target.files)} 
                />

                
                <div className="d-flex justify-content-center align-items-center">
                    <Divider sx={{ my: 0.5, width: '173px', margin: '16px' }} />
                    <div className="text-light">or</div>
                    <Divider sx={{ my: 0.5, width: '173px', margin: '16px' }} />
                </div>
                <TextField style={styles.input}
                    multiline
                    maxRows={vh<600? 3 : vh<640? 4: vh<660? 6 :vh<770? 8 : vh<880? 11 :14}
                    fullWidth
                    variant="standard"
                    label="Past job description here"
                    value={text || ''}
                    helperText="Job description"
                    onChange={(e)=> {setText((prev:any) =>  e.target.value)}}
                />
            </div>
            {/* <Divider sx={{ my: 0.5 }} /> */}
            <div style={styles.item} className="d-flex justify-content-end">
                {/* <Button variant="outlined" 
                    style={{
                        color: '#333', 
                        borderColor: '#333',
                        margin: '0 8px'
                    }}
                    onClick={()=> openPop('new-applicant')}
                >
                    Cancel
                </Button> */}
                <Button variant="contained"
                    onClick={sendHandler}
                >
                    Continue
                </Button>
            </div>
        </div>
    </>)
}