import React from 'react';
import { Button } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { StepType } from './NewStep';
import { ActionsMenu } from './ActionsMenu';

export const Step: React.FC<{
  data: StepType;
  setDragButtonActive: React.Dispatch<React.SetStateAction<boolean>>;
  id?: string;
}> = ({ data, setDragButtonActive, id }) => {
  return (
    <>
      <div
        style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}
      >
        <span style={{ fontSize: '0.875rem' }}>{data.name}</span>
        <span style={{ fontSize: '0.75rem', color: '#999' }}>
          {data.description}
        </span>
      </div>
      <ActionsMenu data={data} header="Delete step" id={id} />
      <Button
        size="small"
        variant="text"
        onMouseDown={() => setDragButtonActive(true)}
      >
        <DragIndicatorIcon />
      </Button>
    </>
  );
};
