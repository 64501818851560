import { useEffect } from 'react';
import {
  addProcess as add,
  addProcess,
  init,
  removeProcess,
} from '../../redux/slices/praocessSlice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { getApplicants, getVacancies } from '../../redux/slices/appSlice';

/**
 *
 * @returns [getCount, addProcess]
 */
export default function useProcess(): [
  (param: { key?: string; subkey?: string }) => number,
  (key: string) => void,
] {
  const dispatch = useAppDispatch();
  const processes = useAppSelector((state) => state.process.processes);
  // const [processesState, setProcessesState] = useState(false);
  // const [initState, setInitState] = useState(false);

  useEffect(() => {
    // setProcessesState(true);
    dispatch(init());
  }, [dispatch]);

  useEffect(() => {
    const checkProcesses = () => {
      const storedProcesses = JSON.parse(
        window.localStorage.getItem('processes') || '[]'
      );

      if (storedProcesses.length) {
        const sortedProcesses = storedProcesses.sort(
          (a, b) => b.created - a.created
        );

        if (sortedProcesses[0].created < Date.now() - 1000 * 90) {
          const { key } = sortedProcesses[0];
          dispatch(removeProcess({ key }));

          if (key.includes('.applicant')) {
            dispatch(
              getApplicants({ vacancy_id: key.replace('.applicant', '') })
            );
          }

          if (key.includes('.vacancy')) {
            dispatch(getVacancies({}));
          }
        }
      }
    };

    const beforeUnloadHandler = (event) => {
      const storedProcesses = JSON.parse(
        window.localStorage.getItem('processes') || '[]'
      );

      if (storedProcesses.length) {
        event.preventDefault();
        const message = 'Are you sure?';
        event.returnValue = message;
        window.confirm(message);
      }
    };

    window.addEventListener('beforeunload', beforeUnloadHandler);

    const interval = setInterval(() => {
      checkProcesses();
    }, 200);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
      clearInterval(interval);
    };
  }, [dispatch]);

  const getCount = (param: { key?: string; subkey?: string }) => {
    if (!!param.subkey)
      return processes.filter((p) => p.key.includes(param.subkey)).length;
    if (!!param.key) return processes.filter((p) => p.key === param.key).length;
    return 0;
  };

  const addProcess = (key: string) => {
    dispatch(add({ key }));
  };

  return [getCount, addProcess];
}
