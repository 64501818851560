import { Divider, Paper } from "@mui/material";

export default function Tile({
    header,
    text,
    subtext,
    line,
    sx,
    children,
}: {
    header?: string;
    text?: string;
    subtext?: string;
    line?: boolean;
    sx?: Object;
    children?: React.ReactNode;
}) {
    return (
        <Paper elevation={0} sx={{ padding: "24px", marginBottom: "24px" }}>
            <div style={{ ...(sx ? sx : {}), margin: "0 auto" }}>
                <div
                    style={{
                        margin: "8px",
                        marginBottom:
                            (header || text || subtext) && (children || line)
                                ? "24px"
                                : "8px",
                        width: `calc(100% - ${8 * 2}px`,
                        background: "inherit",
                    }}
                >
                    {header && <h1>{header}</h1>}
                    {text && (
                        <div className="text" style={{ fontSize: "18px" }}>
                            {text}
                        </div>
                    )}
                    {subtext && <div className="text-light"> {subtext} </div>}
                </div>
                {line && <Divider sx={{ mb: "24px", mx: "-24px" }} />}
                <div>{children}</div>
            </div>
        </Paper>
    );
}
