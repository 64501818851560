import {Box, Divider, Grid,} from '@mui/material';
import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../../../redux/store';
import {getVacancies,} from '../../../../redux/slices/appSlice';
import useTo from '../../../hooks/useTo';
import GettingStarted from "./GettingStarted";
import CancelIcon from "@mui/icons-material/Cancel";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}


export default function FaqGettingStarted({
  close = () => {},
}: {
  close?: Function;
}) {
  const to = useTo();
  const dispatch = useAppDispatch();

  const vacancies = useAppSelector((state) => state.app).vacancies || [];
  useEffect(() => {
    if (!vacancies.length) dispatch(getVacancies(null));
  }, [dispatch]);


  return (
    <Box
      sx={{
        minWidth: '350px',
        maxWidth: '100%',
        maxHeight: '90vh',
          overflow: 'auto'
      }}
    >

      <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{ p: 4 }}
      >
        <Grid item>
          <h1 style={{ padding: 0, margin: 0 }}>
            Getting Started
          </h1>
        </Grid>

        <Grid item>
          <CancelIcon
              style={{
                color: '#999999',
                cursor: 'pointer',
                marginLeft: '1rem',
              }}
              onClick={() => close()}
          />
        </Grid>
      </Grid>
      <Divider sx={{mb: '1.5rem'}}/>
      <GettingStarted />
    </Box>
  );
}
