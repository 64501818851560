import React, { useState } from 'react';
import {
  Button,
  Grid,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { NewStep, StepType } from './NewStep';
import useTo from '../../../../hooks/useTo';
import Pop from '../../../Pop';
import AreYouShure from '../../../pices/AreYouShure';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import {
  copyStep,
  copyWorkflows,
  removeStep,
  removeWorkflows,
} from '../../../../../redux/slices/appSlice';
import EntityModal from '../../../EntityModal';

export const ActionsMenu: React.FC<{
  data: StepType;
  header: string;
  id?: string;
}> = ({ data, header, id }) => {
  const dispatch = useAppDispatch();
  const to = useTo();
  const workflow = useAppSelector((state) => state.app).workflow;

  /** menu */
  const openPop = (scene, options = {}) => {
    to(null, { scene, ...options });
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = (key) => {
    switch (key) {
      case 'Copy':
        if (id) {
          dispatch(copyStep({ _id: id, newFlow: workflow, stepId: data?._id }));
        } else {
          dispatch(copyWorkflows(data?._id));
        }
        break;
      case 'Delete':
        openPop(`delete-step-${data?._id}`);
        break;
      case 'Edit':
        if (id) {
          openPop(`edit_step_${data.code}`);
        } else {
          to(`workflows/:${data?._id}`);
        }
        break;
      default:
        break;
    }
    setAnchorEl(null);
  };

  const handleDelete = () => {
    if (id) {
      dispatch(removeStep({ _id: id, step: data?._id }));
    } else {
      dispatch(removeWorkflows(data?._id));
    }
  };

  return (
    <>
      <Grid item sx={{ position: 'relative' }}>
        <Button size="small" variant="text" onClick={handleOpenMenu}>
          <MoreHorizIcon />
        </Button>
        <Menu
          id="menuCard"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseUserMenu}
          sx={{ zIndex: 11 }}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }}
        >
          <MenuItem
            onClick={() => handleCloseUserMenu('Edit')}
            disabled={id ? !data.editable : false}
          >
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => handleCloseUserMenu('Copy')}
            disabled={id ? !data.editable : false}
          >
            <ListItemIcon>
              <ContentCopyIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Copy</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => handleCloseUserMenu('Delete')}
            disabled={id ? !data.editable : !!data.system}
          >
            <ListItemIcon>
              <DeleteForeverIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Menu>
      </Grid>

      <Pop scene={`delete-step-${data?._id}`} dark>
        <AreYouShure
          handler={handleDelete}
          header={header}
          text={`Are you sure you what to delete the "${data.name}?"`}
          btn="Delete"
        />
      </Pop>

      <EntityModal scene={`edit_step_${data.code}`} top="64px" right="0" dark>
        <NewStep id={id} data={workflow} step={data} />
      </EntityModal>
    </>
  );
};
