import { Button, ButtonPropsColorOverrides, Divider } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react';

export default function AreYouShure({
  header,
  text,
  handler,
  btn,
  color,
  close = () => {},
}: {
  header: React.ReactNode;
  text: React.ReactNode;
  handler?: Function;
  btn: string | null;
  color?: OverridableStringUnion<
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning',
    ButtonPropsColorOverrides
  >;
  close?: Function;
}) {
  const deleteHandler = () => {
    handler();
  };

  const styles = {
    item: { margin: '31px' },
    input: { margin: '4px' },
  };
  return (
    <>
      <div style={{ width: '455px', zIndex: 100 }}>
        <div className="d-flex justify-content-between" style={styles.item}>
          <div
            className="text"
            style={{
              fontSize: '18px',
              fontWeight: 400,
            }}
          >
            {header}
          </div>
          <div>
            <CancelIcon
              style={{ color: '#999999', cursor: 'pointer' }}
              onClick={() => close()}
            />
          </div>
        </div>
        <Divider sx={{ my: 0.5 }} />
        <div
          className="text-light d-flex justify-content-center"
          style={styles.item}
        >
          <div style={{ fontSize: '16px' }}>{text}</div>
        </div>
        <Divider sx={{ my: 0.5 }} />
        <div style={styles.item} className="d-flex justify-content-end">
          <Button
            variant="outlined"
            style={{
              color: '#333',
              borderColor: '#333',
              margin: '0 8px',
            }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          {btn === null ? (
            ''
          ) : (
            <Button
              variant="contained"
              color={color || 'error'}
              onClick={deleteHandler}
            >
              {btn || 'OK'}
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
