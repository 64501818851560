import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {Badge, BadgeProps, Box, Grid, IconButton,} from '@mui/material';
import {styled} from '@mui/material/styles';

import FlagIcon from '@mui/icons-material/Flag';
import CloseIcon from '@mui/icons-material/Close';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ReplayIcon from '@mui/icons-material/Replay';
import {memo, useEffect, useState} from 'react';
import {useAppDispatch} from '../../../redux/store';
import {favoriteApplicant, setStats, updateApplicants,} from '../../../redux/slices/appSlice';
import useProcess from '../../hooks/useProcess';
import useTo from '../../hooks/useTo';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';

export default memo(function MyFlowTable({
  applicants,
  vacancy: v,
  style,
}: {
  applicants: object[];
  vacancy: any;
  style?: React.CSSProperties;
}) {
  const dispatch = useAppDispatch();
  const getApps = (v, a) =>
    v?.flow?.reduce(
      (acc, f) => ({
        ...acc,
        [f.code]: a.filter((a: any) => a.flow_code === f.code),
      }),
      {}
    ) || {};
  const [apps, setApps] = useState(getApps(v, applicants));

  // const matchArrs = (arr1, arr2) => !arr2.map((el)=> arr1.includes(el)).includes(false) && !arr1.map((el)=> arr2.includes(el)).includes(false);
  useEffect(() => {
    setApps(getApps(v, applicants));
  }, [applicants]);

  const flowCodeHandler = (applicant, code) => {
    if (!applicant?._id) return;
    dispatch(
      updateApplicants({
        applicants: [
          { applicant_id: applicant?._id, applicant: { flow_code: code } },
        ],
      })
    );
    dispatch(
      setStats({
        vacancy_id: v._id,
        stats: [
          ...(v.stats?.filter((s) => s !== applicant.flow_code) || []),
          ...(v.stats?.filter((s) => s === applicant.flow_code)?.slice(1) ||
            []),
          code,
        ],
      })
    );
  };

  const handleDragEnd = (result) => {
    if (result?.source?.droppableId && result?.destination?.droppableId) {
      const pApplicant = apps[result.source.droppableId][result.source.index];
      if (result.source.droppableId !== result.destination.droppableId) {
        setApps((p) => ({
          ...p,
          [result.source.droppableId]: p[result.source.droppableId].filter(
            (_, i) => i !== result.source.index
          ),
          [result.destination.droppableId]: [
            ...p[result.destination.droppableId].slice(
              0,
              result.destination.index
            ),
            { ...pApplicant, flow_code: result.destination.droppableId },
            ...p[result.destination.droppableId].slice(
              result.destination.index
            ),
          ],
        }));
        flowCodeHandler(pApplicant, result.destination.droppableId);
      } else {
        setApps((p) => ({
          ...p,
          [result.source.droppableId]: p[result.source.droppableId].map(
            (el, i, arr) =>
              i === result.source.index
                ? arr[result.destination.index]
                : i === result.destination.index
                  ? arr[result.source.index]
                  : el
          ),
        }));
      }
    }
  };

  const styles = {
    column: (data = []) => ({
      background: data?.length ? 'none' : '#DFDFDF',
      height: '100%',
      minWidth: '124px',
      padding: '8px',
      margin: '4px',
      borderRadius: '4px',
    }),
  };

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      left: '-8px',
      top: '16px',
      // border: `2px solid ${theme.palette.background.paper}`,
      width: '24px',
      height: '24px',
      borderRadius: '4px',
      background: '#999999',
      color: 'white',
    },
  }));

  const [procCount] = useProcess();
  const procApps = new Array(procCount({ subkey: v?._id })).fill(null);

  return (
    <>
      <Grid
        container
        alignItems="stretch"
        sx={{ overflow: 'auto', flexWrap: 'nowrap', ...(style || {}) }}
      >
        <DragDropContext onDragEnd={handleDragEnd}>
          {(v?.flow || []).map((f) => (
            <div key={f.code}>
              <Grid item sx={{ height: '100%' }}>
                <Droppable droppableId={f.code}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={styles.column(apps[f.code])}
                    >
                      <Box>
                        <Grid
                          container
                          direction="row"
                          wrap="nowrap"
                          alignItems="center"
                          sx={{ p: '4px 4px 12px 4px' }}
                        >
                          <Grid item xs>
                            <span className="text" style={{ fontWeight: 500 }}>
                              {f.name}
                            </span>
                            {!apps[f.code]?.length ? (
                              ''
                            ) : (
                              <StyledBadge
                                badgeContent={apps[f.code].length}
                                sx={{
                                  display: 'inline-block',
                                  width: '32px',
                                  height: '32px',
                                }}
                              />
                            )}
                          </Grid>
                          <Grid item>
                            <IconButton aria-label="delete" size="small">
                              <MoreHorizIcon fontSize="small" />
                            </IconButton>
                          </Grid>
                        </Grid>
                        {/* Старый header колонок */}
                        {/* <Box sx={{marginBottom: '16px'}}>
                                            <span className='text' style={{marginRight: '8px', fontWeight: 500}}>
                                                {f.name}
                                            </span>
                                            {!apps[f.code]?.length? '' : <StyledBadge badgeContent={apps[f.code].length} sx={{display: 'inline-block', width: '32px', height: '32px'}}/>}
                                        </Box>*/}
                        {f.code !== 'new'
                          ? ''
                          : procApps.map((pa, i) => (
                              <AppCard key={i} applicant={pa} vacancy={v} />
                            ))}
                        {(apps[f.code] || []).map((applicant: any, i) => (
                          <Draggable
                            key={applicant?._id || i}
                            draggableId={applicant?._id || i}
                            index={i}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <AppCard applicant={applicant} vacancy={v} />
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </Box>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Grid>
            </div>
          ))}
        </DragDropContext>
      </Grid>
    </>
  );
});

function AppCard({ applicant, vacancy }) {
  const to = useTo();
  const dispatch = useAppDispatch();
  // const [like, setLike] = useState(!!applicant?.favorite);

  const flowCodeHandler = (applicant, code) => {
    if (!applicant?._id) return;
    dispatch(
      updateApplicants({
        applicants: [
          {
            applicant_id: applicant?._id,
            applicant: {
              flow_code: applicant.flow_code === code ? 'new' : code,
            },
          },
        ],
      })
    );
    dispatch(
      setStats({
        vacancy_id: vacancy._id,
        stats: [
          ...(vacancy.stats?.filter((s) => s !== applicant.flow_code) || []),
          ...(vacancy.stats
            ?.filter((s) => s === applicant.flow_code)
            ?.slice(1) || []),
          applicant.flow_code === code ? 'new' : code,
        ],
      })
    );
  };

  const favoriteHandler = (applicant) => {
    if (!applicant?._id) return;
    dispatch(favoriteApplicant(applicant?._id));
    // setLike(p=>!p);
  };

  const openApplicantHandler = () =>
    to(null, {
      scene: 'view-applicant',
      vacancy_id: vacancy._id,
      applicant_id: applicant._id,
    });

  return (
    <div style={{ paddingBottom: '16px' }}>
      {
        !applicant ? (
          // Скелетон загружающейся вакансии
          <Grid
            container
            sx={{
              padding: '16px',
              color: '#333333',
              background: '#F2F2F2',
              width: '288px',
              /*height: '144px',*/
              borderRadius: '4px',
            }}
          >
            <Grid item xs={9}>
              <div style={{ lineHeight: '21px' }}>New candidate scoring...</div>
              {/*<div style={{ fontSize: '12px', color: '#666' }}>
                Your data was successfully uploaded. Please wait for the scoring
                results.
              </div>*/}
            </Grid>
            {/*<Grid item xs={3} className="d-flex justify-content-end flex-wrap">
              <Skeleton
                sx={{ width: '3rem', height: '2rem', borderRadius: '0.25rem' }}
                animation="wave"
                variant="rectangular"
              />
            </Grid>*/}
            {/*<Grid item xs={12}>
              <div style={{ margin: '16px' }} />
            </Grid>
            <Grid item xs={12} className="d-flex justify-content-end flex-wrap">
              <Skeleton
                animation="wave"
                variant="circular"
                width={20}
                height={20}
              />
            </Grid>*/}
          </Grid>
        ) : // Скелетон загружающейся вакансии

        applicant?.flow_code === 'declined' ? (
          // Карточка отклоненного
          <Grid
            container
            sx={{
              borderRadius: '4px',
              background: '#DFDFDF',
              padding: '8px 16px',
              width: '288px',
            }}
          >
            <Grid item xs={12}>
              <div
                style={{ fontSize: '14px', margin: '8px 0', cursor: 'pointer' }}
                onClick={openApplicantHandler}
              >
                {applicant.resume.personal.first_name}{' '}
                {applicant.resume.personal.last_name}
              </div>
              {/*{
                                applicant.resume.experience[0] &&
                                <>
                                    <div style={{
                                        fontSize: '12px',
                                        color: '#666'
                                    }}>
                                        {applicant.resume.experience[0].position} {applicant.resume.experience[0].company}
                                    </div>
                                    <div className="text-light"
                                         style={{fontSize: '12px'}}>
                                        {applicant.resume?.location?.country}{!applicant.resume?.location?.city ? '' : ' | ' + applicant.resume.location.city}
                                    </div>
                                </>
                            }*/}
            </Grid>
            <Grid item xs={3}>
              <div
                style={{
                  lineHeight: '24px',
                  fontSize: '14px',
                  fontWeight: 700,
                }}
              >
                {applicant.scoring.general_score}/10
              </div>
            </Grid>
            <Grid item xs={9} className="d-flex justify-content-end flex-wrap">
              {/*НОВАЯ КНОПКА ДЛЯ ФИНАЛИСТА*/}

              <IconButton
                aria-label="close"
                sx={{ padding: '0' }}
                onClick={() =>
                  flowCodeHandler(
                    applicant,
                    applicant?.flow_code !== 'declined' ? 'declined' : 'new'
                  )
                }
              >
                {applicant?.flow_code !== 'declined' ? (
                  <CloseIcon sx={{ color: '#999999' }} />
                ) : (
                  <ReplayIcon sx={{ color: '#999999' }} />
                )}
              </IconButton>
            </Grid>
          </Grid>
        ) : (
          // Карточка отклоненного

          // Карточка вакансии
          <Grid
            container
            sx={{
              borderRadius: '4px',
              border: '1px solid #DFDFDF',
              background: '#FFF',
              padding: '16px',
              width: '288px',
            }}
          >
            <Grid item xs={12} sx={{ minHeight: '67px' }}>
              <div
                style={{ fontSize: '14px', margin: '8px 0', cursor: 'pointer' }}
                onClick={openApplicantHandler}
              >
                {applicant.resume.personal.first_name}{' '}
                {applicant.resume.personal.last_name}
              </div>
              {applicant.resume.experience && applicant.resume.experience.length > 0 && (
                  <>
                    <div
                        style={{
                          fontSize: '12px',
                          color: '#666',
                        }}
                    >
                      {applicant.resume.experience[0].position}{' '}
                      {applicant.resume.experience[0].company}
                    </div>
                    <div className="text-light" style={{ fontSize: '12px' }}>
                      {applicant.resume?.location?.country}
                      {!applicant.resume?.location?.city
                          ? ''
                          : ' | ' + applicant.resume.location.city}
                    </div>
                  </>
              )}
            </Grid>
            <Grid item xs={12} sx={{ height: '12px' }} />
            <Grid item xs={3}>
              <div
                style={{
                  lineHeight: '24px',
                  fontSize: '20px',
                  fontWeight: 700,
                }}
              >
                {applicant.scoring?.general_score || 0}/10
              </div>
            </Grid>
            <Grid item xs={9} className="d-flex justify-content-end flex-wrap">
              {/*НОВАЯ КНОПКА ДЛЯ ФИНАЛИСТА*/}

              <IconButton
                aria-label="offer_sent"
                sx={{ padding: '0' }}
                onClick={() =>
                  flowCodeHandler(
                    applicant,
                    applicant?.flow_code !== 'offer_sent' ? 'offer_sent' : 'new'
                  )
                }
              >
                {applicant?.flow_code !== 'offer_sent' ? (
                  <OutlinedFlagIcon sx={{ color: '#999999' }} />
                ) : (
                  <FlagIcon sx={{ color: 'green' }} />
                )}
              </IconButton>

              {/* ############################## */}

              <IconButton
                aria-label="finished"
                sx={{ padding: '0', margin: '0 8px' }}
                onClick={() => favoriteHandler(applicant)}
              >
                {!!applicant?.favorite ? ( ///!!like ?
                  <FavoriteIcon sx={{ color: '#FF0000' }} />
                ) : (
                  <FavoriteBorderIcon sx={{ color: '#999999' }} />
                )}
              </IconButton>

              <IconButton
                aria-label="close"
                sx={{ padding: '0' }}
                onClick={() =>
                  flowCodeHandler(
                    applicant,
                    applicant?.flow_code !== 'declined' ? 'declined' : 'new'
                  )
                }
              >
                {applicant?.flow_code !== 'declined' ? (
                  <CloseIcon sx={{ color: '#999999' }} />
                ) : (
                  <ReplayIcon sx={{ color: '#999999' }} />
                )}
              </IconButton>
            </Grid>
          </Grid>
        )
        // Карточка вакансии
      }
    </div>
  );
}
