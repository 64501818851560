import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const CandidateDetails = () => {
    return (
        <>
            <Box paddingX={4} paddingBottom={2} style={{overflowY: 'auto'}}>

                <Typography variant="body2" color="text.secondary">
                    <p>Let's explore the features Talent Popcorn offers for working with candidate profiles.</p>
                </Typography>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon/>}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Typography>Access to Candidate Profiles</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body2" color="text.secondary">
                            <p>Access to candidate profiles can be obtained in several ways.</p>
                            <ul className={'spaced-list'} style={{listStyle: 'number',}}>
                                <li>
                                    <p>You can view candidates on the page of the vacancy you're interested in.
                                        Candidates will be arranged on a Kanban board, the columns of which represent
                                        stages of the hiring funnel; for simplicity, you can refer to these as the
                                        candidate's status. Candidates within the board's columns are arranged in
                                        descending order of their overall rating. Use the Sort button to change the
                                        sorting order.</p>
                                    <img src={'/images/faq/faq-vacancy-kanban.png'} width={'480px'}
                                         style={{border: '1px solid #dfdfdf'}}/>
                                </li>
                                <li>Another quick way to access the top-rated candidates directly from the Talent
                                    Popcorn dashboard is available. Select the vacancy you're interested in and click on
                                    the blank space on its card. A window will open on the right, showing the Recent
                                    Applicants section. Click the icon at the end of the line to view the candidate's
                                    card.
                                    <img src={'/images/faq/faq-vacancy-candidate-card.gif'} width={'480px'}
                                         style={{border: '1px solid #dfdfdf'}}/>
                                </li>
                            </ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon/>}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Typography>Candidate Information</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body2" color="text.secondary">
                            <p>The information about candidates in the Talent Popcorn system is presented in several
                                formats. As a mini-card on the Kanban board on the vacancy page, and also in a window
                                with detailed information about the candidate.</p>

                            <ul className={'spaced-list'} style={{listStyle: 'number',}}>
                                <li>
                                    <p>Find the candidate card you wish to view on the dashboard. Familiarize yourself
                                        with the UI to understand the data presented on the dashboard.</p>
                                    <img src={'/images/faq/faq-vacancy-mini-card.png'} width={'480px'}
                                         style={{border: '1px solid #dfdfdf'}}/>
                                </li>
                                <li>
                                    <p>Select the candidate's card and click on it to open a window with information
                                        about the candidate.</p>
                                </li>
                                <li>
                                    <p>In the candidate window, you will see multiple elements, including a toolbar with
                                        quick action buttons and several tabs, one of which contains the Talent Popcorn
                                        AI scoring block.</p>
                                    <img src={'/images/faq/faq-vacancy-windows-candidate.png'} width={'480px'}
                                         style={{border: '1px solid #dfdfdf'}}/>
                                    <ul style={{listStyle: 'circle'}}>
                                        <li>Application - contains information about the scoring evaluation and
                                            recommendations
                                            prepared by AI, details of the candidate's skills as reflected in their
                                            resume, and a
                                            section for notes.
                                        </li>
                                        <li>Profile - gathers all the details about the candidate extracted from their
                                            resume.
                                        </li>
                                        <li>History - here, you can view all the information about events associated
                                            with the
                                            candidate's card in the system.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p>On the Application tab, you can see the scores the AI system has given the
                                        candidate based on three parameters:</p>
                                    <ul style={{listStyle: 'circle'}}>
                                        <li>Score - the main evaluation of the candidate's match to the requirements.
                                        </li>
                                        <li>Experience - evaluation of the candidate's experience.</li>
                                        <li>Skills - evaluation of the candidate's skills.</li>
                                    </ul>
                                    <img src={'/images/faq/faq-vacancy-candidate-windows-card.gif'} width={'480px'}
                                         style={{border: '1px solid #dfdfdf'}}/>
                                    <p>Additionally, you can evaluate the quality of the candidates' assessments by
                                        pressing the thumbs up or thumbs down buttons. The button next to them,
                                        featuring an arrow, restarts the candidate's evaluation according to the current
                                        version of the job description.</p>
                                    <p>In the skills section, you will find specific skills of the candidate
                                        identified by Talent Popcorn AI. Note that we strive to avoid making
                                        indirect inferences about a candidate's experience and skills, maintaining a
                                        balance between the content of the resume and the conclusions of artificial
                                        intelligence.</p>
                                    <p>In the Notes section, you and your colleagues can leave comments during
                                        the process of working with the candidate.</p>
                                </li>
                                <li>
                                    <p>On the Profile tab, you will find all the data about the candidate that Talent
                                        Popcorn
                                        AI was able to extract from the resume: contact details, links to social
                                        networks,
                                        information about education and languages, cover letter or summary, list of
                                        skills,
                                        details about experience, education, and certification.</p>
                                </li>
                                <li>
                                    <p>On the History tab, you will find comments and other information about events
                                        that have
                                        occurred with the candidate in the system.</p>
                                </li>
                            </ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    );
};

export default CandidateDetails;
