import {
    Box,
    Button,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Modal,
    TextField
} from "@mui/material";
import {ReactComponent as LogoSvg} from "../../../static/Logo.svg";
import useTo from "../../hooks/useTo";
import {useEffect, useState} from "react";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import CancelIcon from '@mui/icons-material/Cancel';
import validator from 'validator';
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {setSession, signup} from "../../../redux/slices/userSlice";

export default function Registration() {
    const dispatch = useAppDispatch();
    const to = useTo()
    const userData = useAppSelector(state => state.user.data);
    const [form, setForm] = useState({name: '', surname: '', email: '', company: '', pass: '', repeat: ''});
    const [valid, setValid] = useState({
        name: true,
        surname: true,
        email: true,
        company: true,
        pass: true,
        repeat: true
    })
    const [showPassword, setShowPassword] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

    const registrationHandler = () => {
        const [name, surname, email, company, pass, repeat] = [
            form.name.length > 2,
            form.surname.length > 2,
            validator.isEmail(form.email),
            form.surname.length > 2,
            !!validator.isStrongPassword(form.pass, {
                minLength: 8,
                minLowercase: 1,
                minUppercase: 1,
                minNumbers: 1,
                minSymbols: 0
            }),
            form.pass === form.repeat
        ]
        setValid({name, surname, email, company, pass, repeat});
        if (!name || !surname || !email || !company || !pass || !repeat) return;
        dispatch(setSession({checked: false}));
        dispatch(signup((({repeat, pass, ...form}) => ({...form, password: pass}))(form)));
        // to('/board');
    }
    useEffect(() => {
        if (userData?.email) to('/dashboard');
    });

    return (<>

        {/*TODO дла работы брикпойнтов в sx нужно имплементировать theme provider */}

        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '4rem 0',}}>

            <Box sx={{boxShadow: 2, borderRadius: 2, minWidth: 300, maxWidth: 500, background: '#ffffff',}}>

                <Box sx={{p: 4,}} style={{cursor: 'pointer'}} onClick={() => to('/welcome')}>
                    <Box justifyContent="flex-start" alignItems="center" display={"inline-flex"}>
                        <LogoSvg/>
                        <span className="text" style={{
                            paddingLeft: '10px',
                            fontSize: '20px',
                            lineHeight: '24px',
                            letterSpacing: '0.15px',
                            fontWeight: 'bold',
                            color: '#333333',
                        }}>
                            Talent Popcorn
                        </span>
                    </Box>
                </Box>

                <Divider sx={{my: 0.5}}/>

                <Box sx={{p: 4}}>

                    <Grid container rowSpacing={1} columnSpacing={2}>
                        <Grid item xs={12}>
                            <h1 style={{fontSize: '1.5rem', padding: 0, margin: 0,}}>Sign Up</h1>
                            <p style={{fontSize: '1rem', color: '#333333', margin: '1rem 0 0 0',}}>Please fill the form
                                to join our services.</p>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth variant="standard"
                                       type="text"
                                       label="Name" placeholder="Talent" value={form.name} error={!valid.name}
                                       helperText="Your first name here"
                                       onChange={(e) => setForm((prev: any) => ({...prev, name: e.target.value}))}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth variant="standard"
                                       type="text"
                                       label="Surname" placeholder="Popcorn" value={form.surname} error={!valid.surname}
                                       helperText="Your last name here"
                                       onChange={(e) => setForm((prev: any) => ({...prev, surname: e.target.value}))}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth variant="standard" type="text" label="Company"
                                       placeholder="Talent Popcorn" value={form.company} error={!valid.company}
                                       helperText="Company name here"
                                       onChange={(e) => setForm((prev: any) => ({...prev, company: e.target.value}))}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth variant="standard" type="email" label="Email" autoComplete="on"
                                       placeholder="example@mailbox.com" value={form.email} error={!valid.email}
                                       helperText="Your email here"
                                       onChange={(e) => setForm((prev: any) => ({...prev, email: e.target.value}))}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth variant="standard"
                                         error={!valid.pass}>
                                <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                <Input type={showPassword ? 'text' : 'password'} value={form.pass} error={!valid.pass}
                                       onChange={(e) => setForm((prev: any) => ({...prev, pass: e.target.value}))}
                                       endAdornment={
                                           <InputAdornment position="end">
                                               <IconButton aria-label="toggle password visibility"
                                                           onClick={() => setShowPassword((prev: any) => !prev)}
                                                   // onMouseLeave={() => setShowPassword(false)}
                                               >
                                                   {showPassword ? <VisibilityOff/> :
                                                       <Visibility/>}</IconButton>
                                           </InputAdornment>}/>
                                <FormHelperText
                                    error={!valid.pass}>{valid.pass ? 'Your password here' : "Must be longer than 8 chars and contains lowercase and uppercase letters and numbers"}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>

                            <FormControl fullWidth variant="standard"
                                         error={!valid.repeat}><InputLabel htmlFor="standard-adornment-password">Password
                                repeat</InputLabel>
                                <Input type={showPassword ? 'text' : 'password'}
                                       value={form.repeat} error={!valid.repeat}
                                       onChange={(e) => setForm((prev: any) => ({...prev, repeat: e.target.value}))}
                                       endAdornment={<InputAdornment position="end"><IconButton
                                           aria-label="toggle password visibility"
                                           onClick={() => setShowPassword((prev: any) => !prev)}
                                           // onMouseLeave={() => setShowPassword(false)}
                                       >{showPassword ?
                                           <VisibilityOff/> :
                                           <Visibility/>}</IconButton></InputAdornment>}/><FormHelperText
                                    error={!valid.repeat}>{valid.repeat ? 'Repeat the password' : "Must be exactly the same as the password"}</FormHelperText>
                            </FormControl>

                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{pt: 4,}}>
                        <Button fullWidth variant="contained" size="large" sx={{fontSize: '1rem',}}
                                onClick={registrationHandler}>
                            Create account
                        </Button>
                    </Grid>

                    <Grid item xs={12} className="d-flex justify-content-center align-items-center">
                        <div style={{
                            color: '#999',
                            fontSize: '12px',
                            padding: '2rem 0 0 0',
                        }}>
                            By signing up, you agree to our <u onClick={() => {
                            setShowTerms(true)
                        }} style={{cursor: 'pointer'}}>Terms of Service</u> and <u onClick={() => {
                            setShowPrivacyPolicy(true)
                        }} style={{cursor: 'pointer'}}>Privacy Policy</u>
                        </div>
                    </Grid>

                    {/*TODO Кнока авторизации через Google
                     https://www.npmjs.com/package/@react-oauth/google


                    <Grid item xs={12} sx={{mt: 2,}}>
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
                            <Divider sx={{flex: 1,}}/>
                            <div style={{
                                padding: '0 1rem',
                                color: '#999999',
                                fontSize: '0.75rem',
                            }}>
                                Sign up with
                            </div>
                            <Divider sx={{flex: 1,}}/>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sx={{mt: 2,}}>
                        Кнопка тут
                    </Grid>

                    */}

                </Box>
            </Box>
        </Box>

        {/*===============================================================*/}


        <Modal
            open={showTerms}
            onClose={() => {
                setShowTerms(false)
            }}
        >
            <div style={{
                width: '900px',
                minWidth: '300px',
                maxHeight: '90vh',
                overflow: 'scroll',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                outline: 'none',
                background: 'white'
            }}>
                <div className="d-flex justify-content-between align-items-center"
                     style={{margin: '32px', width: 'calc(100% - 64px)'}}>
                    <div>
                        Terms of Service
                    </div>
                    <CancelIcon sx={{
                        cursor: 'pointer'
                    }}
                                onClick={() => {
                                    setShowTerms(false)
                                }}
                    />
                </div>
                <Divider sx={{my: 0.5}}/>
                <div style={{margin: '32px', whiteSpace: 'pre-wrap'}}>

                    {'Terms of Service\n' +
                        '\n' +
                        'Updated on: January 2, 2024\n' +
                        '\n' +
                        'THESE TERMS OF SERVICE, TOGETHER WITH ANY AMENDMENTS, ORDER FORMS, AND ANY ADDITIONAL AGREEMENTS YOU ENTER INTO WITH TALENT POPCORN, INC. (“TALENT POPCORN”, “WE” OR “OUR”) IN CONNECTION WITH THE SERVICE (COLLECTIVELY, “TERMS”), EXCLUSIVELY GOVERN YOUR ACCESS TO AND USE OF OUR TALENT POPCORN RECRUITMENT SOFTWARE (THE “SERVICE”). THE TERMS APPLY TO YOU IF YOU HOLD AN ACCOUNT WITH US, IF YOU PURCHASE THE SERVICE, OR IF YOU OTHERWISE USE THE SERVICE. PLEASE READ THEM CAREFULLY BEFORE USING THE SERVICE.\n' +
                        '\n' +
                        'BY ACCESSING, USING AND/OR ORDERING THE SERVICE, YOU WARRANT THAT YOU ARE AUTHORIZED TO DO SO, AND AGREE TO BE BOUND BY THESE TERMS. IF YOU ARE USING THE SERVICE ON BEHALF OF AN ORGANIZATION OR LEGAL ENTITY (“ORGANIZATION”), THEN YOU ARE AGREEING TO THESE TERMS ON BEHALF OF THAT ORGANIZATION, AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE AUTHORITY TO BIND THAT ORGANIZATION TO THESE TERMS. IN THAT CASE, “YOU” AND “YOUR” REFERS TO YOU AND THAT ORGANIZATION.\n' +
                        '\n' +
                        '1. The Service\n' +
                        '\n' +
                        'The Service is offered on a software-as-a-service subscription basis subject to the Terms. Talent Popcorn retains all rights to the Service. We may change the Service in our sole discretion from time to time as we refine it and add more or other features. \n' +
                        'We may also stop, suspend, or modify the Service at any time without prior notice to you, in any circumstances we reasonably determine to be necessary, including but not limited (i) to perform essential repairs/maintenance to the Service; (ii) where your use of the Service is in breach of your obligations under this agreement; (iii) where payment of the fees for the Service are not paid in a timely manner. We also may impose limitations on bandwidth usage for the Service as we, in our sole discretion, determine to be appropriate.\n' +
                        'You acknowledge and agree that the Service operates on, or with, or using application programming interfaces (“APIs”) and/or other services operated or provided by third parties (“Third Party Services”). Talent Popcorn is not responsible for the operation of any Third Party Services, nor the availability or operation of the Service to the extent such availability and operation is dependent upon Third Party Services. You are solely responsible for procuring any and all rights necessary to access Third Party Services and for complying with any applicable terms or conditions thereof. Talent Popcorn does not make any representations or warranties with respect to Third Party Services. Any exchange of data or other interaction between you and Third Party Services is solely between you and that third party, and is governed by such third party’s terms and conditions.\n' +
                        '\n' +
                        '2. Pricing and Payment\n' +
                        '\n' +
                        'You shall make payment for the fees as specified in the Terms. All fees are exclusive of all applicable taxes, and you agree to pay any and all taxes that you are obliged to pay by law in relation to any transaction between you and Talent Popcorn. All payments shall be made in US dollars, unless agreed otherwise in the Terms.  \n' +
                        'The Terms will specify the usage limitations applicable to your use of the Service, which may include the maximum number of authorised users and agreed bandwidth. You agree to keep a record of your usage of the Service, including the number of users. Talent Popcorn reserves the right to monitor your actual use of the Service. \n' +
                        '\n' +
                        '3. Content\n' +
                        '\n' +
                        'In order to make use of the Service, you or your users may upload, create, or input information, (personal) data, documentation, and other materials (together, “Content”) into the Service. \n' +
                        'You have sole responsibility for all Content which you store in the Service, whether publicly posted or privately transmitted by you. We do not endorse, support, represent or guarantee the completeness, truthfulness, accuracy, or reliability of any Content or communications posted via the Service or endorse any opinions expressed via the Service. If you use or rely on any Content or materials posted via the Service or obtained by you through the Service, it is at your own risk.\n' +
                        'Talent Popcorn disclaims all liability as regards any of your Content, and will not be liable for (i) any loss or damage of any kind incurred as a result of the use of any Content in the Service, (ii) any losses, costs, or expenses resulting from the loss or corruption of your Content, and/or (iii) any third party claims relating to your Content.\n' +
                        'You agree not to upload Content to the Service that: (i) may create a risk of harm, loss, physical or mental injury, emotional distress, death, disability, disfigurement, or physical or mental illness to you, to any other person; (ii) may create a risk of any other loss or damage to any person or property; (iii) seeks to harm or exploit children by exposing them to inappropriate content, asking for personally identifiable details or otherwise; (iv) may constitute or contribute to a crime or tort; (v) contains any information or content that we deem to be unlawful, harmful, abusive, racially or ethnically offensive, defamatory, infringing, invasive of personal privacy or publicity rights, harassing, humiliating to other people (publicly or otherwise), libellous, threatening, profane, or otherwise objectionable; (vi) contains any information or content that is illegal (including, without limitation, the disclosure of insider information under securities law or of another party’s trade secrets); (vii) contains any information or content that you do not have a right to make available under any law or under contractual or fiduciary relationships; or (viii) contains any information or content that you know is not correct and current. \n' +
                        'You agree that any Content that you post does not and will not violate third party rights of any kind, including, without limitation, any intellectual property rights of third parties, or rights of privacy. We reserve the right to reject and/or remove any Content that we believe, in our sole discretion, violates the Terms.\n' +
                        'We also reserve the right to access, read, preserve, and/or disclose any information as we reasonably believe is necessary to (i) perform the Service, (ii) satisfy any applicable law, regulation, legal process or governmental request, (iii) enforce the Terms, including investigation of potential violations, (iv) detect, prevent, or otherwise address fraud, security or technical issues, (v) respond to user support requests, or (vi) protect the rights, property or safety of Talent Popcorn, its users and/or the public.\n' +
                        'You retain full ownership of your Content, but you hereby grant us a worldwide, non-exclusive, irrevocable, transferable, royalty-free license (with the right to sublicense) for the term of the Service, to use, copy, reproduce, process, adapt, modify, publish, transmit, edit, translate, make derivatives, display and distribute any and all Content in connection with providing the Service.\n' +
                        'This license also includes the right to modify or adapt your Content as part of the Service in order to transmit, display or distribute it over computer networks and in various media and/or make changes to your Content to conform and adapt that Content to any requirements or limitations of any networks, devices, services or media. \n' +
                        'You also extend these rights to the Third Party Services with whom we work to provide the Service. You represent and warrant that you have the rights and authority necessary to grant the rights granted herein to any Content that you submit, including all necessary rights to upload your content for use in accordance with the Terms. \n' +
                        'With written permission from you, we may use your name and logo on our website located at https://talentpopcorn.ai for the purpose of marketing Talent Popcorn and the Service. Use of your name and logo on the website will be revocable by you for any reason, at any time, provided you exercise this right in writing.\n' +
                        '\n' +
                        '4. Acceptable uses of Talent Popcorn\n' +
                        '\n' +
                        'Talent Popcorn is trusted by its users, and we trust you to use our Service responsibly. You agree not to misuse the Service. For example, you must not, and must not attempt to use the Service to or for (i) any unlawful purposes or for promotion of illegal activities; (ii) post any Content in violation of any applicable law, including intellectual property laws and right of privacy or publicity laws, or any contractual obligation; (iii) impersonate others, or otherwise misrepresent your affiliation with a person or entity in a manner that does or is intended to mislead, confuse, or deceive others; (iv) publish or post other people’s private or personally identifiable information, such as credit card numbers, street address or Social Security/National Identity numbers; (v) send spam; (vi) publish or link to malicious content intended to damage or disrupt another user’s browser or computer or to compromise a user’s privacy; (vii) access, tamper with, or use non-public areas of the Service, Talent Popcorn’s computer systems, or the technical delivery systems of Talent Popcorn’s providers; (viii) probe, scan, or test the vulnerability of any system or network or breach or circumvent any security or authentication measures; (ix) access or search the Service by any means other than our publicly supported interfaces (for example, “scraping”); (x) forge any TCP/IP packet header or any part of the header information in any email or posting, or in any way use the Service to send altered, deceptive or false source-identifying information; (xi) interfere with, or disrupt, the access of any user, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, mail-bombing the Service, or by scripting the creation of Content in such a manner as to interfere with or create an undue burden on the Service. \n' +
                        'This list is not exhaustive. You and all of your users agree to comply with all local laws regarding online conduct and acceptable content.\n' +
                        'We may investigate and/or suspend your account if you violate any of the above rules. Furthermore, we reserve the right to immediately terminate your account without further notice in the event that, in our sole and absolute judgment, you violate the Terms, or abuse the Service.\n' +
                        'Some use of our Service may require you to download a client software package (“Software”). Talent Popcorn hereby grants you a limited, nonexclusive, non-transferable, revocable license to use the Software, solely to access the Service. \n' +
                        'Your license to use the Software will be automatically revoked if you violate the Terms and if your Service term ends. You must not reverse engineer or decompile the Software, nor attempt to do so, nor assist anyone else to do so. Our Service may update the Software on your device automatically when a new version is available.\n' +
                        '\n' +
                        '5. Your Account\n' +
                        '\n' +
                        'You must provide us with accurate information when you create your Talent Popcorn account. Your Talent Popcorn account gives you access to the Service. \n' +
                        'We may maintain different types of accounts for different types of users or organizations. When you connect to Talent Popcorn through a Third Party Service, you thereby give us permission to access and use your information from that Third Party Service (on such terms as are permitted by that service), and to store your log-in credentials for that service. You may never use another user’s Service account without permission.\n' +
                        'You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with Talent Popcorn or a Third Party Service. \n' +
                        'We encourage you to use “strong” passwords that use a combination of upper and lower case letters, numbers and symbols with your account. You agree not to disclose your password to any third party. \n' +
                        'Talent Popcorn cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements. You must notify Talent Popcorn immediately upon becoming aware of any breach of security or unauthorized use of your account.\n' +
                        '\n' +
                        '6. Support\n' +
                        '\n' +
                        'Talent Popcorn will provide Technical Support to you via email support@talentpopcorn.ai on weekdays during the hours of 9:00 am through 5:00 pm EST, with the exclusion of Federal Holidays (“Support Hours”).  \n' +
                        'Talent Popcorn will endeavour to provide a response to all Helpdesk tickets within forty eight (48) hours. \n' +
                        '\n' +
                        '7. Confidential Information\n' +
                        '\n' +
                        '“Confidential Information” means all information and materials obtained by a party (the “Recipient”) from the other party (the “Disclosing Party”), whether in tangible form, written or oral, that is identified as confidential or would reasonably be understood to be confidential given the nature of the information and circumstances of disclosure, including without limitation any information about the business practices of either party and the Terms. Confidential Information does not include information that (a) is already known to the Recipient prior to its disclosure by the Disclosing Party; (b) is or becomes generally known through no wrongful act of the Recipient; (c) is independently developed by the Recipient without use of or reference to the Disclosing Party’s Confidential Information; or (d) is received from a third party without restriction and without a breach of an obligation of confidentiality. The Recipient shall not use or disclose any Confidential Information without the Disclosing Party’s prior written permission, except as necessary for the provision or use of the Service. The Recipient shall protect the confidentiality of the Disclosing Party’s Confidential Information in the same manner that it protects the confidentiality of its own confidential information, but using not less than a reasonable degree of care. The Recipient may disclose Confidential Information to the extent that it is required to be disclosed pursuant to a statutory or regulatory provision or court order, provided that the Recipient provides prior notice of such disclosure to the Disclosing Party, unless such notice is prohibited by law, rule, regulation or court order.\n' +
                        '“Customer Data” means all permitted electronic data stored by Customer or processed through use of the Subscription Services, Customer Data does not include Prohibited Information.\n' +
                        '“Prohibited Information” means credit or debit card numbers, passwords, protected health information as defined in HIPAA (45 C.F.R. § 160.103), and information relating to a customer or consumer of a financial institution under GLBA (15 U.S.C. §§ 6801–6809).\n' +
                        'These confidentiality obligations shall remain in effect for so long as the Confidential Information of the Disclosing Party is retained.\n' +
                        '\n' +
                        '8. California Consumer Privacy Act\n' +
                        '\n' +
                        '“CCPA” means the California Consumer Privacy Act of 2018, Cal. Civ. Code §1798.100 et. seq., and its implementing regulations;\n' +
                        'No Sale of Company Personal Information to Vendor. Company and Vendor hereby acknowledge and agree that in no event shall the transfer of Company Personal Information from Company to Vendor pursuant to the Agreement constitute a sale of information to Vendor, and that nothing in the Agreement shall be construed as providing for the sale of Company Personal Information to Vendor.\n' +
                        '\n' +
                        '9. Intellectual Property Rights in the Service\n' +
                        '\n' +
                        'All intellectual property rights, titles, and interests in and to the Service are and will remain the exclusive property of Talent Popcorn or its licensors (as applicable). The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. Nothing in the Terms gives you a right to use the Talent Popcorn name or any of the Talent Popcorn trademarks, logos, domain names, and other distinctive brand features. Any feedback, comments, or suggestions you may provide regarding Talent Popcorn, or the Service is entirely voluntary and you acknowledge and irrevocably agree that we will be free to use such feedback, comments or suggestions as we see fit and without any obligation to you.\n' +
                        'Customer retains all rights to its Customer Data, and Talent Popcorn may use the Customer Data only to provide the Subscription Services and as permitted by this Agreement, and not for any other purpose. Customer is the owner and data controller for its Customer Data. Customer is responsible for the accuracy, integrity, and for obtaining all legally-required consents for, and complying with all data protection laws applicable to the use of Customer Data in the Subscription Services, for examining and confirming results before use, and  adopting procedures for identifying and preventing errors in the Customer Data. Each party will use diligence in the protection of Customer Data and in preventing any unauthorized person(s)  from gaining access thereto. \n' +
                        '\n' +
                        '10. Data protection & Data Security\n' +
                        '\n' +
                        'We care about the privacy of our users. We may collect, use and share personally identifiable information and non-personally identifiable information as described in our Privacy Policy as accessible at ______________. To be clear, aside from the exceptions we identify in the Privacy Policy and the Terms, no matter how the Service changes, we won’t share your Content with others unless: (a) you have given us permission to do so; (b) we are required to by law or by valid legal process; or (c) we need to do so in order to provide you the Service. By using the Service, you agree to the collection of such information, and to have your personal data collected, used, transferred to, and processed by us and by third parties on our behalf.\n' +
                        'We shall implement and maintain reasonable administrative, physical and technical safeguards that are designed to prevent any unauthorized use, access, processing, destruction, loss, alteration, or disclosure of any of your data (including any applicant or employee data furnished by you as may be held or accessed by us).\n' +
                        '\n' +
                        '11. Third Party Links\n' +
                        '\n' +
                        'The Service may have links to third-party websites, advertisers, services, special offers, or other events or activities that are not owned or controlled by us. We do not endorse or assume any responsibility for any of these third-party sites, information, materials, products, or services. If you access a third party website from the Service, you do so at your own risk, and you understand that these Terms and our Privacy Policy do not apply to your use of those sites. You expressly relieve Talent Popcorn from any and all liability arising from your use of any third-party website, service, or content and agree that your dealings with any third-party website, service, or content is only between you and such third parties. You agree that we are not responsible for any loss or damage of any sort in your dealings with such third parties. Many of these third party services expressly disclaim all warranties, support or other liabilities or obligations to you in respect of their software or service. \n' +
                        '\n' +
                        '12. Modifications\n' +
                        '\n' +
                        'We may revise these Terms from time to time and the most current version will always be posted on our website. By continuing to access or use the Service after revisions become effective, you agree to be bound by the revised Terms. If you do not agree to any updated terms, please stop using the Service.\n' +
                        '\n' +
                        '13. Indemnity\n' +
                        '\n' +
                        'TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, YOU AGREE TO INDEMNIFY AND HOLD TALENT POPCORN, ITS AFFILIATES, OFFICERS, AGENTS, EMPLOYEES, SUPPLIERS, LICENSORS AND PARTNERS HARMLESS FROM AND AGAINST ANY AND ALL CLAIMS, LIABILITIES, DAMAGES (ACTUAL AND CONSEQUENTIAL), LOSSES AND EXPENSES (INCLUDING ATTORNEYS’ FEES) ARISING FROM OR IN ANY WAY RELATED TO ANY THIRD PARTY CLAIMS RELATING TO (A) YOUR USE OF THE SERVICE (INCLUDING ANY ACTIONS TAKEN BY A THIRD PARTY USING YOUR ACCOUNT), AND (B) YOUR VIOLATION OF THE TERMS. IN THE EVENT OF SUCH A CLAIM, SUIT, OR ACTION (“CLAIM”), WE WILL ATTEMPT TO PROVIDE NOTICE OF THE CLAIM TO THE CONTACT INFORMATION WE HAVE FOR YOUR ACCOUNT (PROVIDED THAT FAILURE TO DELIVER SUCH NOTICE SHALL NOT ELIMINATE OR REDUCE YOUR INDEMNIFICATION OBLIGATIONS HEREUNDER).\n' +
                        '\n' +
                        '14. Entire Agreement\n' +
                        '\n' +
                        'The Terms will constitute the entire agreement (“Agreement”) between Talent Popcorn and you with respect to the subject matter contemplated herein, and supersede all oral statements and prior writings with respect to the subject matter contemplated herein. This Agreement is entered into after full investigation by each party, and neither party is relying on any statement or representation made by the other not embodied in this Agreement.\n' +
                        '\n' +
                        '15. No Warranty - Talent Popcorn is available "As-Is"\n' +
                        '\n' +
                        'Though we want to provide a great service, there are certain things about the Service we can’t promise. THE SERVICE AND SOFTWARE ARE PROVIDED “AS IS”, AND TO THE FULLEST EXTENT PERMITTED BY LAW, NEITHER TALENT POPCORN, ITS AGENTS, AFFILIATES, LICENSORS, NOR SUPPLIERS, MAKE ANY WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE, AND NON-INFRINGEMENT. TALENT POPCORN MAKES NO REPRESENTATIONS ABOUT ANY CONTENT OR INFORMATION IN, OR FROM, AN END USER OR CUSTOMER SERVICES ACCOUNT. EXCEPT AS STATED IN THIS SECTION, TALENT POPCORN DOES NOT REPRESENT THAT CUSTOMER’S USE OF THE SERVICE OR SOFTWARE WILL BE SECURE, UNINTERRUPTED OR ERROR FREE. NO STATEMENT OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM TALENT POPCORN IN ANY MEANS OR FASHION SHALL CREATE ANY WARRANTY NOT EXPRESSLY AND EXPLICITLY SET FORTH IN THE TERMS.\n' +
                        'Talent Popcorn is not responsible for the accuracy, completeness, appropriateness, or legality of data, user posts, or any other information posted by a user.\n' +
                        'Talent Popcorn will have no responsibility for any harm to your computer system, loss or corruption of data, or other harm that results from your access to or use of the Service or Software.\n' +
                        'Neither Talent Popcorn nor its affiliates will      knowingly introduce any time bomb, virus or other harmful or malicious code designed to disrupt the use of the Subscription Services or Software. Talent Popcorn hereby warrants that it will provide Subscription Services: (a) with reasonable skill and care, (b) using appropriately qualified and experienced personnel, (c) in accordance with generally accepted industry standards. Talent Popcorn has the authority to enter into the agreement and perform its obligations in accordance with the terms of this Agreement.\n' +
                        '\n' +
                        '16. Limitation of Liability\n' +
                        '\n' +
                        'TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL TALENT POPCORN, ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, SUPPLIERS OR LICENSORS BE LIABLE FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL (INCLUDING LOSS OF USE, DATA, BUSINESS, OR PROFITS) DAMAGES, REGARDLESS OF LEGAL THEORY, WHETHER OR NOT TALENT POPCORN HAS BEEN WARNED OF THE POSSIBILITY OF SUCH DAMAGES, AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE; (B) AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE SERVICE MORE THAN THE GREATER OF $20 OR THE AMOUNTS PAID BY YOU TO TALENT POPCORN FOR THE PAST THREE MONTHS OF THE SERVICE IN QUESTION.\n' +
                        '\n' +
                        '17. Notice\n' +
                        '\n' +
                        'We may provide notifications, whether these are required by law or are for marketing or other business related purposes, to you via email notice, written or hard copy notice, or through posting of such notice on our website, as determined by us in our sole discretion.\n' +
                        'Notices sent via email are deemed received at the time that the notices are sent.\n' +
                        '\n' +
                        '18. Miscellaneous\n' +
                        '\n' +
                        'All sections of the Terms which by their nature should survive termination of any agreement between you and Talent Popcorn, will survive termination, including, without limitation, restrictions, accrued rights to payment, confidentiality obligations, intellectual property rights, warranty disclaimers, and limitations of liability.\n' +
                        'You agree that the Service is primarily delivered from Florida; and these Terms are governed by the internal substantive laws of the State of Florida, without respect to its conflict of laws principles. The application of the United Nations Convention on Contracts for the International Sale of Goods is expressly excluded. You consent to the exclusive jurisdiction and venue of courts in the state of Florida in all disputes arising out of or relating to the Terms. If any provision of these Terms shall be adjudged by any court of competent jurisdiction to be unenforceable or invalid, that provision shall be limited to the minimum extent necessary so that these Terms shall otherwise remain in effect. No waiver shall be implied from conduct or failure to enforce or exercise rights under these Terms. Nor will any waiver be effective unless in a writing signed by a duly authorized representative on behalf of the party claiming to have waived.\n' +
                        'These Terms and any rights and licenses granted hereunder, may not be transferred or assigned by you without our written permission, but may be assigned by us without restriction. Any attempted transfer or assignment by you will be null and void.\n' +
                        'No supplement, modification, or amendment of these Terms shall be binding unless executed in writing by a duly authorized representative of each party.\n' +
                        'Neither Talent Popcorn nor Customer will be liable for inadequate performance to the extent caused by a condition that was beyond the party’s reasonable control (for example, natural disaster or Internet disturbance).\n' +
                        'Talent Popcorn may suspend performance under these Terms if you cease business operations or become subject to insolvency proceedings and the proceedings are not dismissed within 90 days.\n' +
                        'Termination is not an exclusive remedy and the exercise of Talent Popcorn by any remedy under these Terms will be without prejudice to any other remedies it may have under these Terms, by law, or otherwise.\n' +
                        'Upon any termination, Customer shall promptly pay all unpaid fees due through the end of the term of any applicable Order Form. Upon expiration or any termination, Customer shall immediately cease use of the Subscription Services and remove Customer Data using the existing functionality in the Subscription Services. \n' +
                        'Talent Popcorn holds the data in Customer’s account as long as the Customer  chooses to use Talent Popcorn. Talent Popcorn reserves the right to terminate unpaid user accounts that are inactive for a continuous period of 180 days. In the event of such termination, all data associated with such user account will be deleted. We will provide you prior notice of such termination and option to back-up your data.\n' +
                        '\n' +
                        '19. Jurisdiction\n' +
                        '\n' +
                        'Where a dispute arises in connection with this agreement, the parties shall meet in good faith to attempt to resolve it. Each party shall bear its own costs in connection with such an action.\n' +
                        'Nothing in this Section shall be deemed as preventing Talent Popcorn from seeking injunctive or other equitable relief from the courts as necessary to protect any of Talent Popcorn’s proprietary interests. Except as otherwise provided in these Terms, all remedies are cumulative and in addition to (not in lieu of) any other remedies available to a party at law or equity.\n' +
                        '\n' +
                        'ALL CLAIMS MUST BE BROUGHT IN THE PARTIES’ INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. YOU AGREE THAT, BY USING THE SERVICE, YOU AND TALENT POPCORN ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.\n' +
                        '\n' +
                        'EXHIBIT A\n' +
                        '\n' +
                        'Technical and Organizational Security Measures\n' +
                        'Talent Popcorn will only use Customer Data for the purposes of fulfilling its obligations under the Agreement. Talent Popcorn will maintain and enforce physical and logical security procedures with respect to its access and maintenance of Customer Data contained on Talent Popcorn servers.  \n' +
                        'Talent Popcorn will use reasonable measures to secure and defend its location and equipment against “hackers” and others who may seek to modify or access the Talent Popcorn servers or the information found therein without authorization. Talent Popcorn will test its systems for potential security vulnerabilities at least annually.\n'}
                </div>
            </div>
        </Modal>

        <Modal
            open={showPrivacyPolicy}
            onClose={() => {
                setShowPrivacyPolicy(false)
            }}
        >
            <div style={{
                width: '900px',
                minWidth: '300px',
                maxHeight: '90vh',
                overflow: 'scroll',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                outline: 'none',
                background: 'white'
            }}>
                <div className="d-flex justify-content-between align-items-center"
                     style={{margin: '32px', width: 'calc(100% - 64px)'}}>
                    <div>
                        Privacy Policy
                    </div>
                    <CancelIcon sx={{
                        cursor: 'pointer'
                    }}
                                onClick={() => {
                                    setShowPrivacyPolicy(false)
                                }}
                    />
                </div>
                <Divider sx={{my: 0.5}}/>
                <div style={{margin: '32px', whiteSpace: 'pre-wrap'}}>
                    {`Terms of Service

Updated on: January 2, 2024

Talent Popcorn, “we” or “us” are committed to protecting and respecting your privacy. We provide this privacy notice (“Privacy Notice”) to explain how we collect, use and disclose certain personal information online via our group websites, and our SaaS or Subscription Service (as listed below, collectively referred to as “Site”).
This policy sets out the basis on which personal data is collected from you either through our Site or our Subscription Service (collectively “our Site”). 
Please read the policy carefully to understand our practices regarding our collection and use of your personal information and how it will be treated.
If you have any questions regarding our privacy practices or this Privacy Notice, please contact us using the contact details given below.

Who are “You”?

This Privacy Notice describes how we use “your” information. To understand which of our uses of information are actually applicable to you and your information see the following useful definitions.
You may be a(n):
Customer – an entity, or person representing an entity that has an agreement in place with LTG or one of its subsidiaries under which we provide you with SaaS or Subscription Service, or is visiting our Site to purchase SaaS or Subscription Service via an online payment system.
End-User – a person with a user account to one of our Customer’s instances of our SaaS or Subscription Service.
Visitor – a person who is visiting our website, or has expressed an interest in our products or services by filling in a contact form or sign-up sheet in relation to an event or webinar, or has had conversations about our products with our sales team and provided them with your details.
Your Information
Please select the correct option based on the above definitions to access the information applicable to you and your information.
Customer Information
Our Agreement with you is the best source of information on which personal data we process for you and how we use and protect that personal data. We encourage you to familiarize yourself with the terms of our agreement and reach out to your account manager if you have any questions.
Online Purchases
If you make a purchase via our Site this section shall apply.
When you click the “Subscribe” you will be taken to another site, managed by our payment processor in order for your payment to be processed. Your personal data will be securely encrypted and for further information upon this details of the privacy policy of our payment processor(s) are set forth below, and if applicable, in the email informing you of how to pay by credit or debit card. 
You will be required to provide your payment details, which will be made available to a third party payment gateway and merchant bank to process the transaction. 
We will not receive and/or store your payment details, but once you have made payment we will receive confirmation that your payment has been processed and with certain details regarding the transaction including the amount paid you will be required to provided your payment details.
For further information regarding the payment providers used on each of our sites, please see below. Please note that we are not responsible for the privacy practices or the content of such web sites, and this Privacy Policy does not apply to such sites. 
We encourage our users to be aware when they leave our site and to read the privacy statements of each and every site that collects personally identifiable information.
Payment Provider or Gateway

Stripe https://stripe.com/gb/privacy
End-user information
We have been provided with your information, and handle your information subject to an agreement with one of our clients. 
Our client may be your employer (or an affiliate of your employer) or another company which has either given you or asked you to sign up for an account with one of our Sites. 
Our client acts as ‘data controller’ for your information, and we act as ‘data processor’. This means that we do not determine how we collect or use your data. 
Our client determines how we collect or use your data, and provides us with instructions on what to do with it in our agreement with them. 
We only collect and use your data in accordance with the agreement between us and our client. Further to this,
Our client is responsible for the management of your account on our Site. If you have any questions or concerns regarding our use or collection of your data, or if you wish to make a request to exercise your rights as a data subject or a consumer (as explained below), please reach out to our client as they are best placed to assist you.
How do we collect your Information?
Your information will either have been provided to us by our client if they have set your account up for you, or provided to us by you where our client has invited you to set up an account or submit information such as a resume.
Which Types of Information Do We Collect?
Broadly, the types of data we collect may include:
- Account details (username, password, log-in data, profile data)
- Contact details (name, work/institution phone or email, personal phone or email (for recruitment Sites only))
- Employment data (job roles or descriptions, performance reviews, length of service, feedback, job title, division, development goals or plans, performance plans, salary data).
- Recruitment related data (resume/CV data, home address, qualifications, work history, job application data)

If you require more information about which of your information we use, please contact our client as they can advise you on which of your information they have used our Site to collect.
How Do We Use Your Information?
We only use your information to provide our services to our client in accordance with the agreement between us and our client. Our services to our client include the routine activities of providing cloud based services such as hosting, support, and maintenance. The majority of the time we have no need to access any individual’s information to provide these Services. There are only certain scenarios in which we would ever need to access your information. These scenarios largely are based around providing technical support to your or our client, in which case we may need to access your information to identify or resolve a technical issue. We will only ever access your information to the extent required, and with the permission of our client.
For more information about how we or our client use your information, please contact our client as they are best placed to provide you with this information.
Where We Store Your Information and for How Long
All of your information is stored on secure servers, with layers of security to keep your information protected. The location of the server on which your information is stored depends on the agreement with our client. The length of time for which we store your information is also determined by our agreement with our client. Our client as the ‘data controller’ is responsible for determining both of these things.
For more information about where and for how long we store your information, please contact our client as they are best placed to provide you with this information.
Visitor Information

How Do We Collect Your Information?

You directly provide us with the data that we collect. We collect data and process data when you:
- Complete a form on our website or via online advertising;
- Provide us with your personal data at an event;
- Sign up to a newsletter, contest, webinar, event, or other service;
- Register online or place an order for any of our products or services.
- Voluntarily complete a customer survey or provide feedback on any of our Sites or via email.
- Use or view our website via your browser’s cookies.

Data collected via online forms will advise which fields are mandatory and which are optional.

Which Types of Information do we Collect?

The types of information we may collect through our Sites includes:
- Contact Details (e.g. name, address, email address, phone and fax numbers, institution, title or job position),
- Your interest in products or services;
- Information about your interest in newsletters, webinars, events, or other services that we provide (solely or jointly with others); and
- Any other data you provide.

A visitor to our Sites who is not seeking employment or expressing interest in purchasing products or services is not required to reveal any personal information, provided that the applicable Site Cookie policy applies to all visitors.
How Do We Use Your Information?
We use information in the following ways:

- To provide you with information via post, email or phone about products or services you requested or we feel may interest you;
- To manage any query you have raised;
- To allow you to participate in interactive features of our services, when you choose to do so;
- To administrate our newsletters, surveys, and contests;
- To arrange and manage events and webinars;
- To administer our Site, and for internal operations (including troubleshooting, data analysis, testing, research, statistical purposes, and as part of our efforts to maintain the safety and security of our Sites)

Once we receive your information, it is subject to strict security measures in accordance with applicable law. We may transfer personal information to companies that help us to provide our Services and that we believe offer a secure solution that improves our Service delivery. We may also transfer personal data to our affiliates and partners.
If you are a system user of one of our customers and have questions regarding the processing of your personal information, please contact our respective customer directly as they control the handling of your data.

Cookies and Other Tracking Technologies
Our site uses cookies to distinguish you from other visitors to provide you with a better experience and to help us improve our site. A cookie is a small data file placed on the hard drive of your computer when you visit a website.

Types of Cookies

Our site may use session cookies, persistent cookies, and web beacons. A “session cookie” expires when you end your session (i.e. close your browser).  A “persistent cookie” stores information on your hard drive so when you end your session and return to the same website at a later date, the cookie information is still available. A “web beacon” is a small string of code that represents a clear graphic image, a redirect URL or JavaScript and is used in conjunction with a cookie. The cookies we place on our server are readable only by us, and cookies cannot access, read or modify any other data on your computer. We may use web beacons alone or in conjunction with cookies to compile information about your usage of our site and interaction with emails from us. For example, we may place web beacons in marketing emails that notify us when you click on a link in the email that directs you to our site, in order to improve our site and email communications.

Purposes of Cookie use

We use Cookies for several purposes. Please see information below on each of these purposes.
Preference Cookies
We use preference cookies to collect information about your choices and preferences, and to allow us to remember language or other local settings and customize our site accordingly.
Analytics Cookies
We also use analytics cookies to collect information about your use of our site, and to enable us to improve the way our site works. For example, analytics cookies show us which are the most frequently visited pages on our site, help us record any difficulties you have with our site, and show us whether our advertising is effective or not. This allows us to see the overall patterns of usage on our site, rather than the usage of a single person. We use the information to analyse the site traffic, but we do not examine this information for individually identifying information.
Marketing Cookies
We use Remarketing with Google Analytics to advertise online. Third party vendors, including Google, may show our ads on sites across the internet.
We, and third party vendors, including Google, use first-party cookies (such as the Google Analytics cookie) and third-party cookies (such as the DoubleClick cookie) together to inform, optimize, and serve ads based on someone’s past visits to our website. Remarketing cookies used by us expire after 30 days.
Controlling Cookies
Most web browsers have a “help” menu where you can review how to prevent your browser from accepting new cookies, how to have your browser alert you when you receive a new cookie or how to fully disable cookies on your browser. You can learn more about cookies at sites like www.aboutcookies.org. If you use your browser settings to block all cookies, you may not be able to access all or parts of our site.
Third Party Cookie providers
In addition to the cookies that we own, we may also use third-parties cookies on our Sites for the purposes detailed above.

As the owners of these cookies, you are entitled to information from these third parties regarding their privacy and security policies, and on how to opt-out from their cookies. You can find more information on our third party cookie providers below:

- Google services: Google tag manager, Google Analytics, DoubleClick, Google Optimize, etc. | https://policies.google.com/privacy

- LinkedIn | https://www.linkedin.com/legal/privacy-policy

What are your choices regarding cookies?
Necessary cookies, which are required for the core functionality of the website, are enabled by default automatically when you access the Site.
For all other categories of cookies used, which are not necessary for the site to operate, you can expressly consent to their use by clicking “accept” on the cookie banner which is set on the Site. If you do not wish to consent to the use of these cookies, you can opt out by returning to the Site and changing your cookie settings.
Additionally you can also decide whether or not to accept cookies through your internet browser’s settings. Most browsers have an option for turning off the cookie feature, which will prevent your browser from accepting new cookies, as well as (depending on the sophistication of your browser software) allow you to decide on acceptance of each new cookie in a variety of ways. You can also delete all cookies that are already on your computer. If you do this, however, you may have to manually adjust some preferences every time you visit a site and some of the Services and functionalities may not work.
By continuing to browse our Site, you are agreeing to our use of cookies.
Security
We understand that the security of any data we collect is of great importance. We therefore make sure it is well protected. We have appropriate physical, electronic, and managerial procedures to safeguard and secure the personal information we process. We follow generally accepted industry standards to protect personal information submitted to us both during transmission and once received. While we strive to protect your personal information, we cannot guarantee its absolute security and any transmission of personal information is therefore at your own risk.
If you are a Customer, and you require more information regarding our security measures, please refer to our agreement. If you are an End-User, please reach out to our client who controls your account. If you are a Visitor, please contact us using the methods detailed below for more information.
Disclosure of Your Information
Personal information may be disclosed to our ultimate holding company and its direct and indirect subsidiaries. Our employees are responsible for the internal security of confidential or proprietary information, and are only given access in accordance with the principle of least privilege, meaning they are only given access to your information when necessary and to the extent necessary for our operations to continue. Employees receive regular data protection and cyber security training, and are subject to LTG’s data protection and security policies. Employees who violate the data protection and security policies are subject to disciplinary action including, but not limited to termination.
Our general policy is not to disclose your personal information to any third-party without your prior consent. However, we may disclose your personal information to third parties in the following scenarios:
- We may use third-party vendors and hosting partners to provide the necessary hardware, software, networking, and other technology and services required to operate and maintain our Sites. As part of this, we may be required to transfer some of your personal information to these vendors and partners. Where we use third-party service providers, we choose vendors that enhance our services or practices and follow any legal requirements that apply to securing your data.
- If we sell or buy any business or assets in which case your personal information may be disclosed to the prospective seller or buyer.
- If we, or substantially all of our assets, are acquired by a third party, personal information held by us will be transferred as assets.
- We also reserve the right to disclose your personal data as required by law or in the good faith that disclosure is necessary to protect our rights. This may include exchanging information with other organizations for the purposes of fraud protection and credit risk reduction.

We do not share, sell, rent, or trade personal information with third parties for their promotional or other purposes. If this practice should change, we will update this policy to identify how individuals can opt-out.
Your Rights
Please see the sections below for further information about your rights in accordance with applicable law.  
GDPR and the Data Protection Act 2018 for EU/UK Residents
The General Data Protection Regulation (GDPR) is a regulation in the European Union (EU) and EEA on data protection and privacy and is applicable to all EEA residents. The Data Protection Act 2018 is the UK’s enactment of the GDPR, and applicable to all UK residents. You have the right to contact your local data protection authority and can request details of how to do this by contacting us at support@talentpopcorn.ai.
Legal basis for processing your personal data
We process your personal data on the following legal bases:
Type of Processing | Lawful Basis
########################################
Sending marketing information (to you as an individual) | Consent
Carrying out our contractual obligations to our Clients | Performance of a contract
Setting cookies | Consent
Sending marketing information to existing customers and business prospects | Legitimate interests

Data Subject Rights – EU and UK residents
As a EU or UK data subject, being a person resident in the EEA or UK, you are entitled to the following rights in regard to your personal data:
The right to access – You have the right to request that we provide you with copies of your personal data which we hold on record. In some scenarios, as permitted by law, we may charge you a small fee for this service.
The right to rectification – You have the right to request that we correct any information we have on record that you believe is inaccurate. You also have the right to request that we complete any information we have on record that you believe is incomplete.
The right to erasure – You have the right to request that we erase your personal data, under certain conditions.
The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.
The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.
The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
You can verify whether we hold any of your personal data, or make a data subject request by submitting a request via the support@talentpopcorn.ai. We may need to verify your identity before we are able to respond to the inquiry.
CCPA
The California Consumer Privacy Act (CCPA) is a law in the State of California on data protection and privacy and is applicable to all California residents.
Notice of collection and processing activities
Under the CCPA, you are entitled to certain information so that you are informed about how we collect your data, and how and why we use that data after collection. We encourage you to read this policy in full to get the clearest understanding, but we have summarized this information here:
Categories of personal information collected | As listed under the heading “Which Types of Data Do We Collect?” above
How personal information is collected | As listed under the heading “How Do We Collect Your Data” above.
Categories of sources from which the personal information is collected | We only collect personal information provided by you to us, either directly (e.g. by contact form, by email) or indirectly (e.g. via cookies). We may also collect your personal information from our partners when running joint webinars or events which you have signed up to. We do not collect personal information from any other sources.
Purpose of collection | For our legitimate and lawful sales and marketing operations, and as further detailed under the heading “How Do We Use Your Data?” above
Categories of third parties with whom the data has been shared | Your personal data may be shared with our subsidiaries and associated businesses, our third party providers (e.g. our hosting and analytics providers), and where we host a joint event with a partner which you sign up to, we may share you data with that partner.
Specific pieces of information | We can provide this information on request, in accordance with the procedure set out below.
Consumer Rights – California residents

As a Consumer, being a person resident in California, you are entitled to the following rights regarding your personal data:

Right to Opt-out – You have the right to opt out of an organization selling your information to third parties. We do not sell information to third parties at this time, but should this change we would update this policy to provide further information on our practices and the exercising of this right.

Right of notice – You have the right to be notified of which personal data we are collecting from you, the reasons why, and how it would be used. This policy provides you with this information.

Right of Disclosure – You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months.

Right of Deletion – You have the right to request that we delete any personal information we have collected from you in the last 12 months, in certain circumstances.

Right to Equal Services and Prices – You have the right to exercise your rights under the CCPA free of discrimination, and we will never deny you goods or services, charge you a different price, provide you with a different level or quality of goods or services based on your exercising your rights to your data.
You may only make a request to exercise your rights of disclosure within a twelve (12) month period.
COPPA
In accordance with the Children’s Online Privacy Protection Act (“COPPA”) and the GDPR, our Sites are not intended for use by children. We do not knowingly request, solicit, access, or receive personally identifiable information from anyone under the age of 13 and 16 respectively.
Where we are aware of any personal information submitted through our Sites belonging to a child, we will delete this data from our records.
Exercising your Rights
To exercise your rights under the GDPR, the Data Protection Act 2018, or the CCPA, you may make a request via the support@talentpopcorn.ai.
To enable us to process your request, we may require you to:
- Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative.
- Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.
Please note that we would be unable to respond to your request, or provide you with any personal data if we are unable to verify your identity or authority to make the request and confirm the personal information relates to you.

We endeavor to respond to a request within thirty (30) days of receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing.

We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive or onerous, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.

Third Party Websites

Our Sites may contain links to and from websites of our partner networks, clients, and/or affiliates. Please note that these websites are subject to their own privacy policies and we do not accept any liability for these sites and policies. Please check these policies before you submit any personal data to these websites.

Corporate and Contact Details

You can contact us by email via support@talentpopcorn.ai. 

Changes to this Privacy Notice

We reserve the right to modify this Privacy Notice at any time, so review it frequently. If material changes are made, it will be reposted and made available from our homepage so you are aware of what personal information we collect, how we use it, and under what circumstances (if any) we disclose it.
`}
                </div>
            </div>
        </Modal>

    </>)
}