import {
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Skeleton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useEffect, useState } from 'react';
import { StepType } from './NewStep';
import { ActionsMenu } from './ActionsMenu';

export default function WFTable({
  data = [],
  onClick = () => {},
  rowsPerPage = 5,
}: {
  data: object[];
  selected?: object[];
  onSelect?(item: object | object[], sData?: object[]): void;
  onSort?(cell: { [name: string]: boolean }): void;
  onClick?(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: object
  ): void;
  paginate?: boolean;
  rowsPerPage?: number;
}) {
  const tableHeader = [
    {
      name: 'Name',
      key: 'name',
      width: 'auto',
    },
    {
      name: 'Workflow steps',
      key: 'flow',
      width: 'auto',
    },
    {
      name: 'Vacancies',
      key: 'vacancies',
      width: 'auto',
    },
    {
      name: 'Actions',
      key: 'actions',
      width: 'auto',
    },
  ];

  const cs = tableHeader.map((el) => ({ ...el, key: el.key || el.name }));

  const onClickHandler = (e, item) => {
    onClick(e, item);
  };

  const loadings = data.filter((a) => !a);

  const dataRow = (item: any, cell: any) => {
    if (cell.key === 'actions') {
      return <ActionsMenu data={item} header="Delete workflow" />;
    }

    if (Array.isArray(item[cell.key])) {
      return item[cell.key]
        .map((flow: any) => flow.name || flow.position_name)
        .join(', ');
    }

    return item[cell.key];
  };

  return (
    <>
      <TableContainer
        component={Paper}
        elevation={0}
        // sx={{ maxHeight: '50vh' }}
      >
        <Table
          sx={{ minWidth: 650, marginBottom: '32px' }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {cs.map((cell, i) => (
                <TableCell key={cell.name + i} align={!i ? 'left' : 'center'}>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      justifyContent: !i ? 'start' : 'center',
                    }}
                  >
                    <span style={{ marginRight: '4px', whiteSpace: 'nowrap' }}>
                      {cell.name}
                    </span>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loadings.length ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <div style={{ fontSize: '9px' }}>
                    Loadings {loadings.length} vacanc
                    {loadings.length > 1 ? 'ies' : 'y'}...
                  </div>
                  <Skeleton
                    animation="wave"
                    sx={{
                      width: '100%',
                      height: '25px',
                      margin: '0',
                      mt: '0px',
                    }}
                  />
                </TableCell>
              </TableRow>
            ) : (
              ''
            )}
            {data.map((item: any, index) =>
              !item ? (
                ''
              ) : (
                <TableRow
                  key={index}
                  hover
                  onClick={(e) => onClickHandler(e, item)}
                >
                  {cs.map((cell, i) => (
                    <TableCell
                      key={cell.name + i}
                      align={!i ? 'left' : 'center'}
                    >
                      {dataRow(item, cell)}
                    </TableCell>
                  ))}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
