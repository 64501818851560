import React, {  useEffect, useState } from "react";
import useTo from "./useTo"
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { getUser } from "../../redux/slices/userSlice";

/**
 * 
 * @param backDoor - path to welcome page
 * @returns [PrivateAreaComponent, PrivateContentComponwnt]   
 * @example  
 * <PrivateAreaComponent /> // rederected to welcom page automaticly     
 * <PrivateContentComponent content={<>hello user</>} repl={<>Hello quest</>} /> // render content for user or repl or null for quest      
 */
export default function usePrivate(backDoor?: string | undefined): Array<React.FC<any>>{
    const dispatch = useAppDispatch();
    const {data: userData, session} = useAppSelector(state => state.user);
    const [check, setCheck] = useState(false);

   
    useEffect(() => {
        if(!session?.checked && !check) {
            dispatch(getUser());
            setCheck(true);
        }
    },[setCheck, dispatch, check, session?.checked]);

    const userCheck = !(session?.checked && !userData?.email);
    
    
    return [ 
        
        /**
         * rederected to welcom page automaticly
         * @returns void
         */
        () => PrivateArea({backDoor, userCheck}),
        /**
         * 
         * @param content - content for user
         * @param repl - content for quest
         * @returns content or repl or null
         */
        ({content, repl}:{content: JSX.Element, repl: JSX.Element}) => PrivateContent({content, repl, userCheck})
    ]
}


function PrivateArea({ backDoor, userCheck}: any) {
    const to = useTo();
    
    useEffect(()=>{
        if(!userCheck) to(backDoor || '/main')
    })

    return  React.createElement(React.Fragment, {})
}

function PrivateContent({content, repl, userCheck}: any) {
    

    return !userCheck ? 
        repl? React.cloneElement(repl, repl.props) : null :
        React.cloneElement(content, content.props);

    // return !userData?.email && session?.checked ? 
    //     repl? repl : null :
    //     content;
}