import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';

export default function DropFilesWidget({
  files,
  text,
  onLoad,
  sx = {},
}: {
  text?: string;
  onLoad: (files: FileList | unknown[], clear: () => void) => void;
  sx?: any;
  files?: any[];
}) {
  const [filesState, setFiles] = useState<any | null>(null);
  useEffect(() => {
    if (
      files &&
      files?.map((f) => f.name) === (filesState || []).map((f) => f.name)
    )
      setFiles(files);
  });

  const dropHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFiles(Array.from(e.dataTransfer.files));
    onLoad(Array.from(e.dataTransfer.files), () => setFiles(null));
  };

  const selectHandler = (e) => {
    setFiles(e.target.files);
    onLoad(e.target.files, () => setFiles(null));
  };

  const dragOverHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Button variant="contained" startIcon={<UploadFileIcon />}>
      <label
        htmlFor="fileInput"
        onDragOver={dragOverHandler}
        onDrop={dropHandler}
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          cursor: 'pointer',
        }}
      />
      {filesState?.length
        ? [].map.call(filesState, (f: any) => f.name).join(', ')
        : text
          ? text
          : `New Candidate`}

      <input
        id="fileInput"
        type="file"
        name="files"
        multiple={true}
        style={{ display: 'none' }}
        onChange={selectHandler}
      />
    </Button>
  );
}
