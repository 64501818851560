import { Button, Divider, Grid, TextField } from '@mui/material';
import { ReactComponent as LogoSvg } from '../../../static/Logo.svg';
import validator from 'validator';
import { useMemo, useState } from 'react';
import { useAppDispatch } from '../../../redux/store';
import Box from '@mui/material/Box';
import { requestOnResetPassword } from '../../../redux/slices/userSlice';
import { useLocation } from 'react-router-dom';

export default function PasswordRecoveryRequest() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [form, setForm] = useState({ email: '' });
  const [valid, setValid] = useState({ email: true });
  const queryParams = new URLSearchParams(location.search);

  const text = useMemo(() => {
    let text: React.ReactNode = 'Your account email';

    if (!valid.email) {
      text = 'Email is not valid';
    }
    if (!valid.email) {
      text = (
        <span>
          The account is either not located or has been deactivated. If you
          require assistance, please contact our support team at{' '}
          <a href="mailto:support@talentpopcorn.ai">support@talentpopcorn.ai</a>
        </span>
      );
    }

    return text;
  }, [valid]);

  const recoveryHandler = () => {
    const email = validator.isEmail(form.email);
    setValid({ email });

    if (!email) return;

    dispatch(requestOnResetPassword({ email: form.email }));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '4rem 0',
      }}
    >
      <Box
        sx={{
          boxShadow: 2,
          borderRadius: 2,
          minWidth: 300,
          maxWidth: 500,
          background: '#ffffff',
        }}
      >
        {/*<Box sx={{p: 4,}} style={{cursor: 'pointer'}} onClick={() => to('/welcome')}>*/}
        <Box sx={{ p: 4 }} style={{ cursor: 'pointer' }}>
          <Box
            justifyContent="flex-start"
            alignItems="center"
            display={'inline-flex'}
          >
            <LogoSvg />
            <span
              className="text"
              style={{
                paddingLeft: '10px',
                fontSize: '20px',
                lineHeight: '24px',
                letterSpacing: '0.15px',
                fontWeight: 'bold',
                color: '#333333',
              }}
            >
              Talent Popcorn
            </span>
          </Box>
        </Box>

        <Divider sx={{ my: 0.5 }} />

        <Box sx={{ p: 4 }}>
          {queryParams.get('scene') !== 'password-recovery' ? (
            <Grid container rowSpacing={1} columnSpacing={2}>
              <Grid item xs={12}>
                <h1
                  style={{
                    fontSize: '1.5rem',
                    padding: 0,
                    margin: 0,
                  }}
                >
                  Password Recovery
                </h1>
                <p
                  style={{
                    fontSize: '1rem',
                    color: '#333333',
                    margin: '1rem 0 0 0',
                  }}
                >
                  Please provide your account email address for password
                  recovery. We will send recovery email to you if such email
                  registered in our system.
                </p>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="standard"
                  type="email"
                  label="Email"
                  placeholder="example@mailbox.com"
                  value={form.email}
                  error={!valid.email}
                  helperText={text}
                  onChange={(e) =>
                    setForm((prev: any) => ({
                      ...prev,
                      email: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item sx={{ mt: 4 }} xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={{ fontSize: '1rem' }}
                  onClick={recoveryHandler}
                >
                  Reset Password
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container rowSpacing={1} columnSpacing={2}>
              <Grid item xs={12}>
                <h1
                  style={{
                    fontSize: '1.5rem',
                    padding: 0,
                    margin: 0,
                  }}
                >
                  Password Recovery
                </h1>
                <p
                  style={{
                    fontSize: '1rem',
                    color: '#333333',
                    margin: '1rem 0 0 0',
                  }}
                >
                  Your request has been successfully processed. An email
                  containing a link to reset your password has been sent to you.
                  You can close this page now.
                </p>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </Box>
  );
}
