import { Card } from '@mui/material';
import useTo from '../hooks/useTo';
import React from 'react';
import useWidth from '../hooks/useWidth';

/**
 *
 * @param props - component props
 * @param props.scene - //url?scene=<nameOfScene>
 * @param props.children - react.component.children
 * @param props.dark - fore focus to content
 * @param props.top - css for indent from top or "centerFore400" for children hieght 400px
 * @param props.right - css for indent from right or "centerFore400" for children width 400px
 * @param props.bottom - css for indent from bottom or "centerFore400" for children hieght 400px
 * @param props.left - css for indent from left or "centerFore400" for children width 400px
 * @param props.locked - locked if cannot be closed without selecting an option
 * @returns
 */
export default function EntityModal({
  key,
  scene,
  children,
  dark,
  top,
  right,
  left,
  bottom,
  locked = false,
}: {
  key?: string;
  scene: string;
  children: any;
  dark?: boolean;
  top?: string;
  right?: string;
  left?: string;
  bottom?: string;
  locked?: boolean;
}) {
  const to = useTo();
  const [vw, vh] = useWidth();

  const { scene: q } = Object.fromEntries(
    new URLSearchParams(window.location.search)
  );
  const position: any = {};

  const calculatePos = (param, vw, w) =>
    param.includes('centerFore') ? `${(vw - (vw > w ? w : vw)) / 2}px` : param;
  if (right)
    position.right = calculatePos(
      right,
      vw,
      Number(right.replace('centerFore', ''))
    );
  if (left)
    position.left = calculatePos(
      left,
      vw,
      Number(right.replace('centerFore', ''))
    );
  if (top)
    position.top = calculatePos(
      top,
      vh,
      Number(right.replace('centerFore', ''))
    );
  if (bottom)
    position.bottom = calculatePos(
      bottom,
      vh,
      Number(right.replace('centerFore', ''))
    );

  const closeOrOpenPop = () => {
    if (window.location.href.includes(scene)) {
      document.body.style.overflow = '';
      to(null, {});
    } else {
      to(null, { scene });
    }
  };
  const toN = (s) =>
    s
      ? +(s + '')
          .replace('px', '')
          .replace('%', '')
          .replace('vh', '')
          .replace('vw', '')
          .replace('em', '')
          .replace('rem', '')
      : 0;

  return (
    <>
      {q === scene && (
        <div
          key={key}
          style={{
            position: 'fixed',
            zIndex: 11,
            height: `calc(100vh - 64px)`,
            right: 0,
            minWidth: '650px',
            width: '40vw',
            ...position,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <Card
            variant="outlined"
            sx={{
              height: 'calc(100vh - 64px)',
              overflowY: 'auto',
              borderRadius: 'unset',
            }}
          >
            {React.cloneElement(
              children,
              { ...children.props, close: closeOrOpenPop },
              children.children
            )}
          </Card>
        </div>
      )}
    </>
  );
}
