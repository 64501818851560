import {useMemo, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../../../redux/store';
import {editUserData} from '../../../../../redux/slices/userSlice';
import {Box, Button, TextField} from '@mui/material';
import TileInner from '../../../pices/TileInner';
import validator from 'validator';
import {Avatar} from './Avatar';
import styles from './Account.module.css';
import {useLocation, useNavigate} from 'react-router-dom';

const Details = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.data);

  const userData = useMemo(() => user, [user, dispatch]);

  const [form, setForm] = useState({
    _id: userData?._id,
    name: userData?.name,
    surname: userData?.surname,
    email: userData?.email,
    company: userData?.company,
    phone: userData?.phone,
  });
  const [valid, setValid] = useState({
    name: true,
    surname: true,
    company: true,
    phone: true,
  });

  const saveHandler = () => {
    const [name, surname, company, phone] = [
      form.name.length > 2,
      form.surname.length > 2,
      form.company.length > 2,
      !!form.phone ? validator.isMobilePhone(form.phone, 'any') : true,
    ];
    setValid({ name, surname, company, phone });
    if (!name || !surname || !company || !phone) return;

    dispatch(editUserData((({ ...form }) => ({ ...form }))(form)));
  };

  const openPop = (scene: string) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('scene', scene);
    navigate(`${location.pathname}?${queryParams}`);
  };

  return (
    <div className={styles.details}>
      <div className={styles.content_right}>
        <TileInner text="Edit Profile Details" line>
          <Box
            component="form"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <b className="bold_style">Personal</b>

            <TextField
              fullWidth
              variant="standard"
              type="text"
              label="First Name"
              placeholder="Name"
              value={form.name}
              error={!valid.name}
              helperText="Your first name here"
              onChange={(e) =>
                setForm((prev: any) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
            />

            <TextField
              fullWidth
              variant="standard"
              type="text"
              label="Surname"
              placeholder="Popcorn"
              value={form.surname}
              error={!valid.surname}
              helperText="Your last name here"
              onChange={(e) =>
                setForm((prev: any) => ({
                  ...prev,
                  surname: e.target.value,
                }))
              }
            />

            <b className="bold_style">Corporate</b>

            <TextField
              fullWidth
              variant="standard"
              type="text"
              label="Company"
              placeholder="Company name"
              value={form.company}
              error={!valid.company}
              helperText="Your company name here"
              onChange={(e) =>
                setForm((prev: any) => ({
                  ...prev,
                  company: e.target.value,
                }))
              }
            />

            <b className="bold_style">Contact Details</b>

            <TextField
              fullWidth
              variant="standard"
              type="email"
              label="Email"
              placeholder="talent@popcorn.com"
              value={form.email}
              helperText="Your email here"
              disabled
            />
            <TextField
              fullWidth
              variant="standard"
              type="phone"
              label="Phone Number"
              placeholder="+111-22-333-44-55"
              value={form.phone}
              error={!valid.phone}
              helperText="Phone number"
              onChange={(e) =>
                setForm((prev: any) => ({
                  ...prev,
                  phone: e.target.value,
                }))
              }
            />
          </Box>

          <Button
            variant="contained"
            size="large"
            sx={{
              width: 'fit-content',
              fontSize: '0.9rem',
              fontWeight: 'bold',
              marginTop: '1rem',
            }}
            onClick={saveHandler}
          >
            Save
          </Button>
        </TileInner>
      </div>
      <div className={styles.content_left}>
        {/* Subscription */}
        {!!!userData?.is_subscription_active && (
          <div className={styles.subscription}>
            <b>Subscription</b>
            <p>
                You don't have an active subscription yet. Select one of our subscription plans to unlock more features with Talent Popcorn.
            </p>
            <Button
              variant="contained"
              size="large"
              sx={{
                width: 'fit-content',
                fontSize: '0.9rem',
                fontWeight: 'bold',
                background: '#fff',
                color: '#333',
                '&:hover': {
                  backgroundColor: 'primary.main',
                  color: 'white',
                },
              }}
              onClick={() => openPop('tariff-selection')}
            >
              Subscribe
            </Button>
          </div>
        )}
        {/* Profile Picture */}
        <TileInner text="Profile Picture" line>
          <Avatar />
        </TileInner>
      </div>
    </div>
  );
};

export default Details;
