import { Grid, Slider } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch } from '../../../redux/store';
import {
  getApplicants,
  updateApplicants,
} from '../../../redux/slices/appSlice';

export const GradeSlider: React.FC<{
  label: string;
  grade: number;
  vacancy_id: string;
  scoring?: any;
  propertyName: string;
  applicant_id?: string;
  xs: number;
  style: any;
}> = ({
  grade = 0,
  vacancy_id,
  applicant_id,
  scoring,
  label,
  propertyName,
  xs,
  style,
}) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [sliderValue, setSliderValue] = useState(grade);
  const sliderRef = useRef<HTMLDivElement>(null);
  const isMouseDownInside = useRef(false);

  const valuetext = (value: number) => {
    return `${value}`;
  };

  const handleSliderChange = (e, newValue) => {
    setSliderValue(newValue);
  };

  const handleChange = (grade: number) => {
    if (!applicant_id) return;
    setIsLoading(true);
    dispatch(
      updateApplicants({
        applicants: [
          {
            applicant_id: applicant_id,
            applicant: {
              scoring: { ...scoring, [propertyName]: grade },
            },
          },
        ],
      })
    ).finally(() => setIsLoading(false));
    dispatch(getApplicants({ vacancy_id }));
  };

  useEffect(() => {
    const handleMouseMoveOutside = (e: MouseEvent) => {
      if (
        sliderRef.current &&
        !sliderRef.current.contains(e.target as Node) &&
        isMouseDownInside.current
      ) {
        handleChange(sliderValue);
        isMouseDownInside.current = false;
      }
    };

    const handleMouseUp = () => {
      if (isMouseDownInside.current) {
        handleChange(sliderValue);
        isMouseDownInside.current = false;
      }
    };

    document.addEventListener('mousemove', handleMouseMoveOutside);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMoveOutside);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [sliderValue]);

  return (
    <Grid item xs={xs} sx={{ fontWeight: 700 }}>
      <div style={style}>{`${label}: ${grade}/10`}</div>
      <div>
        <Slider
          key={grade}
          aria-label={label}
          defaultValue={grade}
          getAriaValueText={valuetext}
          onChange={handleSliderChange}
          onMouseDown={() => {
            isMouseDownInside.current = true;
          }}
          disabled={isLoading}
          valueLabelDisplay="auto"
          step={1}
          marks
          min={0}
          max={10}
        />
      </div>
    </Grid>
  );
};
