import { Breadcrumbs, Divider, Link, Typography } from '@mui/material';
import MainTitle from '../../pices/MainTitle';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import useTo from '../../../hooks/useTo';
import { SearchFlowBlock } from './components/SearchFlowBlock';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { useEffect, useMemo } from 'react';
import { clearWorkflow, getWorkflows } from '../../../../redux/slices/appSlice';
import WFTable from './components/WFTable';

export const WorkFlows = () => {
  const dispatch = useAppDispatch();
  const to = useTo();
  const list = useAppSelector((state) => state.app).workflows || [];

  const dataTable = useMemo(() => list, [dispatch, list]);

  useEffect(() => {
    dispatch(clearWorkflow());
    dispatch(getWorkflows(null));
  }, []);

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon />} sx={{ mb: '32px' }}>
        <Link
          underline="hover"
          key="2"
          color="inherit"
          href=""
          onClick={(e) => {
            e.preventDefault();
            to('/dashboard');
          }}
        >
          Home
        </Link>
        <Typography key="3" color="text.primary">
          Workflows
        </Typography>
      </Breadcrumbs>

      <MainTitle header="Workflows" />

      <Divider sx={{ mb: '1.5rem' }} />

      <SearchFlowBlock />

      <Divider sx={{ marginBlock: '1.5rem' }} />

      <WFTable data={dataTable} />
    </>
  );
};
