import { Box, Button, Card, CardActions, LinearProgress } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { UserData } from '../../../redux/slices/userSlice';

export const TariffLimits: React.FC<{ data: UserData | null }> = ({ data }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const calculatePercentage = (value: number, total: number) => {
    return (value / total) * 100;
  };

  const count = (value: number, limit: number) => {
    return value > limit ? limit : value;
  };

  const openPop = (scene: string) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('scene', scene);
    navigate(`${location.pathname}?${queryParams}`);
  };

  return (
    <Card sx={{ width: '100%', mb: '1.5rem' }}>
      <CardActions
        sx={{
          display: 'flex',
          flexFlow: 'row wrap',
          justifyContent: 'space-between',
          alignItems: 'end',
          padding: '16px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'row wrap',
            gap: '2rem',
          }}
        >
          <Box sx={{ width: '220px' }}>
            <div style={{ paddingBottom: '0.5rem' }}>
              {`${data?.activeVacanciesCount || 0} of 3 free vacancies used`}
            </div>
            <LinearProgress
              variant="determinate"
              value={calculatePercentage(
                count(data?.activeVacanciesCount, 3),
                3
              )}
              color={
                calculatePercentage(count(data?.activeVacanciesCount, 3), 3) ===
                100
                  ? 'error'
                  : 'primary'
              }
            />
          </Box>
          <Box sx={{ width: '220px' }}>
            <div style={{ paddingBottom: '0.5rem' }}>
              {`Candidate limit reached (${data?.applicantsCount || 0} of 60)`}
            </div>
            <LinearProgress
              variant="determinate"
              value={calculatePercentage(count(data?.applicantsCount, 60), 60)}
              color={
                calculatePercentage(count(data?.applicantsCount, 60), 60) ===
                100
                  ? 'error'
                  : 'primary'
              }
            />
          </Box>
        </Box>
        <Button
          variant="contained"
          className="btn_yellow"
          onClick={() => openPop('tariff-selection')}
        >
          Subscribe
        </Button>
      </CardActions>
    </Card>
  );
};
