import {Box, Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField,} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import {useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {useAppDispatch} from '../../../../../redux/store';
import {createStep, createWorkflows, Flow, updateStep,} from '../../../../../redux/slices/appSlice';
import useTo from '../../../../hooks/useTo';
import {defaultFlows} from '../../../../../assets/constants';

export interface StepType {
  code: string;
  type: string;
  description?: string;
  name: string;
  _id?: string;
  editable: boolean;
  system?: boolean;
}

export const NewStep: React.FC<{
  data: Flow;
  id: string;
  step?: StepType;
  close?: Function;
}> = ({ data, id, step, close = () => {} }) => {
  const to = useTo();
  const dispatch = useAppDispatch();
  const [form, setForm] = useState({
    name: step?.name || '',
    description: step?.description || '',
    type: step?.type || 'default',
  });
  const [valid, setValid] = useState({
    name: true,
  });

  const saveHandler = () => {
    const name = form?.name?.length > 2;

    setValid({ name });

    if (!name) return;

    const new_step = {
      code: uuidv4().slice(0, 8),
      type: form.type,
      description: form?.description,
      name: form.name,
      editable: true,
    };

    const stepList = step?._id
      ? data?.flow?.map((item) => {
          if (item._id === step._id) {
            return {
              ...item,
              type: form.type,
              description: form?.description,
              name: form.name,
            };
          }
          return item;
        })
      : [...(data?.flow || []), new_step];

    const mergeFlows = () => {
      const newFlows = defaultFlows.filter(
        (defaultFlow) =>
          !stepList.some((flow) => flow.code === defaultFlow.code)
      );

      const result = [...stepList, ...newFlows];

      return result;
    };

    const new_workflow = {
      ...data,
      flow: mergeFlows(),
    };
    if (id) {
      if (step) {
        dispatch(updateStep({ _id: id, updatedFlow: new_workflow }));
      } else {
        dispatch(createStep({ _id: id, newFlow: new_workflow }));
      }
    } else {
      dispatch(createWorkflows(new_workflow));
      to('workflows');
    }
    close();
  };

  return (
    <Box
      sx={{
        minWidth: '350px',
        maxWidth: '100%',
        maxHeight: '90vh',
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          background: 'white',
          width: 'inherit',
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{ p: '2rem 2rem 1.5rem' }}
        >
          <Grid item>
            <h3 style={{ padding: 0, margin: 0 }}>{`${
              id ? 'Edit' : 'New'
            } Funnel Step`}</h3>
          </Grid>

          <Grid item>
            <CancelIcon
              style={{
                color: '#999999',
                cursor: 'pointer',
                marginLeft: '1rem',
              }}
              onClick={() => close()}
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container sx={{ p: '1rem 2rem' }}>
        <Box
          component="form"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <b className="bold_style">Detailes</b>

          <TextField
            fullWidth
            variant="standard"
            type="text"
            label="Name"
            placeholder="Name"
            value={form.name}
            error={!valid.name}
            helperText="Choose a name for the scheme that makes it easy to tell and remember what job type it's for."
            onChange={(e) =>
              setForm((prev: any) => ({
                ...prev,
                name: e.target.value,
              }))
            }
          />

          <TextField
            fullWidth
            variant="standard"
            type="text"
            label="Description"
            placeholder="Description"
            value={form.description}
            helperText="Choose a name for the scheme that makes it easy to tell and remember what job type it's for."
            onChange={(e) =>
              setForm((prev: any) => ({
                ...prev,
                description: e.target.value,
              }))
            }
          />

          <b className="bold_style">Select type</b>
          <p style={{ color: '#666' }}>
            Every funnel step could be associated with ‘type’, candidates funnel
            step will be calculated depending selected type as new, declined,
            selected for a interview or as finalist.
          </p>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={step?.type || 'default'}
              name="radio-buttons-group"
              onChange={(e) =>
                setForm((prev: any) => ({
                  ...prev,
                  type: e.target.value,
                }))
              }
            >
              <FormControlLabel
                value="default"
                control={<Radio />}
                label="Default"
              />
              <FormControlLabel
                value="iterview"
                control={<Radio />}
                label="Interview"
              />
              <FormControlLabel
                value="final"
                control={<Radio />}
                label="Final"
              />
              <FormControlLabel value="new" control={<Radio />} label="New" />
              <FormControlLabel
                value="declined"
                control={<Radio />}
                label="Declined"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <div
          className="d-flex justify-content-end"
          style={{ width: '100%', marginTop: '1rem' }}
        >
          <Button
            variant="outlined"
            style={{
              color: '#333',
              borderColor: '#333',
              margin: '0 8px',
            }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={saveHandler}>
            Save
          </Button>
        </div>
      </Grid>
    </Box>
  );
};
