import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { urls, herald } from '../urls';
import { setUser } from './userSlice';
import { getApplicants } from './appSlice';

// --- ACTIONS ---

export const sendFeedback = createAsyncThunk(
  'feedback/sendFeedback',
  async (
    {
      entity_id,
      type,
      resultObj,
      vacancy_id,
    }: {
      entity_id: string;
      resultObj: object;
      type: 'Applicant';
      vacancy_id?: string;
    },
    { dispatch }
  ) => {
    try {
      const data = { entity_id, collectionName: type, result: resultObj };
      const response = await herald(urls.feedback(), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      if (response.status === 403) dispatch(setUser({ email: null }));
      if (!response.ok) throw Error();

      vacancy_id && dispatch(getApplicants({ vacancy_id }));
      return await response.json();
    } catch (error) {}
  }
);

// --- SLICE ---

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState: null,
  reducers: {},
  extraReducers: (builder) => {
    // builder.addCase(caseName.fulfilled, (state, action) => {
    //   // both `state` and `action` are now correctly typed
    //   // based on the slice state and the `pending` action creator
    // })
  },
});

// export const {} = feedbackSlice.actions
export default feedbackSlice;
