import Pop from '../Pop';
import AreYouShure from './AreYouShure';
import { useAppDispatch } from '../../../redux/store';
import { changeVacancy, getApplicants } from '../../../redux/slices/appSlice';
import EntityModal from '../EntityModal';
import ViewVacancy from './ViewVacancy';
import AddVacancy from './AddVacancy';
import useTo from '../../hooks/useTo';

export interface DataType {
  _id: string;
  createdAt: Date;
  updatedAt: Date;
  position_description: string;
  company_name: string;
  position_name: string;
  stats: any[];
  status: string;
  flow: any[];
  is_favorite: boolean;
}

export const VacancyPopups: React.FC<{
  data: DataType;
  vacancyUpdate?: (value: boolean) => void;
}> = ({ data, vacancyUpdate }) => {
  const dispatch = useAppDispatch();
  const to = useTo();
  const currentUrl = window.location.href;
  const vacancy_id = Object.fromEntries(
    new URLSearchParams(window.location.search)
  )?.vacancy_id;

  const handleCansel = () => {
    if (currentUrl.includes('dashboard')) {
      to('/dashboard');
    } else {
      to('/vacancy', { vacancy_id });
      vacancyUpdate(false);
    }
  };

  return (
    <>
      <Pop scene={`delete-vacancy-${data._id}`} dark>
        <AreYouShure
          // handler={() => dispatch(removeVacancies([data._id]))}
          handler={() => {
            dispatch(
              changeVacancy({
                _id: data._id,
                status: 'deleted',
              })
            );
            to('/dashboard');
          }}
          header="Delete  vacancy"
          text={`Are you sure you what to delete the vacancy "${data.position_name}?"`}
          btn="Delete"
        />
      </Pop>

      <Pop scene={`active-vacancy-${data._id}`} dark>
        <AreYouShure
          handler={() => {
            dispatch(
              changeVacancy({
                _id: data._id,
                status: 'active',
              })
            );
            handleCansel();
          }}
          header="Active vacancy"
          text={`Are you sure you what to active the vacancy "${data.position_name}?"`}
          btn="Active"
        />
      </Pop>

      <Pop scene={`cancel-vacancy-${data._id}`} dark>
        <AreYouShure
          handler={() => {
            dispatch(
              changeVacancy({
                _id: data._id,
                status: 'canceled',
              })
            );
            handleCansel();
          }}
          header="Cancel  vacancy"
          text={`Are you sure you what to cancel the vacancy "${data.position_name}?"`}
          btn="Ok"
        />
      </Pop>

      <Pop scene={`complete-vacancy-${data._id}`} dark>
        <AreYouShure
          handler={() => {
            dispatch(
              changeVacancy({
                _id: data._id,
                status: 'finished',
              })
            );
            handleCansel();
          }}
          header="Complete  vacancy"
          text={`Are you sure you what to complete the vacancy "${data.position_name}?"`}
          btn="Complete"
        />
      </Pop>

      <EntityModal scene={`view-vacancy-${data._id}`} top="64px" right="0" dark>
        <ViewVacancy vacancy={data} />
      </EntityModal>

      <EntityModal
        scene={`update-vacancy-${data._id}`}
        top="64px"
        right="0"
        dark
      >
        <AddVacancy vacancy={data} />
      </EntityModal>
    </>
  );
};
