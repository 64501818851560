import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from '@mui/material';
import { useAppDispatch } from '../../../redux/store';
import {
  getVacancies,
  getWorkflows,
  query,
} from '../../../redux/slices/appSlice';
import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import useTo from '../../hooks/useTo';
import { UserData } from '../../../redux/slices/userSlice';

export const FiltersBlock: React.FC<{ user: UserData }> = ({ user }) => {
  const switcher = window.localStorage.getItem('switcher');
  const dispatch = useAppDispatch();
  const debouncedelay = 1000;
  const [searchVacancies, setSearchVacancies] = useState<any>();
  const [switched, setSwitched] = useState(switcher === 'true');
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);
  const to = useTo();


  const openPop = (scene, options = {}) => {
    to(null, { scene, ...options });
  };

  /** Switch */
  useEffect(() => {
    window.localStorage.setItem('switcher', switched ? 'true' : 'false');
    dispatch(getVacancies({ switch: switched ? 'true' : 'false' }));
  }, [switched]);

  /** Search */
  const handleChange = (params: query) => {
    dispatch(
      getVacancies(
        !!params.search
          ? { ...params, switch: window.localStorage.getItem('switcher') }
          : { switch: window.localStorage.getItem('switcher') }
      )
    );
  };

  const handleSearchChange = (e: BaseSyntheticEvent) => {
    handleChange({ search: e.target.value });
  };

  const handleChangeDebounce = useDebouncedCallback(
    handleSearchChange,
    debouncedelay
  );

  const handleTextFieldFocus = () => {
    setIsTextFieldFocused(true);
  };

  const handleTextFieldBlur = () => {
    setIsTextFieldFocused(false);
  };

  const handleEnterKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter' && isTextFieldFocused) {
      event.preventDefault();
      handleChange({ search: searchVacancies });
    }
  };

  /** menu */
  const [anchorElFilterBlock, setAnchorElFilterBlock] =
    useState<null | HTMLElement>(null);

  const handleOpenFilterBlockMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElFilterBlock(event.currentTarget);
  };

  useEffect(() => {
    dispatch(getWorkflows({}));
  }, []);

  return (
    <Grid container gap={2}>
      <Grid item xs>
        <TextField
          id="outlined-multiline-flexible"
          label={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '0.75rem',
              }}
            >
              <SearchIcon sx={{ mr: '8px' }} />
              <span>Search through vacancies</span>
            </div>
          }
          multiline
          size="small"
          sx={{ width: '100%', backgroundColor: 'white' }}
          defaultValue={searchVacancies}
          onChange={(e) => {
            setSearchVacancies(e.target.value);
            handleChangeDebounce(e);
          }}
          onFocus={handleTextFieldFocus}
          onBlur={handleTextFieldBlur}
          onKeyDown={handleEnterKeyPress}
        />
      </Grid>
      <Grid item xs="auto">
        <FormControlLabel
          control={
            <Switch
              checked={switched}
              onChange={(e) => setSwitched(e.target.checked)}
            />
          }
          label="Show Hidden"
        />
      </Grid>
      {/*<Grid item>
        <Button
          variant="contained"
          startIcon={<QuickreplyIcon />}
          className="btn_yellow"
        >
          AI
        </Button>
        </Grid>*/}
      <Grid item>
        <Button
          variant="contained"
          startIcon={<AddCircleIcon />}
          onClick={() => {
            user.free_limit_reached
              ? openPop('tariff-selection')
              : openPop('add-vacancy');
          }}
        >
          New Vacancy
        </Button>
      </Grid>
      {/*<Grid item sx={{ position: 'relative' }}>
        <Button
          variant="contained"
          color="inherit"
          sx={{ padding: '6px', minWidth: 'unset' }}
          onClick={handleOpenFilterBlockMenu}
        >
          <MoreHorizIcon />
        </Button>
        <Menu
          id="menuFilterBlock"
          anchorEl={anchorElFilterBlock}
          open={Boolean(anchorElFilterBlock)}
          onClose={() => setAnchorElFilterBlock(null)}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }}
        >
          <MenuItem onClick={() => {}}>
            <ListItemIcon>
              <AddCircleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Item-1</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {}}>
            <ListItemIcon>
              <AddCircleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Item-2</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {}}>
            <ListItemIcon>
              <AddCircleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Item-3</ListItemText>
          </MenuItem>
        </Menu>
      </Grid>*/}
    </Grid>
  );
};
