import React, { useState } from 'react';
import { StepType } from './NewStep';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Step } from './Step';
import { useAppDispatch } from '../../../../../redux/store';
import { Flow, updateStep } from '../../../../../redux/slices/appSlice';
import { defaultFlows } from '../../../../../assets/constants';

export const WorkflowSteps: React.FC<{
  data: Flow;
  id?: string;
}> = ({ data, id }) => {
  const dispatch = useAppDispatch();
  const [dragButtonActive, setDragButtonActive] = useState(false);
  const stepList = data?.flow || defaultFlows;

  const onDragEnd = (result) => {
    if (data?.flow) {
      setDragButtonActive(false);

      if (!result.destination) {
        return;
      }

      const updatedStepList = [...stepList];
      const [movedStep] = updatedStepList.splice(result.source.index, 1);
      updatedStepList.splice(result.destination.index, 0, movedStep);

      dispatch(
        updateStep({ _id: id, updatedFlow: { ...data, flow: updatedStepList } })
      );
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="workflow-steps">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {stepList?.map((step, index) => (
              <Draggable key={step.code} draggableId={step.code} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                      borderBottom: '1px solid #DFDFDF',
                      padding: '0.5rem 1rem',
                      display: 'flex',
                      flexFlow: 'row nowrap',
                      backgroundColor: snapshot.isDragging
                        ? '#d3ffd3'
                        : 'inherit',
                    }}
                  >
                    <Step
                      data={step}
                      setDragButtonActive={setDragButtonActive}
                      id={id}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
