import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import {
  changePassword,
  deleteAccount,
  downloadAccount,
} from '../../../../../redux/slices/userSlice';
import { Box, Button } from '@mui/material';
import TileInner from '../../../pices/TileInner';
import validator from 'validator';
import DeleteIcon from '@mui/icons-material/Delete';
import { PasswordField } from './PasswordField';
import styles from './Account.module.css';
import AreYouShure from '../../../pices/AreYouShure';
import Pop from '../../../Pop';
import { useLocation, useNavigate } from 'react-router-dom';

export const Security = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.data);
  const [form, setForm] = useState({
    current_password: '',
    new_password: '',
    repeat_password: '',
  });
  const [valid, setValid] = useState({
    new_password: true,
    repeat_password: true,
  });

  const saveHandler = () => {
    const [new_password, repeat_password] = [
      !!validator.isStrongPassword(form.new_password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 0,
      }),
      form.new_password === form.repeat_password,
    ];

    setValid({ new_password, repeat_password });

    if (!new_password || !repeat_password) return;

    dispatch(
      changePassword({
        _id: user._id,
        current_password: form.current_password,
        new_password: form.new_password,
      })
    );
  };

  const deleteHandler = () => {
    dispatch(deleteAccount({ _id: user._id }));
  };

  const uploadHandler = () => {
    dispatch(downloadAccount({ _id: user._id }));
  };

  const openPop = (scene: string) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('scene', scene);
    navigate(`${location.pathname}?${queryParams}`);
  };

  return (
    <>
      <div className={styles.details}>
        <div className={styles.content_right}>
          <TileInner text="Change Password" line>
            <Box
              component="form"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <PasswordField
                type={'current'}
                valid={true}
                form={form.current_password}
                setForm={setForm}
              />
              <div className={styles.passwords}>
                <PasswordField
                  type={'new'}
                  valid={valid.new_password}
                  form={form.new_password}
                  setForm={setForm}
                />
                <PasswordField
                  type={'repeat'}
                  valid={valid.repeat_password}
                  form={form.repeat_password}
                  setForm={setForm}
                />
              </div>
            </Box>

            <Button
              variant="contained"
              size="large"
              sx={{
                width: 'fit-content',
                fontSize: '0.9rem',
                fontWeight: 'bold',
                marginBlock: '1rem',
              }}
              onClick={saveHandler}
            >
              Save
            </Button>
          </TileInner>
        </div>
        <div className={styles.content_left}>
          <TileInner text="Delete Account" line>
            {!!user?.is_active ? (
              <p>
                To deactivate your account, first delete its resources and
                cancel your subscription. If you are the only owner of any
                teams, either assign another owner or deactivate the team.
              </p>
            ) : (
              <p>
                The account is either not located or has been deactivated. If
                you require assistance, please contact our support team at{' '}
                <a href="mailto:support@talentpopcorn.ai">
                  support@talentpopcorn.ai
                </a>
              </p>
            )}
            <Button
              variant="outlined"
              color="error"
              size="large"
              startIcon={<DeleteIcon />}
              sx={{
                width: 'fit-content',
                fontSize: '0.9rem',
                fontWeight: 'bold',
                marginBlock: '1rem',
              }}
              disabled={!!user?.is_subscription_active}
              onClick={() => openPop('delete-account')}
            >
              DELETE ACCOUNT
            </Button>
          </TileInner>
          <TileInner text="Download Data" line>
            <p>
              Press here if you want to get a copy of your data from your
              account.
            </p>
            <Button
              variant="outlined"
              color="info"
              size="large"
              sx={{
                width: 'fit-content',
                fontSize: '0.9rem',
                fontWeight: 'bold',
                marginBlock: '1rem',
              }}
              onClick={() => openPop('download-account')}
            >
              REQUEST DATA
            </Button>
          </TileInner>
        </div>
      </div>

      <Pop scene="delete-account" dark>
        <AreYouShure
          handler={deleteHandler}
          header="Delete account"
          text="Are you sure you what to delete account?"
          btn="Delete"
        />
      </Pop>

      <Pop scene="download-account" dark>
        <AreYouShure
          handler={uploadHandler}
          header="Download account"
          text="Submit a request to download your account data."
          btn="Send"
        />
      </Pop>

      <Pop scene="password-changed" dark>
        <AreYouShure
          header={<span className="ntf_header">TALENT POPCORN</span>}
          text={
            <div className="ntf_text">
              <header>Password successfully reset!</header>
              <p>
                You have successfully changed your password. You will be
                redirected to the Login Page shortly. Thank you!
              </p>
            </div>
          }
          btn={null}
        />
      </Pop>
    </>
  );
};
