import moment from 'moment';

export const calculateTimeAgo = (date: Date) => {
  const updatedMoment = moment(date);
  const duration = moment.duration(moment().diff(updatedMoment));

  const days = duration.days();
  const hours = duration.hours();

  let timeAgoString = '';
  if (days > 0) {
    timeAgoString += `${days} ${days === 1 ? 'day' : 'days'} `;
  }
  if (hours > 0) {
    timeAgoString += `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
  }

  if (timeAgoString === '') {
    timeAgoString = 'Just now';
  } else {
    timeAgoString += ' ago';
  }

  return `Updated ${timeAgoString}`;
};
