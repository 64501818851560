import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { Box, Button, TextField } from '@mui/material';
import TileInner from '../../../pices/TileInner';
import styles from './Account.module.css';
import {
  generateApiKey,
  removeApiKey,
} from '../../../../../redux/slices/userSlice';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';

const Integrations = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.data);

  const userData = useMemo(() => user, [user, dispatch]);

  return (
    <div className={styles.details}>
      <div className={styles.content_right}>
        <TileInner text="API" line>
          <Box>
            <TextField
              fullWidth
              variant="standard"
              type="text"
              placeholder="API key"
              value={userData?.api_key}
              helperText="This is your API key"
              disabled
            />
            <div style={{ marginTop: '2rem' }}>
              <Button
                color="error"
                onClick={() => dispatch(generateApiKey())}
                startIcon={<RefreshIcon />}
              >
                Refresh
              </Button>
              <Button
                color="error"
                onClick={() => dispatch(removeApiKey())}
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            </div>
          </Box>
        </TileInner>
      </div>
    </div>
  );
};

export default Integrations;
