import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { urls, herald } from '../urls';

type langs = 'en' | 'ru' | 'il' | 'kz' | 'tk' | null;
export const languages: Array<langs> = ['en', 'ru', 'il', 'kz', 'tk'];
// --- ACTIONS ---

export const setLocale = createAsyncThunk(
  'locale/set',
  async (lang: langs, { dispatch, getState }) => {
    try {
      // @ts-ignore
      if (!lang) lang = getState().locale.lang;
      if (!lang) lang = 'en';
      if (lang === 'en') return { lang, keys: null };
      const response = await herald(urls.local());
      if (!response.ok) throw Error('can not get translate languege');
      return { lang, keys: await response.json() };
    } catch (e) {
      // @ts-ignore
      dispatch(addHint(e?.message || 'locale/set ERROR'));
    }
  }
);

// --- SLICE ---

export interface hints {
  lang: langs;
  keys: Object | null;
}
const initialState: hints = {
  lang: null,
  keys: null,
};

export const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setLocale.fulfilled, (state, action) => {
      if (action.payload) {
        state = action.payload;
      }
    });
  },
});

// export const {} = localeSlice.actions
export default localeSlice;
