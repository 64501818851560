import './App.css';
import Index from './lib/app/Index';

function App() {
  return (
    <div
      className="text"
      style={{
        display: 'relative',
        minHeight: '100vh',
        width: '100vw',
        background: '#F6F6F6',
      }}
    >
      <Index />
    </div>
  );
}

export default App;
