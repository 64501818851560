import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import type { TypedUseSelectorHook } from 'react-redux'
import userSlice from './slices/userSlice';
import hintsSlice from './slices/hintsSlice';
import { localeSlice } from './slices/localeSlice';
import appSlice from './slices/appSlice';
import processSlice from './slices/praocessSlice';
import feedbackSlice from './slices/feedbackSlice';

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    hints: hintsSlice.reducer,
    locale: localeSlice.reducer,
    app: appSlice.reducer,
    process: processSlice.reducer,
    feedback: feedbackSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>; // Infer the `RootState` and `AppDispatch` types from the store itself
export type AppDispatch = typeof store.dispatch; // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;