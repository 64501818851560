import {FormControl, FormHelperText, IconButton, Input, InputAdornment, InputLabel,} from '@mui/material';
import {useEffect, useState} from 'react';
import {Visibility, VisibilityOff} from '@mui/icons-material';

type DataType = {
  label?: string;
  helperText?: string;
  helperTextError?: string;
};

export const PasswordField: React.FC<{
  type: string;
  valid: boolean;
  form: string;
  setForm: (value: any) => void;
}> = ({ type, valid, form, setForm }) => {
  const [data, setData] = useState<DataType>();
  const [showPassword, setShowPassword] = useState(false);

  const dataType = () => {
    switch (type) {
      case 'current':
        return {
          label: 'Current Password',
          helperText: 'Your current password here',
          helperTextError:
            'Must be longer than 8 chars and contains lowercase and uppercase letters and numbers',
        };

      case 'new':
        return {
          label: 'New Password',
          helperText: 'Your new password here',
          helperTextError:
            'Must be longer than 8 chars and contains lowercase and uppercase letters and numbers',
        };

      case 'repeat':
        return {
          label: 'Repeat Password',
          helperText: 'Repeat the password',
          helperTextError: 'Must be exactly the same as the password',
        };

      default:
        return {};
    }
  };

  useEffect(() => {
    const values = dataType();
    setData(values);
  }, []);

  return (
    <FormControl fullWidth variant="standard" error={!valid}>
      <InputLabel htmlFor="standard-adornment-password">
        {data?.label}
      </InputLabel>
      <Input
        type={showPassword ? 'text' : 'password'}
        value={form}
        error={!valid}
        autoComplete="off"
        onChange={(e) =>
          setForm((prev: any) => ({
            ...prev,
            [`${type}_password`]: e.target.value,
          }))
        }
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword((prev: any) => !prev)}
              onMouseLeave={() => setShowPassword(false)}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText error={!valid}>
        {valid ? data?.helperText : data?.helperTextError}
      </FormHelperText>
    </FormControl>
  );
};
