import { Button, MenuItem } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import CreditCardIcon from '@mui/icons-material/CreditCard';

export const TariffSelection: React.FC<{
  typeBtn?: string;
}> = ({ typeBtn }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const openPop = (scene: string) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('scene', scene);
    navigate(`${location.pathname}?${queryParams}`);
  };

  return (
    <>
      {typeBtn === 'menuItem' ? (
        <MenuItem onClick={() => openPop('tariff-selection')}>
          <CreditCardIcon fontSize="medium" color="action" />
          <span className="text-grey">Subscription</span>
        </MenuItem>
      ) : (
        <Button variant="contained" onClick={() => openPop('tariff-selection')}>
          Tariff selection
        </Button>
      )}
    </>
  );
};
