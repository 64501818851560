import {Divider, Paper} from '@mui/material';
import Box from '@mui/material/Box';

export default function TileInner({
  text,
  subtext,
  line,
  sx,
  children,
}: {
  text?: string;
  subtext?: string;
  line?: boolean;
  sx?: Object;
  children?: React.ReactNode;
}) {
  return (
    <Paper elevation={0} sx={{ borderRadius: '8px' }}>
      <Box className="text" style={{ ...(sx ? sx : {}), margin: '0 auto' }}>
        <Box style={{ padding: '1rem 2rem', background: 'inherit' }}>
          {text && <h2>{text}</h2>}
          {subtext && (
            <div className="text-light">
              {' '}
              {subtext || 'Your vacancies listed below.'}{' '}
            </div>
          )}
        </Box>
        {line && <Divider />}
        <Box style={{ padding: '1rem 2rem', background: 'inherit' }}>
          {children}
        </Box>
      </Box>
    </Paper>
  );
}
