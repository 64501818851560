import React, {useEffect, useState} from 'react';
import {CSSObject, styled, Theme, useTheme} from '@mui/material/styles';
import {
  Avatar,
  Box,
  CssBaseline,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AutoAwesomeMotionOutlinedIcon from '@mui/icons-material/AutoAwesomeMotionOutlined';
import ListItemIcon from '@mui/material/ListItemIcon';
import WorkIcon from '@mui/icons-material/Work';
import SettingsIcon from '@mui/icons-material/Settings';
import {Link} from 'react-router-dom';
import {ReactComponent as LogoSvg} from '../../../static/Logo.svg';
import Router from '../../app/Router';
import Welcome from '../pages/Welcome';
import Login from '../pages/Login';
import Registration from '../pages/Registration';
import PasswordRecoveryRequest from '../pages/PasswordRecoveryRequest';
import PasswordRecovery from '../pages/PasswordRecovery';
import Dashboard from '../pages/Dashboard';
import Vacancy from '../pages/Vacancy';
import ScoringDemo from '../pages/ScoringDemo';
import Candidate from '../pages/Candidate';
import Hints from '../../app/Hints';
import {useAppDispatch, useAppSelector} from '../../../redux/store';
import {logout} from '../../../redux/slices/userSlice';
import Footer from './Footer';
import usePrivate from '../../hooks/usePrivate';
import useTo from '../../hooks/useTo';
import AccountSettings from '../pages/AccountSettings';
import ContactUs from '../pages/ContactUs';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import {TariffSelection} from './tariff-selection/TariffSelection';
import io from 'socket.io-client';
import {WorkFlows} from '../pages/work-flows/WorkFlows';
import {WorkFlowAction} from '../pages/work-flows/WorkFlowAction';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import FAQ from "./faq/FAQ";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function HeaderWithDrawer() {
  const to = useTo();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [open, setOpen] = useState(() => window.innerWidth > 1024);
  const [maxWidth, setMaxWidth] = useState('100vw');
  const [PrivateArea] = usePrivate('/login');
  const token = !!sessionStorage.getItem('token');

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const user = useAppSelector((state) => state.user.data);

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (key) => {
    switch (key) {
      case 'Logout':
        dispatch(logout());
        break;
      case 'Profile':
        to('settings');
        break;
      default:
        break;
    }
    setAnchorElUser(null);
  };

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      setMaxWidth(open ? `${screenWidth - 340}px` : `${screenWidth - 120}px`);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [open]);

  useEffect(() => {
    const handleResize = () => {
      setOpen(window.innerWidth > 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let socket;

    const connectSocket = async () => {
      socket = io(process.env.REACT_APP_DEV_ORIGIN);

      await new Promise<void>((resolve) => {
        socket.on('connect', () => {
          resolve();
        });
      });

      // Сообщение серверу
      socket.emit('clientMessage', {
        socket_id: socket.id,
        message: 'Hello from the client!',
        _id: user._id,
      });

      // Сообщение от сервера
      socket.on('serverMessage', (data) => {
        console.log('Received message from server:', data.message);
      });

      socket.on('webHook', (data) => {
        console.log('Received message from server:', data.message);
        dispatch(logout());
      });

      socket.on('connect_error', (error) => {
        console.log('Connection error:', error);
      });

      socket.on('disconnect', () => {
        console.log('Disconnected from server');
      });
    };

    const cleanupSocket = () => {
      if (socket) {
        socket.disconnect();
      }
    };

    if (token && user) {
      connectSocket();
    }

    return () => {
      cleanupSocket();
    };
  }, [token]);

  return (
    <Box sx={{ display: 'flex' }}>
      <PrivateArea />
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ background: '#F7D446' }}>
        <Toolbar style={{ paddingLeft: 16 }}>
          <Link to="/en/dashboard">
            <Box
              justifyContent="flex-start"
              alignItems="center"
              display={'inline-flex'}
            >
              <LogoSvg />
              <span
                className="text"
                style={{
                  paddingLeft: '10px',
                  fontSize: '20px',
                  lineHeight: '24px',
                  letterSpacing: '0.15px',
                  fontWeight: 'bold',
                  color: '#333333',
                }}
              >
                Talent Popcorn
              </span>
            </Box>
          </Link>

          <IconButton
            color="default"
            aria-label="open drawer"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              marginLeft: 1,
            }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            sx={{
              flexGrow: 0,
              display: { xs: 'flex' },
              marginLeft: 'auto',
            }}
          >

            {/*<div style={{margin: '0 14px', display: 'flex'}}>
              <HelpOutlinedIcon style={{color: '#333333', cursor: 'pointer',fontSize: '26px', margin: '8px'}}
                            onClick={()=>alert('SettingsIcon')}
              />
            </div>*/}

            <Tooltip
              title={
                user?.email ? `${user?.name} ${user?.surname}` : 'open settings'
              }
            >
              <IconButton
                className="header_avatar"
                onClick={handleOpenUserMenu}
                sx={{
                  borderRadius: '24px',
                  background: '#FFF',
                  width: '78px',
                  height: '44px',
                  padding: '6px',
                }}
              >
                <Avatar
                  alt={
                    user?.email ? user?.name?.[0] + user?.surname?.[0] : 'User'
                  }
                  src={user?.userpic}
                  sx={{ maxWidth: '32px', maxHeight: '32px' }}
                />
                <ChevronRightIcon className="chevron" />
              </IconButton>
            </Tooltip>
            <Menu
              id="menuAppBar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <div>
                <span
                  style={{ color: '#000' }}
                >{`${user?.name} ${user?.surname}`}</span>
                <span className="text-light">{user?.email}</span>
              </div>

              <Divider />

              <MenuItem
                onClick={() => handleCloseUserMenu('Profile')}
                sx={{ mt: '8px' }}
              >
                <PermIdentityIcon fontSize="medium" color="action" />
                <span className="text-grey">Profile</span>
              </MenuItem>

              <TariffSelection typeBtn="menuItem" />

              <Divider />

              <MenuItem onClick={() => handleCloseUserMenu('Logout')}>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{ background: '#F7D446' }}></DrawerHeader>
        <Divider />
        <List
          subheader={
            open && (
              <ListSubheader component="div" id="nested-list-subheader">
                Dashboard
              </ListSubheader>
            )
          }
        >
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/en/dashboard">
              <ListItemIcon>
                <WorkIcon />
              </ListItemIcon>
              <ListItemText primary="Vacancies" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/en/workflows">
              <ListItemIcon>
                <AutoAwesomeMotionOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Workflows" />
            </ListItemButton>
          </ListItem>
          {/*<ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary="Candidates" />
            </ListItemButton>
          </ListItem>*/}
        </List>
        <Divider />
        <List
          subheader={
            open && (
              <ListSubheader component="div" id="nested-list-subheader">
                Account
              </ListSubheader>
            )
          }
        >
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/en/settings">
              <ListItemIcon>{<SettingsIcon />}</ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/en/faq">
              <ListItemIcon>{<HelpOutlinedIcon />}</ListItemIcon>
              <ListItemText primary="FAQ" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <div id="content-container" style={{ maxWidth }}>
          <Router
            roots={{
              welcome: <Welcome />,
              login: <Login />,
              registration: <Registration />,
              recoveryrequest: <PasswordRecoveryRequest />,
              recovery: <PasswordRecovery />,
              dashboard: <Dashboard />,
              vacancy: <Vacancy />,
              interviews: <>{}</>,
              candidates: <ScoringDemo />,
              candidate: <Candidate />,
              settings: <AccountSettings />,
              workflows: <WorkFlows />,
              'workflows/:id': <WorkFlowAction />,
              'workflows/new-workflow': <WorkFlowAction />,
              show: <></>,
              'contact-us': <ContactUs />,
               faq: <FAQ />,
            }}
            default="dashboard"
          />
          <Footer />
        </div>

        <Hints right="0" top="64px" />
      </Box>
    </Box>
  );
}
