import {
  Avatar,
  AvatarGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { ReactComponent as StarSvg } from '../../../static/Star.svg';
import { ReactComponent as YellowStarSvg } from '../../../static/YellowStar.svg';
import { calculateTimeAgo } from '../../../assets/dateFormater';
import useTo from '../../hooks/useTo';
import { useState } from 'react';
import Pop from '../Pop';
import AreYouShure from './AreYouShure';
import { useAppDispatch } from '../../../redux/store';
import {
  changeVacancy,
  favoriteVacancy,
  removeVacancies,
} from '../../../redux/slices/appSlice';
import EntityModal from '../EntityModal';
import ViewVacancy from './ViewVacancy';
import GroupIcon from '@mui/icons-material/Group';
import FlagIcon from '@mui/icons-material/Flag';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import HdrAutoIcon from '@mui/icons-material/HdrAuto';
import AddVacancy from './AddVacancy';
import { UserData } from '../../../redux/slices/userSlice';
import { VacancyPopups } from './VacancyPopups';

export interface DataType {
  _id: string;
  createdAt: Date;
  updatedAt: Date;
  position_description: string;
  company_name: string;
  position_name: string;
  stats: any[];
  status: string;
  flow_id: string;
  flow: any[];
  is_favorite: boolean;
}

export const VacancyCard: React.FC<{ data: DataType; user: UserData }> = ({
  data,
  user,
}) => {
  const dispatch = useAppDispatch();
  const to = useTo();
  const [favorite, setFavorite] = useState<boolean>(data?.is_favorite);

  const handlerStar = (value: boolean) => {
    dispatch(favoriteVacancy({ _id: data._id, is_favorite: value }));
    setFavorite(value);
  };

  const openPop = (scene, options = {}) => {
    to(null, { scene, ...options });
  };

  /** menu */
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = (key) => {
    switch (key) {
      case 'Active':
        openPop(`active-vacancy-${data._id}`);
        break;
      case 'Delete':
        openPop(`delete-vacancy-${data._id}`);
        break;
      case 'Edit':
        openPop(`update-vacancy-${data._id}`);
        break;
      case 'Settings':
        to(`/workflows/:${data.flow_id}`);
        break;
      case 'Cancel':
        openPop(`cancel-vacancy-${data._id}`);
        break;
      case 'Complete':
        openPop(`complete-vacancy-${data._id}`);
        break;
      default:
        break;
    }
    setAnchorEl(null);
  };

  const status = (key: string) => {
    switch (key) {
      case 'canceled':
        return 'Cancel';
      case 'finished':
        return 'Complete';
      default:
        return;
    }
  };

  return (
    <>
      <Card sx={{ width: 305 }}>
        <CardHeader
          action={
            <>
              <IconButton
                aria-label="settings"
                sx={{ zIndex: 1 }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenMenu(e);
                }}
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="menuCard"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseUserMenu}
                sx={{ zIndex: 11 }}
                MenuListProps={{
                  'aria-labelledby': 'lock-button',
                  role: 'listbox',
                }}
              >
                <MenuItem onClick={() => handleCloseUserMenu('Edit')}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Edit</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleCloseUserMenu('Settings')}>
                  <ListItemIcon>
                    <SettingsIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Settings</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => handleCloseUserMenu('Active')}>
                  <ListItemIcon>
                    <HdrAutoIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Active</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleCloseUserMenu('Cancel')}>
                  <ListItemIcon>
                    <CancelIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Cancel</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleCloseUserMenu('Complete')}>
                  <ListItemIcon>
                    <CheckCircleIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Complete</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => handleCloseUserMenu('Delete')}>
                  <ListItemIcon>
                    <DeleteForeverIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Delete</ListItemText>
                </MenuItem>
              </Menu>
            </>
          }
          subheader={calculateTimeAgo(data.updatedAt || data.createdAt)}
        />
        <CardContent
          sx={{ pt: 0, zIndex: 0, cursor: 'pointer' }}
          onClick={() => openPop(`view-vacancy-${data._id}`)}
        >
          <Typography gutterBottom component="div" sx={{ mb: '1rem' }}>
            <Link
              href={`/en/vacancy?vacancy_id=${data._id}`}
              underline="hover"
              color="inherit"
              sx={{ zIndex: 1 }}
              onClick={(e) => e.stopPropagation()}
            >
              {data.status === 'active'
                ? data.position_name
                : `(${status(data.status)}) ${data.position_name}`}
            </Link>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                fontSize: '0.85rem',
                width: '100%',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {data.company_name}
            </Typography>
          </Typography>
          <div className="icons_group">
            <span>
              <GroupIcon /> {data?.stats?.length}
            </span>
            <span>
              <FlagIcon />
              {
                data.stats?.filter(
                  (item) => item === 'offer_sent' || item === 'offer_accepted'
                ).length
              }
            </span>
          </div>
        </CardContent>
        <Divider variant="middle" />
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            zIndex: 0,
            cursor: 'pointer',
          }}
          onClick={() => openPop(`view-vacancy-${data._id}`)}
        >
          <AvatarGroup max={4}>
            <Avatar alt={`Avatar${user?.name}`} src={user?.userpic} />
            {/* {data.stats?.map((item, index) => (
              <Avatar key={index} alt={`Avatar${index}`} src={item?.userpic} />
            ))} */}
          </AvatarGroup>
          <IconButton
            aria-label="share"
            sx={{ zIndex: 1 }}
            onClick={(e) => {
              e.stopPropagation();
              handlerStar(!favorite);
            }}
          >
            {favorite === true ? <YellowStarSvg /> : <StarSvg />}
          </IconButton>
        </CardActions>
      </Card>

      <VacancyPopups data={data} />
    </>
  );
};
