import {useEffect, useMemo, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../redux/store';
import useTo from '../../hooks/useTo';
import {getApplicants, getVacancies} from '../../../redux/slices/appSlice';
import {Breadcrumbs, Divider, Link, Typography} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MyFlowTable from '../pices/MyFlowTable';
import EntityModal from '../EntityModal';
import ViewApplication from '../pices/ViewApplication';
import MainTitle from '../pices/MainTitle';
import {FiltersBlockVacancy} from '../pices/FiltersBlockVacancy';
import {useLocation} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';

const Vacancy = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const to = useTo();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const vacancy_id = useMemo(
    () => Object.fromEntries(queryParams)?.vacancy_id || '',
    [queryParams]
  );
  const applicant_id = useMemo(
    () => Object.fromEntries(queryParams)?.applicant_id || '',
    [queryParams]
  );

  const vacancies = useAppSelector((s) => s.app.vacancies);
  const vacancy = useMemo(
    () => vacancies?.find((v) => v._id === vacancy_id),
    [vacancies, vacancy_id]
  );
  const [chekA, setChekA] = useState(false);
  const [applicantsKey, setApplicantsKey] = useState(uuidv4().slice(0, 4));

  useEffect(() => {
    if (vacancy_id && !Object.fromEntries(queryParams)?.vacancy_id) {
      setChekA(false);
    }

    if (vacancy && !vacancy.applicants?.length && !chekA) {
      dispatch(getApplicants({ vacancy_id }));
      setChekA(true);
    }
  }, [vacancy_id, queryParams, vacancies, vacancy, dispatch, chekA]);

  useEffect(() => {
    if (!vacancy) dispatch(getVacancies(null));
  }, [dispatch, vacancy]);

  const flowCodes = useMemo(
    () => ({
      all: (vacancy?.flow || []).map((f) => f.code),
      hidde: [],
    }),
    [vacancy]
  );

  const [flows, setFlows] = useState(flowCodes.all);

  useEffect(() => {
    if (!flows.length)
      setFlows(flowCodes.all.filter((s) => !flowCodes.hidde.includes(s)));
  }, [flowCodes, flows]);

  const [tableTag, setTableTag] = useState({});

  const un = 'undefined';
  const getLocation = (a) =>
    `${a.resume.location.country || un}, ${a.resume.location.city || un}${
      a.resume.location.state ? `, ${a.resume.location.state}` : ''
    }`;
  const getPositions = (a) =>
    a.resume.experience?.map((ex) => ex.position || un);
  const getCompanies = (a) =>
    a.resume.experience?.map((ex) => ex.company || un);
  const matchArrs = (arr1, arr2) =>
    arr2.reduce((acc, el) => acc || arr1.includes(el), false);

  const filteredApplicants = useMemo(() => {
    const keys = Object.keys(tableTag).filter((k) => !!tableTag[k].length);
    if (keys.length)
      return keys.reduce(
        (acc, k) => [
          ...acc.filter((a) =>
            k === 'search'
              ? tableTag[k].length ===
                tableTag[k].filter((ttk) =>
                  JSON.stringify(a.resume)
                    .toLowerCase()
                    .includes(ttk.toLowerCase())
                ).length
              : k === 'location'
                ? matchArrs(tableTag[k], [getLocation(a) || un])
                : k === 'Recent Position'
                  ? matchArrs(tableTag[k], [...(getPositions(a) || un)])
                  : k === 'Recent Company'
                    ? matchArrs(tableTag[k], [...(getCompanies(a) || un)])
                    : matchArrs(tableTag[k], [a[k]])
          ),
        ],
        vacancy?.applicants || []
      );
    return vacancy?.applicants || [];
  }, [vacancy, tableTag, getLocation, getPositions, getCompanies]);

  const [flowTableProps, setFlowTableProps] = useState({
    applicants: filteredApplicants.filter((a) => flows.includes(a.flow_code)),
    vacancy: vacancy,
  });

  const prevApplicantsRef = useRef([]);

  useEffect(() => {
    const prevApplicants = prevApplicantsRef.current;
    if (!arraysAreEqual(prevApplicants, filteredApplicants)) {
      setFlowTableProps({
        applicants: filteredApplicants.filter((a) =>
          flows.includes(a.flow_code)
        ),
        vacancy: vacancy,
      });

      setApplicantsKey(uuidv4().slice(0, 4));

      prevApplicantsRef.current = filteredApplicants;
    }
  }, [filteredApplicants, flows, vacancy]);

  const switcherHandler = (showDeclined) => {
    if (showDeclined) {
      setFlows(flowCodes.all.filter((s) => s !== 'declined'));
    } else {
      setFlows(flowCodes.all);
    }
  };

  const status = (key: string) => {
    switch (key) {
      case 'canceled':
        return 'Cancel';
      case 'finished':
        return 'Complete';
      default:
        return;
    }
  };

  return !vacancy ? (
    <>Loading...</>
  ) : (
    <>
      <Breadcrumbs separator={<NavigateNextIcon />} sx={{ mb: '24px' }}>
        <Link
          underline="hover"
          key="2"
          color="inherit"
          href=""
          onClick={(e) => {
            e.preventDefault();
            to('/dashboard');
          }}
        >
          Home
        </Link>
        <Typography key="3" color="text.primary">
          {vacancy.position_name}
        </Typography>
      </Breadcrumbs>

      <MainTitle
        header={
          vacancy.status === 'active'
            ? vacancy.position_name
            : `(${status(vacancy.status)}) ${vacancy.position_name}`
        }
        subtext={vacancy.company_name}
        mb={'1rem'}
      />

      <Divider sx={{ marginBottom: '1rem' }} />

      <FiltersBlockVacancy
        data={vacancy}
        onSearch={setTableTag}
        onSwitch={switcherHandler}
        flowTableProps={flowTableProps}
        setFlowTableProps={setFlowTableProps}
        vacancyUpdate={setChekA}
      />

      <Divider sx={{ marginBlock: '1rem' }} />

      <MyFlowTable
        applicants={flowTableProps.applicants}
        vacancy={flowTableProps.vacancy || vacancy}
        key={applicantsKey}
        style={{
          overflowX: 'auto',
          overflowY: 'hidden',
          marginBottom: '2rem',
        }}
      />

      <EntityModal
        scene="view-applicant"
        top="64px"
        right="0"
        dark
        key={applicant_id}
      >
        <ViewApplication />
      </EntityModal>
    </>
  );
};

export default Vacancy;

function arraysAreEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
}
