import { useEffect, useState } from 'react';
import Hints from './Hints';
import Router from './Router';
import Login from '../components/pages/Login';
import Welcome from '../components/pages/Welcome';
import PasswordRecovery from '../components/pages/PasswordRecovery';
import Registration from '../components/pages/Registration';
import PasswordRecoveryRequest from '../components/pages/PasswordRecoveryRequest';
import HeaderWithDrawer from '../components/pices/HeaderWithDrawer';
import Footer from '../components/pices/Footer';
import { useLocation } from 'react-router-dom';
import ContactUs from '../components/pages/ContactUs';
import { Popups } from './Popups';
import { useAppSelector } from '../../redux/store';

export default function Index() {
  const location = useLocation();
  const [kentInSessionStorage, setKentInSessionStorage] = useState(() => {
    return !!sessionStorage.getItem('token');
  });
  const user = useAppSelector((state) => state.user.data);

  useEffect(() => {
    setKentInSessionStorage(!!sessionStorage.getItem('token'));
  }, [location.pathname, user]);

  return (
    <>
      {kentInSessionStorage ? (
        <HeaderWithDrawer />
      ) : (
        <div
          id="app-controller"
          style={{
            maxHeight: '100vh',
            overflow: 'auto',
          }}
        >
          <div
            id="content-container"
            style={{
              margin: '0 auto',
              padding: '32px',
            }}
          >
            <Router
              roots={{
                welcome: <Welcome />,
                login: <Login />,
                registration: <Registration />,
                recoveryrequest: <PasswordRecoveryRequest />,
                recovery: <PasswordRecovery />,
                // dashboard: <Dashboard />,
                // vacancy: <Vacancy />,
                // interviews: <>{}</>,
                // candidates: <ScoringDemo />,
                // candidate: <Candidate />,
                // show: <></>,
                'contact-us': <ContactUs />,
              }}
              default="login"
            />
            <Footer />
          </div>
        </div>
      )}

      <Popups />

      <Hints right="0" top="64px" />
    </>
  );
}
