import AddCircleIcon from '@mui/icons-material/AddCircle';
import {Badge, Button, Grid,} from '@mui/material';
import {useAppDispatch} from '../../../../redux/store';
import {getVacancies, getWorkflows, query,} from '../../../../redux/slices/appSlice';
import {BaseSyntheticEvent, useEffect, useState} from 'react';
import {useDebouncedCallback} from 'use-debounce';
import useTo from '../../../hooks/useTo';
import {UserData} from '../../../../redux/slices/userSlice';

export const FaqButtonsBlocks: React.FC<{ user: UserData }> = ({ user }) => {
    const switcher = window.localStorage.getItem('switcher');
    const dispatch = useAppDispatch();
    const debouncedelay = 1000;
    const [searchVacancies] = useState<any>();
    const [switched] = useState(switcher === 'true');
    const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);
    const to = useTo();


    const openPop = (scene, options = {}) => {
        to(null, { scene, ...options });
    };

    /** Switch */
    useEffect(() => {
        window.localStorage.setItem('switcher', switched ? 'true' : 'false');
        dispatch(getVacancies({ switch: switched ? 'true' : 'false' }));
    }, [switched]);

    /** Search */
    const handleChange = (params: query) => {
        dispatch(
            getVacancies(
                !!params.search
                    ? { ...params, switch: window.localStorage.getItem('switcher') }
                    : { switch: window.localStorage.getItem('switcher') }
            )
        );
    };

    const handleSearchChange = (e: BaseSyntheticEvent) => {
        handleChange({ search: e.target.value });
    };

    const handleChangeDebounce = useDebouncedCallback(
        handleSearchChange,
        debouncedelay
    );

    const handleTextFieldFocus = () => {
        setIsTextFieldFocused(true);
    };

    const handleTextFieldBlur = () => {
        setIsTextFieldFocused(false);
    };

    const handleEnterKeyPress = (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (event.key === 'Enter' && isTextFieldFocused) {
            event.preventDefault();
            handleChange({ search: searchVacancies });
        }
    };

    /** menu */
    const [anchorElFilterBlock, setAnchorElFilterBlock] =
        useState<null | HTMLElement>(null);

    const handleOpenFilterBlockMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElFilterBlock(event.currentTarget);
    };

    useEffect(() => {
        dispatch(getWorkflows({}));
    }, []);


    return (
    <Grid container gap={2}>
      <Grid item>
        <Button
          variant="contained"
          startIcon={<AddCircleIcon />}
          onClick={() => {
            user.free_limit_reached
              ? openPop('tariff-selection')
              : openPop('add-vacancy');
          }}
        >
          Start New Vacancy
        </Button>
      </Grid>
        <Grid item>
            <Badge badgeContent={'soon'} color="info">
                <Button
                    variant="contained"
                    className="btn_yellow"
                    onClick={() => openPop('tariff-selection')}
                    disabled
                >
                    Create Demo Data
                </Button>
            </Badge>
        </Grid>
    </Grid>
  );
};
