import React, { useEffect, useState } from 'react';
import { Box, Breadcrumbs, Link, Tab, Tabs, Typography } from '@mui/material';
import MainTitle from '../../pices/MainTitle';
import Details from './components/Details';
import { Notifications } from './components/Notifications';
import { Security } from './components/Security';
import { useLocation, useNavigate } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import useTo from '../../../hooks/useTo';
import Integrations from './components/Integrations';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default function AccountSettings() {
  const to = useTo();
  const location = useLocation();
  const navigate = useNavigate();
  const tabsMenu = [
    'Details',
    // 'Billing',
    'Notifications',
    'Security',
    'Integrations',
  ];
  const initialTab =
    new URLSearchParams(location.search).get('tab') || 'Details';
  const initialTabIndex = tabsMenu.indexOf(initialTab);
  const [view, setView] = useState(
    initialTabIndex !== -1 ? initialTabIndex : 0
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setView(newValue);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('tab', tabsMenu[view]);
    navigate(`?${queryParams.toString()}`);
  }, [view, navigate]);

  useEffect(() => {
    const newTab = new URLSearchParams(location.search).get('tab') || 'Details';
    const newTabIndex = tabsMenu.indexOf(newTab);

    setView(newTabIndex !== -1 ? newTabIndex : 0);
  }, [location.search]);

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon />} sx={{ mb: '32px' }}>
        <Link
          underline="hover"
          key="2"
          color="inherit"
          href=""
          onClick={(e) => {
            e.preventDefault();
            to('/dashboard');
          }}
        >
          Home
        </Link>
        <Typography key="3" color="text.primary">
          Account settings
        </Typography>
      </Breadcrumbs>

      <MainTitle header="Account settings" />

      {/* tab nav */}

      <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 4 }}>
        <Tabs
          value={view}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabsMenu?.map((tab_name, index) => (
            <Tab
              key={`${tab_name}-${index}`}
              style={{ textTransform: 'capitalize' }}
              label={tab_name}
              id={`tab-${index}`}
              aria-controls={`tab-${index}`}
            />
          ))}
        </Tabs>
      </Box>

      {/*  tabs */}

      <Box sx={{ py: '1.5rem' }}>
        {/* tab 1 Details */}

        <CustomTabPanel value={view} index={0}>
          <Details />
        </CustomTabPanel>

        {/* tab 2 Billing */}

        {/*<CustomTabPanel value={view} index={1}>
          Billing
        </CustomTabPanel>*/}

        {/* tab 3 Notifications */}

        <CustomTabPanel value={view} index={1}>
          <Notifications />
        </CustomTabPanel>

        {/* tab 4 Security */}

        <CustomTabPanel value={view} index={2}>
          <Security />
        </CustomTabPanel>

        {/* tab 5 Integrations */}

        <CustomTabPanel value={view} index={3}>
          <Integrations />
        </CustomTabPanel>
      </Box>
    </>
  );
}
