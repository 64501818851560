import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import {
  FormGroup,
  FormControlLabel,
  Switch,
  FormControl,
} from '@mui/material';
import TileInner from '../../../pices/TileInner';
import { changeNotifications } from '../../../../../redux/slices/userSlice';
import styles from './Account.module.css';

export const Notifications = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.data);
  const [state, setState] = useState({
    product: user.marketing_consents.product,
    events: user.marketing_consents.events,
    surveys: user.marketing_consents.surveys,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });

    dispatch(
      changeNotifications({
        _id: user._id,
        marketing_consents: {
          ...state,
          [event.target.name]: event.target.checked,
        },
      })
    );
  };

  return (
    <div className={styles.details}>
      <div className={styles.content_right}>
        {/* Notifications */}
        <TileInner text="Subscription Preference Center" line>
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.product}
                    onChange={handleChange}
                    name="product"
                  />
                }
                label="Product Announcements and Updates"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={state.events}
                    onChange={handleChange}
                    name="events"
                  />
                }
                label="Events and Meetups"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={state.surveys}
                    onChange={handleChange}
                    name="surveys"
                  />
                }
                label="Customer Research Surveys"
              />
            </FormGroup>
          </FormControl>
        </TileInner>
      </div>
    </div>
  );
};
