import {
  Autocomplete,
  Button,
  Divider,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
  createVacancies,
  getApplicants,
  getVacancies,
  getWorkflows,
  updateVacancies,
} from '../../../redux/slices/appSlice';
import useWidth from '../../hooks/useWidth';
import useTo from '../../hooks/useTo';
import { v4 as uuidv4 } from 'uuid';

export default function AddVacancy({
  vacancy,
  close = () => {},
}: {
  vacancy?: any;
  close?: Function;
}) {
  const dispatch = useAppDispatch();
  const [_, vh] = useWidth();
  const flows = useAppSelector((state) => state.app).workflows || [];
  const selectList = useMemo(
    () => flows.map((item) => ({ name: item.name, value: item._id })),
    [flows]
  );
  const loading = useAppSelector((state) => state.app).loading || false;
  const [files, setFiles] = useState<any | null>(null);
  const [text, setText] = useState<string | null>(null);
  const to = useTo();
  const currentUrl = window.location.href;

  const [form, setForm] = useState({
    company_name: '',
    position_name: '',
    position_description: '',
    flow_id: vacancy?.flow_id || selectList[0]?.value,
    ...(vacancy ? vacancy : {}),
  });

  const handleChange = (event: SelectChangeEvent) => {
    setForm({ ...form, flow_id: event.target.value });
  };

  const [errors, setErrors] = useState({
    company_name: '',
    position_name: '',
    position_description: '',
    flow_id: '',
  });

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      company_name: '',
      position_name: '',
      position_description: '',
      flow_id: '',
    };

    if (!form.company_name) {
      newErrors.company_name = 'Company name is required';
      isValid = false;
    }

    if (!form.position_name) {
      newErrors.position_name = 'Position name is required';
      isValid = false;
    }

    if (!form.position_description) {
      newErrors.position_description = 'Job description required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const sendHandler = () => {
    if (validateForm()) {
      if (vacancy?._id) {
        dispatch(updateVacancies([{ vacancy_id: vacancy._id, vacancy: form }]));
        dispatch(getVacancies(null)).finally(() => {
          if (vacancy._id) {
            dispatch(getApplicants({ vacancy_id: vacancy._id }));
          }
        });
      } else if (files?.length) {
        dispatch(
          createVacancies({ vacancies: files, query: { type: 'file' } })
        );
      } else if (text) {
        dispatch(
          createVacancies({ vacancies: [text], query: { type: 'text' } })
        );
      } else if (form.company_name !== '' && form.position_name !== '') {
        dispatch(
          createVacancies({ vacancies: [form], query: { type: 'object' } })
        );
      }

      handleCansel();
    }
  };

  const styles = {
    item: { margin: '2rem' },
    input: { margin: '4px', marginBottom: '1rem' },
  };

  useEffect(() => {
    if (flows.length === 0) {
      dispatch(getWorkflows(null));
    }
  }, []);

  const listFlow = () => {
    if (flows.length > 0) {
      return flows
        .find((item) => item._id === form.flow_id)
        .flow?.map((el) => <li key={el.code}>{el.name}</li>);
    }

    return <></>;
  };

  const handleCansel = () => {
    if (currentUrl.includes('dashboard') || currentUrl.includes('faq')) {
      to('/dashboard');
    } else if (currentUrl.includes('vacancy')) {
      to('/vacancy', vacancy?._id && { vacancy_id: vacancy._id });
    }
  };

  return (
    <div
      style={{
        minWidth: '340px',
        zIndex: 100,
      }}
    >
      <div className="d-flex justify-content-between" style={styles.item}>
        <div
          className="text"
          style={{
            fontSize: '18px',
            fontWeight: 400,
          }}
        >
          {vacancy ? 'Update Vacancy' : 'New Vacancy'}
        </div>
        <div>
          <CancelIcon
            style={{ color: '#999999', cursor: 'pointer' }}
            onClick={handleCansel}
          />
        </div>
      </div>
      <Divider sx={{ my: 0.5 }} />

      <div style={{ margin: '2rem' }}>
        <Autocomplete
          style={styles.input}
          freeSolo
          options={['a', 'b', 'v']}
          value={form.position_name}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="standard"
              label="Position name"
              placeholder="Fullstack JavaScript Developer"
              helperText={
                errors.position_name || 'For example, Project Manager'
              }
              error={Boolean(errors.position_name)}
              onChange={(e) => {
                setForm((prev: any) => ({
                  ...prev,
                  position_name: e.target.value,
                }));
                setErrors((prev) => ({ ...prev, position_name: '' }));
              }}
            />
          )}
        />
        <Autocomplete
          style={styles.input}
          freeSolo
          options={['a', 'b', 'v']}
          value={form.company_name}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="standard"
              label="Company name"
              placeholder="Talent Popcorn"
              helperText={
                errors.company_name ||
                'Enter company name you want to use. Once saved with this vacancy it will be available for selection from the list for the future vacancies.'
              }
              error={Boolean(errors.company_name)}
              onChange={(e) => {
                setForm((prev: any) => ({
                  ...prev,
                  company_name: e.target.value,
                }));
                setErrors((prev) => ({ ...prev, company_name: '' }));
              }}
            />
          )}
        />
        <TextField
          style={styles.input}
          multiline
          minRows={3}
          maxRows={vh < 680 ? 3 : 5}
          fullWidth
          variant="standard"
          label="Job description"
          placeholder="Fullstack JavaScript Developer"
          value={form.position_description}
          helperText={
            errors.position_description || 'Past job description here'
          }
          error={Boolean(errors.position_description)}
          // helperText="Company description"
          onChange={(e) => {
            setForm((prev: any) => ({
              ...prev,
              position_description: e.target.value,
            }));
          }}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '1rem',
          }}
        >
          <b>Hiring flow:</b>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            {loading ? (
              <>...</>
            ) : (
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                defaultValue={form.flow_id}
                onChange={handleChange}
              >
                {selectList?.map((flow) => (
                  <MenuItem key={uuidv4().slice(0, 4)} value={flow.value}>
                    {flow.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </div>
        {loading ? <>...</> : <ul className="list_flow">{listFlow()}</ul>}
      </div>

      {/*{!vacancy?._id && (
        <>
          <Tabs
            value={method}
            onChange={(_, newValue: number) => {
              setMethod(newValue);
            }}
          >
            <Tab label="Form" sx={{ textTransform: 'none' }} />
            <Tab label="File or text" sx={{ textTransform: 'none' }} />
          </Tabs>
          <Divider sx={{ my: 0.5, margin: '0' }} />
        </>
      )}*/}
      {/*<div
        style={{
          maxHeight: 'calc(100vh - 314px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        {method === 0 && (

        )}
      </div>
      <Divider sx={{ my: 0.5 }} />*/}

      {/*{method === 1 && (
        <div style={styles.item}>
          <label htmlFor="fileInput">
            <div
              className="d-flex justify-content-center align-items-center text-light"
              style={{
                borderRadius: '8px',
                border: '1px solid #DFDFDF',
                background: '#F2F2F2',
                padding: '16px',
                marginBottom: '16px',
              }}
            >
              <UploadFileIcon style={{ margin: '0 16px' }} />
              <div>
                {files?.length
                  ? [].map.call(files, (f: any) => f.name).join(', ')
                  : `Click to select a Vacancy file`}
              </div>
            </div>
          </label>
          <input
            id="fileInput"
            type="file"
            name="files"
            multiple={true}
            style={{ display: 'none' }}
            onChange={(e) => setFiles(e.target.files)}
          />

          <div className="d-flex justify-content-center align-items-center">
            <Divider sx={{ my: 0.5, width: '173px', margin: '16px' }} />
            <div className="text-light">or</div>
            <Divider sx={{ my: 0.5, width: '173px', margin: '16px' }} />
          </div>
          <TextField
            style={styles.input}
            multiline
            maxRows={
              vh < 600
                ? 3
                : vh < 640
                  ? 4
                  : vh < 660
                    ? 6
                    : vh < 770
                      ? 8
                      : vh < 880
                        ? 11
                        : 14
            }
            fullWidth
            variant="standard"
            label="Copy-paste Vacancy text here"
            value={text || ''}
            helperText="Copy-paste Vacancy text here"
            onChange={(e) => {
              setText((prev: any) => e.target.value);
            }}
          />
        </div>
      )}*/}

      <div style={styles.item} className="d-flex justify-content-end">
        <Button
          variant="outlined"
          style={{
            color: '#333',
            borderColor: '#333',
            margin: '0 8px',
          }}
          onClick={handleCansel}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={sendHandler}>
          Continue
        </Button>
      </div>
    </div>
  );
}
