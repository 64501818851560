import {
  Grid,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from '@mui/material';
import { ReactComponent as LogoSvg } from '../../../static/Logo.svg';
import useTo from '../../hooks/useTo';
import { useEffect, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import validator from 'validator';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
  requestOnChangePassword,
  setSession,
} from '../../../redux/slices/userSlice';
import Box from '@mui/material/Box';
import usePrivate from '../../hooks/usePrivate';
import { useLocation } from 'react-router-dom';

export default function PasswordRecovery() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const encodedEmailParam = searchParams.get('mail');
  const emailParam = encodedEmailParam ? atob(encodedEmailParam) : null;
  const [PrivateArea] = usePrivate('/login');
  const dispatch = useAppDispatch();
  const to = useTo();
  const userData = useAppSelector((state) => state.user.data);
  const [form, setForm] = useState({ pass: '', repeat: '' });
  const [valid, setValid] = useState({
    pass: true,
    repeat: true,
  });
  const [showPassword, setShowPassword] = useState(false);

  const recoveryHandler = () => {
    const [pass, repeat] = [
      !!validator.isStrongPassword(form.pass, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 0,
      }),
      form.pass === form.repeat,
    ];

    setValid({ pass, repeat });

    if (!pass || !repeat) return;

    if (userData?.email || emailParam) {
      dispatch(
        requestOnChangePassword({
          email: userData?.email || emailParam,
          password: form.pass,
        })
      );

      //   if (userData?.email) to('/dashboard');
      //   if (emailParam) to('/login');
    }
  };

  return (
    <>
      {!emailParam && <PrivateArea />}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '4rem 0',
        }}
      >
        <Box
          sx={{
            boxShadow: 2,
            borderRadius: 2,
            minWidth: 300,
            maxWidth: 500,
            background: '#ffffff',
          }}
        >
          <Box
            sx={{ p: 4 }}
            style={{ cursor: 'pointer' }}
            onClick={() => to('/welcome')}
          >
            <Box
              justifyContent="flex-start"
              alignItems="center"
              display={'inline-flex'}
            >
              <LogoSvg />
              <span
                className="text"
                style={{
                  paddingLeft: '10px',
                  fontSize: '20px',
                  lineHeight: '24px',
                  letterSpacing: '0.15px',
                  fontWeight: 'bold',
                  color: '#333333',
                }}
              >
                Talent Popcorn
              </span>
            </Box>
          </Box>

          <Divider sx={{ my: 0.5 }} />

          <Box sx={{ p: 4 }}>
            <Grid container rowSpacing={1} columnSpacing={2}>
              <Grid item xs={12}>
                <h1
                  style={{
                    fontSize: '1.5rem',
                    padding: 0,
                    margin: 0,
                  }}
                >
                  Reset Password
                </h1>
                <p
                  style={{
                    fontSize: '1rem',
                    color: '#333333',
                    margin: '1rem 0 0 0',
                  }}
                >
                  Please choose your new password.
                </p>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth variant="standard" error={!valid.pass}>
                  <InputLabel htmlFor="standard-adornment-password">
                    Password
                  </InputLabel>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    value={form.pass}
                    error={!valid.pass}
                    onChange={(e) =>
                      setForm((prev: any) => ({
                        ...prev,
                        pass: e.target.value,
                      }))
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword((prev: any) => !prev)}
                          onMouseLeave={() => setShowPassword(false)}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText error={!valid.pass}>
                    {valid.pass
                      ? 'Your password here'
                      : 'Must be longer than 8 chars and contains lowercase and uppercase letters and numbers'}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="standard" error={!valid.repeat}>
                  <InputLabel htmlFor="standard-adornment-password">
                    Password repeat
                  </InputLabel>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    value={form.repeat}
                    error={!valid.repeat}
                    onChange={(e) =>
                      setForm((prev: any) => ({
                        ...prev,
                        repeat: e.target.value,
                      }))
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword((prev: any) => !prev)}
                          onMouseLeave={() => setShowPassword(false)}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText error={!valid.repeat}>
                    {valid.repeat
                      ? 'Repeat the password'
                      : 'Must be exactly the same as the password'}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ pt: 4 }}>
              <Button
                fullWidth
                variant="contained"
                size="large"
                sx={{ fontSize: '1rem' }}
                onClick={recoveryHandler}
              >
                Submit
              </Button>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}
