export const defaultFlows = [
  {
    code: 'declined',
    type: 'declined',
    name: 'Declined',
    editable: false,
    _id: '6597c369a5f516780e5dc6a6',
  },
  {
    code: 'new',
    type: null,
    name: 'New',
    editable: false,
    _id: '6597c369a5f516780e5dc6a7',
  },
  {
    code: 'interview',
    type: 'interview',
    name: 'Intreview',
    editable: false,
    _id: '6597c369a5f516780e5dc6ab',
  },
  {
    code: 'offer_accepted',
    type: 'finalist',
    name: 'Offer accepted',
    editable: false,
    _id: '6597c369a5f516780e5dc6ae',
  },
];
