import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import {urls, herald} from '../urls';

// --- ACTIONS ---

// --- SLICE ---

const initialState: { processes: { key: string; created: number }[] | [] } = {
  processes: [],
};

export const processSlice = createSlice({
  name: 'process',
  initialState,
  reducers: {
    init: (state) => {
      const proc = JSON.parse(
        window.localStorage.getItem('processes') || '[]'
      ).filter((p) => +p.created > Date.now() - 1000 * 60);
      window.localStorage.setItem('processes', JSON.stringify(proc));
      state.processes = proc;
    },
    addProcess: (state, action: PayloadAction<{ key: string }>) => {
      const proc = [
        ...state.processes,
        { ...action.payload, created: Date.now() },
      ];
      window.localStorage.setItem('processes', JSON.stringify(proc));
      state.processes = proc;
    },
    removeProcess: (state, action: PayloadAction<{ key: string }>) => {
      const item = state.processes
        .sort((a, b) => b.created - a.created)
        .find((p) => p.key === action.payload.key);
      const proc = state.processes.filter(
        (p) => JSON.stringify(p) !== JSON.stringify(item)
      );
      window.localStorage.setItem('processes', JSON.stringify(proc));
      state.processes = proc;
    },
    removeAll: (state) => {
      window.localStorage.setItem('processes', JSON.stringify([]));
      state.processes = [];
    },
  },
});

export const { init, addProcess, removeProcess, removeAll } =
  processSlice.actions;
export default processSlice;
