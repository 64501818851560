import {Alert, Badge, Button, Card, CardActions, CardContent, CardMedia, Grid, Paper} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../../../redux/store';
import MainTitle from '../../pices/MainTitle';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import AddVacancy from "../AddVacancy";
import EntityModal from "../../EntityModal";
import useTo from "../../../hooks/useTo";
import {FaqButtonsBlocks} from "./FaqButtonsBlocks";
import FaqGettingStarted from "./FaqGettingStarted";
import {getVacancies} from "../../../../redux/slices/appSlice";
import FaqCandidateDetails from "./FaqCandidateDetails";
import FaqSubscrionPayment from "./FaqSubscrionPayment";

export default function FAQ() {

    const user = useAppSelector((state) => state.user);
    const to = useTo();

    const switcher = window.localStorage.getItem('switcher');
    const dispatch = useAppDispatch();
    const [switched] = useState(switcher === 'true');


    const openPop = (scene, options = {}) => {
        to(null, {scene, ...options});
    };
    /** Switch */
    useEffect(() => {
        window.localStorage.setItem('switcher', switched ? 'true' : 'false');
        dispatch(getVacancies({switch: switched ? 'true' : 'false'}));
    }, [switched]);


    return (
        <>
            <Box style={{maxWidth: '1476px',}} paddingBottom={2}>
                <MainTitle
                    header={`Welcome, ${user?.data?.name || 'User'}`}
                    subheader={'Learn the Talent Popcorn AI Basics now!'}
                />
                <Box paddingY={1}>
                    <Alert severity="info">This FAQ will continue to appear until you create your
                        first vacancy. Once you've posted your initial vacancy, you can find this information on
                        the <a href={'faq'}>FAQ page</a> or
                        look
                        for {/*<HelpOutlinedIcon style={{color: '#333333', fontSize: '20px',}}/>*/} "question"
                        icons on the sidebar to get more information about functionality you are working
                        with. We recommend that you review the <b>
                            <a href={''} onClick={(e) => {
                                e.preventDefault();
                                openPop('faq-getting-started');
                            }}>Getting Started</a>
                        </b> section before posting your
                        first vacancy. Good luck!
                    </Alert>
                </Box>

                <Paper style={{padding: '16px', margin: '8px 0'}}>

                    <Typography variant="body2" color="text.secondary" paddingBottom={2}>
                        {/*<p>Hello, we're glad to see you here!</p>*/}
                        Talent Popcorn is an AI-first talent acquisition system. With Talent Popcorn,
                        you can manage the entire recruitment process and save a significant amount of
                        your valuable time with our scoring, assessment, and evaluation tools. On this
                        page,
                        you can learn about our system's capabilities, settings, and
                        discover some productivity tips and tricks for better performance with Talent
                        Popcorn.
                        Additionally, you'll find information on how to use our API to integrate results
                        with
                        third-party systems.
                    </Typography>

                    <FaqButtonsBlocks user={user.data}/>

                </Paper>


                <Grid container spacing={4} paddingY={2}>
                    {/* GETTING STARTED */}
                    <Grid item>
                        <Card sx={{maxWidth: 345}}>
                            <Box onClick={() => openPop('faq-getting-started')} style={{cursor: 'pointer',}}>
                                <CardMedia
                                    sx={{height: 140}}
                                    image="/images/faq_gettingstarted.png"
                                    title="faq-getting-started"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Getting Started
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" minHeight={80}>
                                        Discover how to create your first vacancy and add candidates to the
                                        system.
                                        Find out where to view scoring results and learn the process of moving
                                        candidates through your hiring workflow.
                                    </Typography>
                                </CardContent>
                            </Box>
                            <CardActions>
                                <Button size="small" onClick={() => openPop('faq-getting-started')}>View
                                    Documentation</Button>
                                <Button size="small" disabled>Watch Video</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    {/* CANDIDATE DETAILS */}
                    <Grid item>
                        <Card sx={{maxWidth: 345}}>
                            <Box onClick={() => openPop('faq-candidate-details')} style={{cursor: 'pointer',}}>
                                <CardMedia
                                    sx={{height: 140}}
                                    image="/images/faq_candidatedetailes.png"
                                    title="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Candidate Details
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" minHeight={80}>
                                        Explore the candidate profile and how to manage it.
                                        Understand how AI assesses and evaluates candidate
                                        profiles to find the best matches for your vacancies.
                                    </Typography>
                                </CardContent>
                            </Box>
                            <CardActions>
                                <Button size="small" onClick={() => openPop('faq-candidate-details')}>View
                                    Documentation</Button>
                                <Button size="small" disabled>Watch Video</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    {/* DESCRIPTION GUIDE */}
                    <Grid item>
                        <Card sx={{maxWidth: 345}}>
                            <Box onClick={() => openPop('faq-subscription-payment')} style={{cursor: 'pointer',}}>
                                <CardMedia
                                    sx={{height: 140}}
                                    image="/images/faq_payment.png"
                                    title="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Subscription and Payment
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" minHeight={80}>
                                        Find out how to subscribe to Talent Popcorn, choose the right tariff, apply
                                        promo codes, and manage your subscription.
                                    </Typography>
                                </CardContent>
                            </Box>
                            <CardActions>
                                <Button size="small" onClick={() => openPop('faq-subscription-payment')}>View
                                    Documentation</Button>
                                <Button size="small" disabled>Watch Video</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                {/* SOON */}
                <Grid container spacing={4} paddingY={2}>
                    <Grid item>
                        <Badge badgeContent={'soon'} color="info">
                            <Card sx={{maxWidth: 345}}>
                                <CardMedia
                                    sx={{height: 140}}
                                    image="/images/faq_hiringflow.png"
                                    title="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Hiring Flow Control
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" minHeight={80}>
                                        Explore managing your hiring workflows, including creating
                                        new recruitment schemas and associating them with vacancies.
                                        Learn about various types of workflow steps.
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" disabled>View Documentation</Button>
                                    <Button size="small" disabled>Watch Video</Button>
                                </CardActions>
                            </Card>
                        </Badge>
                    </Grid>
                    <Grid item>
                        <Badge badgeContent={'soon'} color="info">
                            <Card sx={{maxWidth: 345}}>
                                <CardMedia
                                    sx={{height: 140}}
                                    image="/images/faq_tipsandtricks.png"
                                    title="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Tips and Tricks
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" minHeight={80}>
                                        Discover productivity tips and tricks to enhance your performance
                                        with Talent Popcorn and understand how AI can amplify your productivity.
                                        More comes soon!
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" disabled>View Documentation</Button>
                                    <Button size="small" disabled>Watch Video</Button>
                                </CardActions>
                            </Card>
                        </Badge>
                    </Grid>
                    <Grid item>
                        <Badge badgeContent={'soon'} color="info">
                            <Card sx={{maxWidth: 345}}>
                                <CardMedia
                                    sx={{height: 140}}
                                    image="/images/faq_developertools.png"
                                    title="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Developer Tools
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" minHeight={80}>
                                        Find out how to use the Talent Popcorn API for integrating
                                        systems with your other ATS, HCMS, ERP, or website and for
                                        remotely collecting results.
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" disabled>View Documentation</Button>
                                    <Button size="small" disabled>Watch Video</Button>
                                </CardActions>
                            </Card>
                        </Badge>
                    </Grid>
                    <Grid item>
                        <Badge badgeContent={'soon'} color="info">
                            <Card sx={{maxWidth: 345}}>
                                <CardMedia
                                    sx={{height: 140}}
                                    image="/images/faq_troubleshooting.png"
                                    title="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Troubleshooting
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" minHeight={80}>
                                        Are you experiencing difficulties with using the service, access, or recognizing
                                        information? Ask your question here.<br/>
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" disabled>View Documentation</Button>
                                    <Button size="small" disabled>Watch Video</Button>
                                </CardActions>
                            </Card>
                        </Badge>
                    </Grid>
                </Grid>
            </Box>

            <EntityModal scene="add-vacancy" top="64px" right="0" dark>
                <AddVacancy/>
            </EntityModal>

            <EntityModal scene="faq-getting-started" top="64px" right="0" dark>
                <FaqGettingStarted/>
            </EntityModal>

            <EntityModal scene="faq-candidate-details" top="64px" right="0" dark>
                <FaqCandidateDetails/>
            </EntityModal>

            <EntityModal scene="faq-subscription-payment" top="64px" right="0" dark>
                <FaqSubscrionPayment/>
            </EntityModal>

        </>
    );
};
