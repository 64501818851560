import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {herald, urls} from '../urls';
import {addHint} from './hintsSlice';
import {clearAppData} from './appSlice';

// --- ACTIONS ---

export const getUser = createAsyncThunk(
  'users/get',
  async (_, { dispatch }) => {
    try {
      const token = window.sessionStorage.getItem('token');
      const tokenHeader = token
        ? {
            'x-auth': 'Bearer ' + token,
          }
        : {};

      const response = await herald(urls.getUser(), {
        headers: { ...tokenHeader },
      });

      if (response.ok) {
        window.sessionStorage.setItem(
          'token',
          response.headers.get('x-auth').replace('Bearer ', '')
        );
        return await response.json();
      }
    } catch (e) {
      window.sessionStorage.removeItem('token');
      console.error(e);
      // @ts-ignore
      // dispatch(addHint('getUser error:' + e.message));
    }
  }
);

export const signup = createAsyncThunk(
  'users/signup',
  async (
    user: {
      name: string;
      surname: string;
      company: string;
      email: string;
      password: string;
    },
    { dispatch }
  ) => {
    try {
      const response = await herald(urls.signup(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });
      if (!response.ok)
        throw Error(
          (await response.json()).error || 'form data is invalid or empty'
        );
      window.sessionStorage.removeItem('token');
      window.sessionStorage.setItem(
        'token',
        response.headers.get('x-auth').replace('Bearer ', '')
      );
      dispatch(getUser());
    } catch (e) {
      // @ts-ignore
      dispatch(addHint(e?.message || 'users/signup ERROR'));
    }
  }
);

export const login = createAsyncThunk(
  'users/login',
  async (
    { email, password }: { email: string; password: string },
    { dispatch }
  ) => {
    try {
      const response = await herald(urls.login(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
      if (!response.ok) throw Error('email or password is invalid');
      window.sessionStorage.setItem(
        'token',
        response.headers.get('x-auth').replace('Bearer ', '')
      );
      return await response.json();
    } catch (e) {
      // @ts-ignore
      dispatch(addHint(e?.message || 'users/login ERROR'));
    }
  }
);

export const logout = createAsyncThunk(
  'users/logout',
  async (_, { dispatch }) => {
    try {
      window.sessionStorage.removeItem('token');
      const response = await herald(urls.logout());
      if (!response.ok) throw Error();
      dispatch(clearAppData());
      window.location.reload();
    } catch (e) {
      //@ts-ignore
      dispatch(addHint('logout feild' + e.message));
    }
  }
);

export const requestOnResetPassword = createAsyncThunk(
  'email/password-reset',
  async ({ email }: { email: string }, { dispatch }) => {
    try {
      const response = await herald(urls.requestOnResetPassword(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const errorResponse = await response.text();

      if (!response.ok) throw Error(JSON.parse(errorResponse).error);

      const authTokenHeader = response.headers.get('x-auth');
      if (authTokenHeader) {
        const authToken = authTokenHeader.replace('Bearer ', '');
        window.sessionStorage.setItem('token', authToken);
      }

      dispatch(addHint({ text: errorResponse, type: 'success' }));

      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set('scene', 'password-recovery');
      window.location.href = currentUrl.href;

      return errorResponse;
    } catch (e) {
      // @ts-ignore
      dispatch(addHint(e?.message || 'email/password-reset ERROR'));
    }
  }
);

export const requestOnChangePassword = createAsyncThunk(
  'email/password-changed',
  async (
    { email, password }: { email: string; password: string },
    { dispatch }
  ) => {
    try {
      const response = await herald(urls.resetPassword(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const errorResponse = await response.text();

      if (!response.ok) throw Error(JSON.parse(errorResponse).error);

      const authTokenHeader = response.headers.get('x-auth');
      if (authTokenHeader) {
        const authToken = authTokenHeader.replace('Bearer ', '');
        window.sessionStorage.setItem('token', authToken);
      }

      dispatch(addHint({ text: errorResponse, type: 'success' }));

      const currentUrl = new URL(window.location.href);
      window.location.href = `${currentUrl.origin}/en/login?scene=password-reset`;

      return errorResponse;
    } catch (e) {
      // @ts-ignore
      dispatch(addHint(e?.message || 'email/password-reset ERROR'));
    }
  }
);

export const editUserData = createAsyncThunk(
  'users/edit-user-data',
  async (
    user: {
      _id: string;
      name: string;
      surname: string;
      company: string;
      phone: string;
    },
    { dispatch }
  ) => {
    try {
      const response = await herald(urls.editUserData(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });

      const successResponse = await response.text();

      if (!response.ok) throw Error(JSON.parse(successResponse).error);

      dispatch(getUser());
      dispatch(addHint({ text: successResponse, type: 'success' }));
    } catch (e) {
      // @ts-ignore
      dispatch(addHint(e?.message || 'users/edit-user-data ERROR'));
    }
  }
);

export const createPaymentSession = createAsyncThunk(
  'stripe/create-session',
  async (
    user: {
      _id: string;
    },
    { dispatch }
  ) => {
    try {
      const response = await herald(urls.createPaymentSession(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });

      const successResponse = await response.text();

      if (!response.ok) throw Error(JSON.parse(successResponse).error);

      dispatch(getUser());

      // Open a new window with the received URL
      // window.open(successResponse, '_blank');
      window.location.href = successResponse;
    } catch (e) {
      // @ts-ignore
      dispatch(addHint(e?.message || 'stripe/create-session ERROR'));

      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set('scene', 'tariff-selection-error');
      window.location.href = currentUrl.href;
    }
  }
);

export const createPaymentIntent = createAsyncThunk(
  'stripe/create-payment-intent',
  async (
    user: {
      _id: string;
      price: string;
      quantity: string;
    },
    { dispatch }
  ) => {
    try {
      const response = await herald(urls.createPaymentIntent(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });

      const successResponse = await response.text();

      if (!response.ok) throw Error(JSON.parse(successResponse).error);

      dispatch(getUser());

      // Open a new window with the received URL
      // window.open(successResponse, '_blank');
      window.location.href = successResponse;
    } catch (e) {
      // @ts-ignore
      dispatch(addHint(e?.message || 'stripe/create-payment-intent ERROR'));

      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set('scene', 'tariff-selection-error');
      window.location.href = currentUrl.href;
    }
  }
);

export const changeAvatar = createAsyncThunk(
  'users/avatar',
  async (
    user: {
      _id: string;
      userpic: string;
    },
    { dispatch }
  ) => {
    try {
      const response = await herald(urls.changeAvatar(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });

      const successResponse = await response.text();

      if (!response.ok) throw Error(JSON.parse(successResponse).error);

      dispatch(getUser());
      dispatch(addHint({ text: successResponse, type: 'success' }));
    } catch (e) {
      // @ts-ignore
      dispatch(addHint(e?.message || 'users/avatar ERROR'));
    }
  }
);

export const changeNotifications = createAsyncThunk(
  'users/change-notifications',
  async (
    user: {
      _id: string;
      marketing_consents: MarketingConsentsType;
    },
    { dispatch }
  ) => {
    try {
      const response = await herald(urls.changeNotifications(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });

      const successResponse = await response.text();

      if (!response.ok) throw Error(JSON.parse(successResponse).error);

      dispatch(getUser());
      dispatch(addHint({ text: successResponse, type: 'success' }));
    } catch (e) {
      // @ts-ignore
      dispatch(addHint(e?.message || 'users/change-notifications ERROR'));
    }
  }
);

export const changePassword = createAsyncThunk(
  'users/change-password',
  async (
    user: {
      _id: string;
      current_password: string;
      new_password: string;
    },
    { dispatch }
  ) => {
    try {
      const response = await herald(urls.changePassword(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });

      const successResponse = await response.text();

      if (!response.ok) throw Error(JSON.parse(successResponse).error);

      dispatch(getUser());
      dispatch(addHint({ text: successResponse, type: 'success' }));
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set('scene', 'password-changed');
      window.location.href = currentUrl.href;
    } catch (e) {
      // @ts-ignore
      dispatch(addHint(e?.message || 'users/change-password ERROR'));
    }
  }
);

export const deleteAccount = createAsyncThunk(
  'users/delete-account',
  async (
    user: {
      _id: string;
    },
    { dispatch }
  ) => {
    try {
      const response = await herald(urls.deleteAccount(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });

      const successResponse = await response.text();

      if (!response.ok) throw Error(JSON.parse(successResponse).error);

      window.sessionStorage.removeItem('token');

      dispatch(addHint({ text: successResponse, type: 'success' }));

      return successResponse;
    } catch (e) {
      // @ts-ignore
      dispatch(addHint(e?.message || 'users/delete-account ERROR'));
    }
  }
);

export const downloadAccount = createAsyncThunk(
  'users/download-account',
  async (
    user: {
      _id: string;
    },
    { dispatch }
  ) => {
    try {
      const response = await herald(urls.downloadAccount(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });

      const successResponse = await response.text();

      if (!response.ok) throw Error(JSON.parse(successResponse).error);

      dispatch(addHint({ text: successResponse, type: 'success' }));

      return successResponse;
    } catch (e) {
      // @ts-ignore
      dispatch(addHint(e?.message || 'users/download-account ERROR'));
    }
  }
);

export const getUserStatus = createAsyncThunk(
  'users/status',
  async (user: { _id: string }, { dispatch }) => {
    try {
      const response = await herald(urls.getUserStatus(user._id), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const successResponse = await response.text();

      if (!response.ok) throw Error(JSON.parse(successResponse).error);

      window.sessionStorage.setItem(
        'token',
        response.headers.get('x-auth').replace('Bearer ', '')
      );

      return JSON.parse(successResponse);
    } catch (e: any) {
      // @ts-ignore
      dispatch(addHint(e?.message || 'users/status ERROR'));
    }
  }
);

export const generateApiKey = createAsyncThunk(
  'users/generate-api-key',
  async (_, { dispatch }) => {
    try {
      const response = await herald(urls.generateApiKey(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const successResponse = await response.text();

      if (!response.ok) throw Error(JSON.parse(successResponse).error);

      dispatch(
        addHint({ text: 'ApiKey was generated success', type: 'success' })
      );
      dispatch(getUser());
    } catch (e) {
      // @ts-ignore
      dispatch(addHint(e?.message || 'users/generate-api-key ERROR'));
    }
  }
);

export const removeApiKey = createAsyncThunk(
  'users/generate-api-key',
  async (_, { dispatch }) => {
    try {
      const response = await herald(urls.generateApiKey(), {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const successResponse = await response.text();

      if (!response.ok) throw Error(JSON.parse(successResponse).error);

      dispatch(
        addHint({ text: 'ApiKey was deleted success', type: 'success' })
      );
      dispatch(getUser());
    } catch (e) {
      // @ts-ignore
      dispatch(addHint(e?.message || 'users/generate-api-key ERROR'));
    }
  }
);

// --- SLICE ---

export interface UserData {
  _id: string;
  email: string;
  password: string;
  name?: string;
  surname?: string;
  company?: string;
  userpic?: string;
  is_active?: Boolean;
  is_subscription_active?: Boolean;
  phone?: string;
  marketing_consents?: MarketingConsentsType;
  stripe_id?: string;
  activeVacanciesCount?: number;
  applicantsCount?: number;
  free_limit_reached?: boolean;
  free_candidate_limit_reached?: boolean;
  api_key?: string;
}

type MarketingConsentsType = {
  product?: boolean;
  events?: boolean;
  surveys?: boolean;
};

export interface UserState {
  data: UserData | null;
  session: any | null;
}

const initialState: UserState = {
  data: null,
  session: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      if (state.data.email || action.payload.email)
        state.data = { ...state.data, ...action.payload };
    },
    setSession: (state, action) => {
      state.session = { ...state.session, ...action.payload };
    },
    updateSubscriptionStatus: (state, action) => {
      if (state.data) {
        state.data.is_subscription_active =
          action.payload.is_subscription_active;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload;
        }
        state.session = { checked: true };
      })
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload;
        }
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.data = null;
      })
      .addCase(getUserStatus.fulfilled, (state, action) => {
        const { is_subscription_active } = action.payload || {};
        state.data.is_subscription_active = is_subscription_active;
      });
  },
});

export const { setUser, setSession, updateSubscriptionStatus } =
  userSlice.actions;
export default userSlice;
