import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  useParams,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { store, useAppDispatch, useAppSelector } from '../../redux/store';
import { setLocale, languages } from '../../redux/slices/localeSlice';
import { getUserStatus } from '../../redux/slices/userSlice';

interface props {
  roots: object;
  default?: string;
  onReplace?(location: Location): void;
}

export default function Router(props: props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.data);

  useEffect(() => {
    // Проверяем, содержится ли "payment=success" в параметрах URL
    const hasPaymentSuccess = location.search.includes('payment=success');

    if (hasPaymentSuccess) {
      const timeout = setTimeout(() => {
        user?._id && dispatch(getUserStatus({ _id: user._id }));
        // Удаляем "payment=success" из URL без перезагрузки страницы
        const newSearch = location.search.replace(
          /(\?|&)payment=success(&|$)/,
          '$1'
        );
        navigate({ search: newSearch });
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [location.pathname, navigate, user]);

  return (
    <>
      <Routes>
        <Route path="/" element={<PageRenderer {...props} />} />
        <Route path="/:lang" element={<PageRenderer {...props} />} />
        <Route path="//:lang" element={<PageRenderer {...props} />} />
        <Route path="/:lang/:p1" element={<PageRenderer {...props} />} />
        <Route path="/:lang/:p1/:p2" element={<PageRenderer {...props} />} />
        <Route
          path="/:lang/:p1/:p2/:p3"
          element={<PageRenderer {...props} />}
        />
        <Route
          path="/:lang/:p1/:p2/:p3/:p4"
          element={<PageRenderer {...props} />}
        />
        <Route
          path="/:lang/:p1/:p2/:p3/:p4/:p5"
          element={<PageRenderer {...props} />}
        />
        <Route
          path="/:lang/:p1/:p2/:p3/:p4/:p5/:p6"
          element={<PageRenderer {...props} />}
        />
        <Route
          path="/:lang/:p1/:p2/:p3/:p4/:p5/:p6/:p7"
          element={<PageRenderer {...props} />}
        />
      </Routes>
    </>
  );
}

function PageRenderer(props: props) {
  const dispatch = useAppDispatch();
  const locale = store.getState().locale.lang;
  // const navigate = useNavigate();
  let { lang, ...pages } = useParams();
  let path = Object.values(pages)
    .map((p) => p || '')
    .join('/')
    .split('?')[0];
  let id = null;
  let origPath = null;

  if (path.includes(':')) {
    const [newPath, newId] = path.split(':');
    path = `${newPath}:id`;
    id = newId;
    origPath = newPath;
  }

  const check = () => {
    const resultLang = languages.find((l) => l === lang) || languages[0];
    const rPath = !props.roots[path]
      ? props.default || 'welcome'
      : id
        ? origPath + id
        : path;

    if (!path || !lang || lang !== resultLang || !props.roots[path])
      window.location.pathname = `/${
        lang === resultLang ? lang : languages[0]
      }/${rPath}`;
    if (lang !== locale) dispatch(setLocale(resultLang));
  };

  useEffect(() => {
    check();
    if (typeof props.onReplace === 'function') props.onReplace(window.location);
  });

  return (
    <>
      {React.cloneElement(props.roots[path] || <></>, {
        ...(props.roots[path]?.props || {}),
        query: new URLSearchParams(useLocation().search),
      })}
    </>
  );
}
