interface request {
  query?: any;
  body?: any;
}

const sepData = {
  /*
        payload {
            "name": "Name",
            "surname": "Names",
            "company": "Popcorn",
            "email": "test5@test.test",
            "password": "test12345"
        }   
        content-type: application/json
    */
  '[POST]user/signup2': ({ query, body }: request) =>
    sepData['user/signup']({ query, body }),
  '[POST]user/signup': ({ query, body }: request) => ({
    status: 200,
    ok: true,
    body: 'created',
  }),
  /*
        x-auth: 'Bearer tokenHash'
    */
  '[GET]user/auth': ({ query, body }: request) => ({
    status: 200,
    ok: true,
    body: {
      name: 'Name',
      surname: 'Names',
      company: 'Popcorn',
      email: 'test5@test.test',
    },
    headers: new Headers({
      'x-auth': 'Bearer da22a017-7f33-406f-84c8-d0018f991f8f',
    }),
  }),

  /* 
        payload {email, password}
        content-type: application/json
    */
  '[POST]user/login': ({ query, body }: request) => ({
    status: 200,
    ok: true,
    body: {
      name: 'Name',
      surname: 'Names',
      company: 'Popcorn',
      email: 'test5@test.test',
    },
    headers: new Headers({
      'x-auth': 'Bearer da22a017-7f33-406f-84c8-d0018f991f8f',
    }),
  }),

  '[GET]user/logout': ({ query, body }: request) => ({
    status: 200,
    ok: true,
  }),

  '[GET]local': ({ query, body }: request) => ({
    status: 200,
    ok: true,
    body: {
      hello: 'привет',
      user: 'пользователь',
    },
  }),

  /*
    query: {page?: number, size?: number}
*/
  '[GET]vacancies/index': ({ query, body }: request) => ({
    status: 200,
    ok: true,
    body: [
      {
        _id: 'abc123',
        company_name: 'Talent Popcorn',
        position_name: 'Node.js + React Teamlider',
        position_description:
          ' 5 ears + expireans js fullstack developer for best startap in the world',
        status: 'active', // active | finished | canceled | deleted
        flow: [
          {
            _id: 'dlmg123',
            code: 'asd',
            name: 'meating',
            type: '',
            editeble: false,
          },
        ],
        stats: ['flowcode of applicant', 'flowcode of another applicant'],
      },
    ],
  }),
  /*
        payload: [{
            company_name: 'Talent Popcorn',
            position_name: 'Node.js + React Teamlider',
            position_description: ' 5 ears + expireans js fullstack developer for best startap in the world',
        }] | [ 'text of vacancy' ] | [files]

            query: {
            type: 'ojbect' | 'text' | 'file',
            }

        Content-Type: application/json | application/form-data
    */
  '[POST]vacancies/index': ({ query, body }: request) => ({
    status: 200,
    ok: true,
    body: [
      {
        _id: 'abc123',
        company_name: 'Talent Popcorn',
        position_name: 'Node.js + React Teamlider',
        position_description:
          ' 5 ears + expireans js fullstack developer for best startap in the world',
        status: 'active', // active | finished | canceled | deleted
        flow: [{ code: 'asd', name: 'meating', type: '', editeble: false }],
      },
    ],
  }),
  /*
        payload: [{
            vacancy_id: string,
            vacancy: { 
                ...keys  // replace keys
            }
        }]

        Content-Type: application/json 
    */
  '[PUT]vacancies/index': ({ query, body }: request) => ({
    status: 200,
    ok: true,
    body: [
      {
        _id: 'abc123',
        company_name: 'Talent Popcorn',
        position_name: 'Node.js + React Teamlider',
        position_description:
          ' 5 ears + expireans js fullstack developer for best startap in the world',
        status: 'active', // active | finished | canceled | deleted
        flow: [{ code: 'asd', name: 'meating', type: '', editeble: false }],
      },
    ],
  }),

  /*
        payload: {vacancies: ['id12', 'id23']}
    */
  '[DELETE]vacancies/index': ({ query, body }: request) => ({
    status: 200,
    ok: true,
    body: 'ok',
  }),

  /*
        query: {vacancy_id: string, page?: number, size?: number}
    */
  '[GET]applicants/index': ({ query, body }: request) => ({
    status: 200,
    ok: true,
    body: [
      {
        _id: 'nu123id',
        vacancy_id: 'abc123',
        flow_code: 'asd',
        resume: {
          _id: 'gjh123',
          candidate_id: 'bhy543',
          source_type: '',
          source_url: '',
          source_text: '',
          personal: {
            first_name: 'Kirill',
            last_name: 'Kukuliev',
            gender: 'male',
            age: 26,
            birthday: '',
          },
          languages: [
            {
              language: 'ru',
              proficiency: 'native',
            },
          ],
          location: {
            city: 'Tel-Aviv',
            state: 'Israel',
            country: 'israel',
          },
          phones: ['1234567890', '0987654321'],
          emails: ['kh@gmail.com', 'test@gmail.com'],
          social: [
            {
              name: 'Telegram',
              url: 'https://telegram.com/@kukulu',
            },
          ],
          summary: 'summary lorem inpusm',
          objective: 'objective',
          cover_letter: 'cover_letter',
          skills: ['sql', 'noSql'],
          experience: [
            {
              position: 'developer',
              company: 'tel-run',
              location: 'Israel, Rechovot',
              start_date: '22.03.2017',
              end_date: '22.01.2018',
              description: 'description',
              responsibilities: ['responsibilite-A', 'responsibilite-B'],
              achievements: ['achive-A', 'achive-B'],
            },
          ],
          education: [
            {
              degree: 'university',
              institution: 'mgtu',
              graduation_start_date: '01.09.2015',
              graduation_end_date: '31.06.2017',
              gpa: {
                type: Number,
              },
            },
          ],
          certifications: [
            {
              title: 'Diplom',
              issuing_organization: 'mgtu',
              issue_date: null,
              expiry_date: null,
            },
          ],
          empoyment_status: false,
        },
        scoring: {
          general_score: 8.7,
          experience_score: 10,
          skills_score: 5,
          explanation: 9,
        },
      },
    ],
  }),
  /*
        payload: [ 'text of vacancy' ] |[ files ]
    }
    query : {
        type: 'file' | 'text',
        vacancy_id: 'abc123'
    }
    Content-Type: application/json | application/form-data
    */
  '[POST]applicants/index': ({ query, body }: request) => ({
    status: 200,
    ok: true,
    body: [
      {
        _id: 'nu123id',
        vacancy_id: 'abc123',
        flow_code: 'asd',
        resume: {
          _id: 'gjh123',
          candidate_id: 'bhy543',
          source_type: '',
          source_url: '',
          source_text: '',
          personal: {
            first_name: 'Kirill',
            last_name: 'Kukuliev',
            gender: 'male',
            age: 26,
            birthday: '',
          },
          languages: [
            {
              language: 'ru',
              proficiency: 'native',
            },
          ],
          location: {
            city: 'Tel-Aviv',
            state: 'Israel',
            country: 'israel',
          },
          phones: ['1234567890', '0987654321'],
          emails: ['kh@gmail.com', 'test@gmail.com'],
          social: [
            {
              name: 'Telegram',
              url: 'https://telegram.com/@kukulu',
            },
          ],
          summary: 'summary lorem inpusm',
          objective: 'objective',
          cover_letter: 'cover_letter',
          skills: ['sql', 'noSql'],
          experience: [
            {
              position: 'developer',
              company: 'tel-run',
              location: 'Israel, Rechovot',
              start_date: '22.03.2017',
              end_date: '22.01.2018',
              description: 'description',
              responsibilities: ['responsibilite-A', 'responsibilite-B'],
              achievements: ['achive-A', 'achive-B'],
            },
          ],
          education: [
            {
              degree: 'university',
              institution: 'mgtu',
              graduation_start_date: '01.09.2015',
              graduation_end_date: '31.06.2017',
              gpa: {
                type: Number,
              },
            },
          ],
          certifications: [
            {
              title: 'Diplom',
              issuing_organization: 'mgtu',
              issue_date: null,
              expiry_date: null,
            },
          ],
          empoyment_status: false,
        },
        scoring: {
          general_score: 8.7,
          experience_score: 10,
          skills_score: 5,
          explanation: 9,
        },
      },
    ],
  }),

  /*
    payload [{
        applicant_id: 'nu123id',
        resume: { 
            ...keys 
            // replace keys: {personal, languages, location, phones, emails, social, summary, objective, cover_letter, skills, experience, education, certifications, empoyment_status}
        },
        applicant: { 
            ...keys 
            // replace keys: {personal, languages, location, phones, emails, social, summary, objective, cover_letter, skills, experience, education, certifications, empoyment_status}
        }
    }]
    
    Content-Type: application/json 
    */
  '[PUT]applicants/index': ({ query, body }: request) => ({
    status: 200,
    ok: true,
    body: [
      {
        _id: 'nu123id',
        vacancy_id: 'abc123',
        flow_code: 'asd',
        resume: {
          _id: 'gjh123',
          candidate_id: 'bhy543',
          source_type: '',
          source_url: '',
          source_text: '',
          personal: {
            first_name: 'Kirill',
            last_name: 'Kukuliev',
            gender: 'male',
            age: 26,
            birthday: '',
          },
          languages: [
            {
              language: 'ru',
              proficiency: 'native',
            },
          ],
          location: {
            city: 'Tel-Aviv',
            state: 'Israel',
            country: 'israel',
          },
          phones: ['1234567890', '0987654321'],
          emails: ['kh@gmail.com', 'test@gmail.com'],
          social: [
            {
              name: 'Telegram',
              url: 'https://telegram.com/@kukulu',
            },
          ],
          summary: 'summary lorem inpusm',
          objective: 'objective',
          cover_letter: 'cover_letter',
          skills: ['sql', 'noSql'],
          experience: [
            {
              position: 'developer',
              company: 'tel-run',
              location: 'Israel, Rechovot',
              start_date: '22.03.2017',
              end_date: '22.01.2018',
              description: 'description',
              responsibilities: ['responsibilite-A', 'responsibilite-B'],
              achievements: ['achive-A', 'achive-B'],
            },
          ],
          education: [
            {
              degree: 'university',
              institution: 'mgtu',
              graduation_start_date: '01.09.2015',
              graduation_end_date: '31.06.2017',
              gpa: {
                type: Number,
              },
            },
          ],
          certifications: [
            {
              title: 'Diplom',
              issuing_organization: 'mgtu',
              issue_date: null,
              expiry_date: null,
            },
          ],
          empoyment_status: false,
        },
        scoring: {
          general_score: 8.7,
          experience_score: 10,
          skills_score: 5,
          explanation: 9,
        },
      },
    ],
  }),

  /*
        payload: {applicants: ['id12', 'id23']}
    */
  '[DELETE]applicants/index': ({ query, body }: request) => ({
    status: 200,
    ok: true,
    body: 'ok',
  }),

  /*
        query: {vacancy_id: string, page?: number, size?: number}
    */
  '[GET]applicants/favorite': ({ query, body }: request) => ({
    status: 200,
    ok: true,
    body: [
      {
        _id: 'nu123id',
        vacancy_id: 'abc123',
        flow_code: 'asd',
        resume: {
          _id: 'gjh123',
          candidate_id: 'bhy543',
          source_type: '',
          source_url: '',
          source_text: '',
          personal: {
            first_name: 'Kirill',
            last_name: 'Kukuliev',
            gender: 'male',
            age: 26,
            birthday: '',
          },
          languages: [
            {
              language: 'ru',
              proficiency: 'native',
            },
          ],
          location: {
            city: 'Tel-Aviv',
            state: 'Israel',
            country: 'israel',
          },
          phones: ['1234567890', '0987654321'],
          emails: ['kh@gmail.com', 'test@gmail.com'],
          social: [
            {
              name: 'Telegram',
              url: 'https://telegram.com/@kukulu',
            },
          ],
          summary: 'summary lorem inpusm',
          objective: 'objective',
          cover_letter: 'cover_letter',
          skills: ['sql', 'noSql'],
          experience: [
            {
              position: 'developer',
              company: 'tel-run',
              location: 'Israel, Rechovot',
              start_date: '22.03.2017',
              end_date: '22.01.2018',
              description: 'description',
              responsibilities: ['responsibilite-A', 'responsibilite-B'],
              achievements: ['achive-A', 'achive-B'],
            },
          ],
          education: [
            {
              degree: 'university',
              institution: 'mgtu',
              graduation_start_date: '01.09.2015',
              graduation_end_date: '31.06.2017',
              gpa: {
                type: Number,
              },
            },
          ],
          certifications: [
            {
              title: 'Diplom',
              issuing_organization: 'mgtu',
              issue_date: null,
              expiry_date: null,
            },
          ],
          empoyment_status: false,
        },
        scoring: {
          general_score: 8.7,
          experience_score: 10,
          skills_score: 5,
          explanation: 9,
        },
      },
    ],
  }),

  /*
        payload : {
            applicant_id: 'abc123'
        }
        Content-Type: application/json
    */
  '[PUT]applicants/favorite': ({ query, body }: request) => ({
    status: 200,
    ok: true,
    body: [
      {
        _id: 'nu123id',
        vacancy_id: 'abc123',
        flow_code: 'asd',
        resume: {
          _id: 'gjh123',
          candidate_id: 'bhy543',
          source_type: '',
          source_url: '',
          source_text: '',
          personal: {
            first_name: 'Kirill',
            last_name: 'Kukuliev',
            gender: 'male',
            age: 26,
            birthday: '',
          },
          languages: [
            {
              language: 'ru',
              proficiency: 'native',
            },
          ],
          location: {
            city: 'Tel-Aviv',
            state: 'Israel',
            country: 'israel',
          },
          phones: ['1234567890', '0987654321'],
          emails: ['kh@gmail.com', 'test@gmail.com'],
          social: [
            {
              name: 'Telegram',
              url: 'https://telegram.com/@kukulu',
            },
          ],
          summary: 'summary lorem inpusm',
          objective: 'objective',
          cover_letter: 'cover_letter',
          skills: ['sql', 'noSql'],
          experience: [
            {
              position: 'developer',
              company: 'tel-run',
              location: 'Israel, Rechovot',
              start_date: '22.03.2017',
              end_date: '22.01.2018',
              description: 'description',
              responsibilities: ['responsibilite-A', 'responsibilite-B'],
              achievements: ['achive-A', 'achive-B'],
            },
          ],
          education: [
            {
              degree: 'university',
              institution: 'mgtu',
              graduation_start_date: '01.09.2015',
              graduation_end_date: '31.06.2017',
              gpa: {
                type: Number,
              },
            },
          ],
          certifications: [
            {
              title: 'Diplom',
              issuing_organization: 'mgtu',
              issue_date: null,
              expiry_date: null,
            },
          ],
          empoyment_status: false,
        },
        scoring: {
          general_score: 8.7,
          experience_score: 10,
          skills_score: 5,
          explanation: 9,
        },
      },
    ],
  }),

  /*
        query: {entity_id: string, page?: number, size?: number}
    */
  '[GET]notes/index': ({ query, body }: request) => ({
    status: 200,
    ok: true,
    body: [
      {
        _id: 'id123',
        entity_id: 'qwe123',
        user_id: 'usr123',
        collectionName: 'Vacancy', // | 'Applicant'
        text: 'text',
      },
    ],
  }),
  /*
        payload: [ {
            entity_id: string,
            collectionName: 'Vacancy' | 'Candidate
            text: string
        }]
        
        Content-Type: application/json 
    */
  '[POST]notes/index': ({ query, body }: request) => ({
    status: 200,
    ok: true,
    body: [
      {
        _id: 'id123',
        entity_id: 'qwe123',
        user_id: 'usr123',
        collectionName: 'Vacancy', // | 'Candidate
        text: 'text',
      },
    ],
  }),

  /*
    payload: [ {
            _id: string, text: string
        }]
    
    Content-Type: application/json 
    */
  '[PUT]notes/index': ({ query, body }: request) => ({
    status: 200,
    ok: true,
    body: [
      {
        _id: 'id123',
        entity_id: 'qwe123',
        user_id: 'usr123',
        collectionName: 'Vacancy', // | 'Candidate
        text: 'text',
      },
    ],
  }),

  /*
        payload: {notes: ['id12', 'id23']}
    */
  '[DELETE]notes/index': ({ query, body }: request) => ({
    status: 200,
    ok: true,
    body: 'ok',
  }),
  /*
        payload: {
            entity_id: string,
            collectionName: 'Applicant' | string
            result object
        }
        
        Content-Type: application/json 
    */
  '[POST]feedback/index': ({ query, body }: request) => ({
    status: 200,
    ok: true,
    body: 'ok',
  }),
};

export default sepData;
