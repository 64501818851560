import {
    Alert,
    Avatar,
    Button,
    Checkbox,
    Chip,
    Collapse,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    Skeleton,
    Tab,
    Tabs,
    TextField,
} from "@mui/material";
import Badge, { BadgeProps } from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import SortIcon from "@mui/icons-material/Sort";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import Person2Icon from "@mui/icons-material/Person2";
import FlagIcon from "@mui/icons-material/Flag";
import CloseIcon from "@mui/icons-material/Close";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ReplayIcon from "@mui/icons-material/Replay";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { useAppDispatch, useAppSelector } from "../../../redux/store";
import Tile from "../pices/Tile";
import React, { useEffect, useState } from "react";
import {
    createNotes,
    favoriteApplicant,
    getApplicants,
    getNotes,
    getVacancies,
    removeApplicants,
    removeVacancies,
    setStats,
    updateApplicants,
} from "../../../redux/slices/appSlice";
import EntityModal from "../EntityModal";
import AddVacancy from "../pices/AddVacancy";
import AreYouShure from "../pices/AreYouShure";
import AddApplicant from "../pices/AddAplicant";
import useTo from "../../hooks/useTo";
import { addHint } from "../../../redux/slices/hintsSlice";
import useProcess from "../../hooks/useProcess";
import { sendFeedback } from "../../../redux/slices/feedbackSlice";
import Box from "@mui/material/Box";

export default function ScoringDemo() {
    const to = useTo();
    const [procCount] = useProcess();
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user);
    const vacancies = useAppSelector((state) => state.app).vacancies || [];
    const [selectedVacancyId, setSelectedVacancyId] = useState<string | null>(
        null
    );
    const selectedVacancy = vacancies.find((v) => v._id === selectedVacancyId);
    const [selectedApplicantId, setSelectedApplicantId] = useState<
        string | null
    >(null);
    const selectedApplicant = selectedVacancy?.applicants?.find(
        (a) => a._id === selectedApplicantId
    );
    const [applicantWue, setApplicantWue] = useState<number>(0);

    const [scoringExp, setScoringExp] = useState({
        grade: null,
        text: "",
        sended: false,
    });
    const [sort, setSort] = useState({ applicants: 0, vacancies: 0 });
    const [note, setNote] = useState("");

    useEffect(() => {
        dispatch(getVacancies(null));
    }, [dispatch, user]);

    // select new vacancy automatically
    const [vacanciesCount, setVacanciesCount] = useState<number>(
        vacancies.length
    );
    useEffect(() => {
        if (vacancies.length == vacanciesCount) return;
        if (!(vacancies.length < vacanciesCount || !vacanciesCount)) {
            setSelectedVacancyId(vacancies.slice(-1)[0]?._id);
            setSelectedApplicantId(null);
        }
        setVacanciesCount(vacancies.length);
    });

    const selectVacancyHandler = (vacancy_id) => {
        setSelectedVacancyId(vacancy_id);
        setSelectedApplicantId(null);
        setSort({ applicants: 0, vacancies: 0 });
        dispatch(getApplicants({ vacancy_id }));
    };

    const selectApplicantHandler = (applicant_id) => {
        setSelectedApplicantId(applicant_id);
        const applicant = selectedVacancy?.applicants?.find(
            (a) => a._id === applicant_id
        );
        if (!applicant.notes)
            dispatch(
                getNotes({
                    entity_id: applicant_id,
                    vacancy_id: selectedVacancy._id,
                })
            );
        try {
            setScoringExp({
                grade: null,
                text: "",
                sended: !!JSON.parse(
                    window.localStorage.getItem("ScoringDemo_feedbacks") || "{}"
                )[applicant_id],
            });
        } finally {
        }
    };

    const openPop = (scene) => {
        to(null, { scene });
    };

    const scoringHandlerKeyPress = (e) => {
        if (e.key === "Enter") scoringHandler();
    };
    const scoringHandler = () => {
        if (!(scoringExp.grade && selectedApplicant?._id))
            return dispatch(addHint("please choose your experience grade! "));
        dispatch(
            sendFeedback({
                entity_id: selectedApplicant?._id,
                type: "Applicant",
                resultObj: (({ sended, ...res }) => ({ ...res }))(scoringExp),
            })
        );
        dispatch(
            addHint({ text: "your opinion was sending", type: "success" })
        );
        setScoringExp({ grade: null, text: "", sended: true });
        window.localStorage.setItem(
            "ScoringDemo_feedbacks",
            JSON.stringify({
                ...JSON.parse(
                    window.localStorage.getItem("ScoringDemo_feedbacks") || "{}"
                ),
                [selectedApplicant?._id]: true,
            })
        );
    };

    const flowCodeHandler = (applicant, code) => {
        if (!applicant?._id) return;
        dispatch(
            updateApplicants({
                applicants: [
                    {
                        applicant_id: applicant?._id,
                        applicant: {
                            flow_code:
                                applicant.flow_code === code ? "new" : code,
                        },
                    },
                ],
            })
        );
        dispatch(
            setStats({
                vacancy_id: selectedVacancy._id,
                stats: [
                    ...(selectedVacancy.stats?.filter(
                        (s) => s !== applicant.flow_code
                    ) || []),
                    ...(selectedVacancy.stats
                        ?.filter((s) => s === applicant.flow_code)
                        ?.slice(1) || []),
                    applicant.flow_code === code ? "new" : code,
                ],
            })
        );
    };

    const sendNoteHandlerKeyPress = (e) => {
        if (e.key === "Enter") sendNoteHandler();
    };
    const sendNoteHandler = () => {
        if (selectedApplicant) {
            if (!note)
                dispatch(
                    addHint(
                        "The CV of the candidate has not included any summary."
                    )
                );
            dispatch(
                createNotes({
                    notes: [
                        {
                            entity_id: selectedApplicant._id,
                            collectionName: "Applicant",
                            text: note,
                        },
                    ],
                })
            );
            setNote("");
        }
    };

    const favoriteHandler = (applicant) => {
        if (!applicant?._id) return;
        dispatch(favoriteApplicant(applicant?._id));
    };

    const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
        "& .MuiBadge-badge": {
            left: 0,
            top: 0,
            border: `2px solid ${theme.palette.background.paper}`,
            width: "32px",
            height: "32px",
            borderRadius: "8px",
            background: "#999999",
            color: "white",
        },
    }));

    const applicants = selectedVacancy?.applicants || [];
    const procApplicants = new Array(
        procCount({ subkey: selectedVacancy?._id })
    ).fill(null);
    const procVacancies = new Array(procCount({ subkey: "vacancy" })).fill(
        null
    );

    return (
        <div className="text">
            <Tile
                text={`Hi, ${user?.data?.name || "user"}`}
                subtext="Good to see you again! "
                line={false}
            />

            <Alert severity="info" sx={{ mb: 4, boxShadow: 1 }}>
                <div
                    style={{
                        fontSize: "14px",
                        color: "#333333",
                        paddingBottom: "0.5rem",
                    }}
                >
                    We appreciate your interest in the Talent Popcorn service.
                    This is the demo version designed to introduce you to the
                    candidate scoring capabilities powered by AI.
                </div>
                <div style={{ fontSize: "12px", color: "#333333" }}>
                    Please note that the current version does not showcase the
                    full functionality of our upcoming product and is provided
                    'as is.'
                </div>
            </Alert>

            <Tile sx={{ minHeight: "450px" }}>
                <Grid container spacing={2} sx={{ flexWrap: "wrap" }}>
                    <Grid item xs={3}>
                        <Grid container sx={{ padding: "16px" }}>
                            <Grid
                                item
                                xs={11}
                                sx={{
                                    lineHeight: "32px",
                                    fontSize: "18px",
                                }}
                            >
                                Vacancies{" "}
                                <StyledBadge
                                    badgeContent={vacancies.length || "0"}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton
                                    aria-label="sort"
                                    sx={{ padding: "4px 0" }}
                                    onClick={() =>
                                        setSort((prev) => ({
                                            ...prev,
                                            vacancies:
                                                prev.vacancies > 0 ? -1 : 1,
                                        }))
                                    }
                                >
                                    <SortIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            sx={{
                                background: "#F9F9F9" || "#F3F3F3",
                                padding: "16px",
                                marginTop: "16px",
                            }}
                        >
                            <Grid item xs={12}>
                                <Button
                                    variant="text"
                                    color="inherit"
                                    startIcon={<AddCircleIcon />}
                                    sx={{
                                        textTransform: "none",
                                        fontWeight: 400,
                                    }}
                                    onClick={() => openPop("add-vacancy")}
                                >
                                    Add position
                                </Button>
                            </Grid>
                        </Grid>
                        {vacancies.length === 0 ? (
                            <div style={{ padding: "16px" }}>
                                Nothing here yet
                            </div>
                        ) : (
                            (!!sort.vacancies
                                ? [...vacancies].sort(
                                      (a, b) =>
                                          a.position_name.localeCompare(
                                              b.position_name
                                          ) * sort.vacancies
                                  )
                                : [...vacancies].reverse()
                            ).map((vacancy, i) => (
                                <div
                                    key={vacancy._id}
                                    onClick={() =>
                                        selectVacancyHandler(vacancy._id)
                                    }
                                >
                                    <Grid
                                        container
                                        sx={{
                                            ...{ padding: "16px" },
                                            ...(vacancy._id ===
                                            selectedVacancyId
                                                ? {
                                                      color: "white",
                                                      background: "#666",
                                                  }
                                                : {}),
                                        }}
                                    >
                                        <Grid item xs={11}>
                                            <div style={{ lineHeight: "21px" }}>
                                                {vacancy.position_name}
                                            </div>
                                            <div
                                                className="text-light"
                                                style={{ fontSize: "12px" }}
                                            >
                                                {vacancy.company_name}
                                            </div>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton
                                                aria-label="edit"
                                                sx={{
                                                    padding: "0",
                                                    margin: "4px 0",
                                                }}
                                                onClick={() =>
                                                    openPop("update-vacancy")
                                                }
                                            >
                                                <EditIcon
                                                    sx={{ color: "#DFDFDF" }}
                                                />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div style={{ margin: "16px" }} />
                                        </Grid>
                                        <Grid item xs={2} className="d-flex">
                                            <div style={{ padding: "8px 0" }}>
                                                <Person2Icon
                                                    sx={{ color: "#DFDFDF" }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    lineHeight: "40px",
                                                    fontSize: "10px",
                                                    margin: "0 auto",
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {vacancy.stats?.length || "0"}
                                            </div>
                                        </Grid>
                                        <Grid item xs={2} className="d-flex">
                                            <div style={{ padding: "8px 0" }}>
                                                <FlagIcon
                                                    sx={{ color: "#DFDFDF" }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    lineHeight: "40px",
                                                    fontSize: "10px",
                                                    margin: "0 auto",
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {vacancy.stats?.filter(
                                                    (s) => s === "offer_sent"
                                                ).length || "0"}
                                            </div>
                                        </Grid>
                                        <Grid item xs={7} />
                                        <Grid item xs={1}>
                                            <IconButton
                                                aria-label="close"
                                                sx={{
                                                    padding: "0",
                                                    margin: "8px 0",
                                                }}
                                                onClick={() =>
                                                    openPop("delete-vacancy")
                                                }
                                            >
                                                {/* <CloseIcon sx={{color: '#DFDFDF'}}/> */}
                                                <DeleteForeverIcon
                                                    sx={{ color: "#DFDFDF" }}
                                                />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                </div>
                            ))
                        )}
                    </Grid>

                    {/* ---------------------------------------------------------------------------------------------------------------------------------- */}

                    <Grid item xs={3}>
                        <Grid container sx={{ padding: "16px" }}>
                            <Grid
                                item
                                xs={11}
                                sx={{
                                    lineHeight: "32px",
                                    fontSize: "18px",
                                }}
                            >
                                Applicants{" "}
                                <StyledBadge
                                    badgeContent={
                                        selectedVacancy?.applicants?.length ||
                                        "0"
                                    }
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton
                                    aria-label="sort"
                                    sx={{ padding: "4px 0" }}
                                    onClick={() =>
                                        setSort((prev) => ({
                                            ...prev,
                                            applicants:
                                                prev.applicants < 0 ? 1 : -1,
                                        }))
                                    }
                                >
                                    <SortIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            sx={{
                                background: "#F9F9F9",
                                padding: "16px",
                                marginTop: "16px",
                            }}
                        >
                            <Grid item xs={12}>
                                <Button
                                    disabled={selectedVacancyId === null}
                                    startIcon={<AddCircleIcon />}
                                    variant="text"
                                    color="inherit"
                                    sx={{
                                        textTransform: "none",
                                        fontWeight: 400,
                                    }}
                                    onClick={() => openPop("add-applicant")}
                                >
                                    Add applicant
                                </Button>
                            </Grid>
                        </Grid>
                        {!(applicants?.length + procApplicants?.length) ? (
                            <div style={{ padding: "16px" }}>
                                Nothing here yet
                            </div>
                        ) : (
                            (!!sort.applicants
                                ? [...applicants, ...procApplicants].sort(
                                      (a, b) =>
                                          (a.scoring.general_score -
                                              b.scoring.general_score) *
                                          sort.applicants
                                  )
                                : [...applicants, ...procApplicants]
                            ).map((applicant, i) =>
                                !applicant ? (
                                    <div>
                                        <Grid
                                            container
                                            sx={{
                                                padding: "16px",
                                                color: "#333333",
                                                background: "#F2F2F2",
                                                minHeight: "126px",
                                            }}
                                        >
                                            <Grid item xs={9}>
                                                <div
                                                    style={{
                                                        lineHeight: "21px",
                                                    }}
                                                >
                                                    Candidate scoring...
                                                </div>
                                                <div
                                                    style={{
                                                        fontSize: "12px",
                                                        color: "#666",
                                                    }}
                                                >
                                                    Your data was successfully
                                                    uploaded. Please wait for
                                                    the scoring results.
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={3}
                                                className="d-flex justify-content-end flex-wrap"
                                            >
                                                <Skeleton
                                                    key={i}
                                                    sx={{
                                                        width: "3rem",
                                                        height: "2rem",
                                                        borderRadius: "0.25rem",
                                                    }}
                                                    animation="wave"
                                                    variant="rectangular"
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="d-flex justify-content-end flex-wrap"
                                            >
                                                <Skeleton
                                                    animation="wave"
                                                    variant="circular"
                                                    width={20}
                                                    height={20}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                    </div>
                                ) : (
                                    <div
                                        key={applicant._id}
                                        onClick={() =>
                                            selectApplicantHandler(
                                                applicant._id
                                            )
                                        }
                                    >
                                        <Grid
                                            container
                                            sx={{
                                                ...{
                                                    padding: "16px",
                                                    minHeight: "126px",
                                                },
                                                ...(applicant._id ===
                                                selectedApplicantId
                                                    ? {
                                                          color: "white",
                                                          background: "#3069BD",
                                                      }
                                                    : {}),
                                            }}
                                        >
                                            <Grid item xs={9}>
                                                <div
                                                    style={{
                                                        lineHeight: "21px",
                                                    }}
                                                >
                                                    {
                                                        applicant.resume
                                                            .personal.first_name
                                                    }{" "}
                                                    {
                                                        applicant.resume
                                                            .personal.last_name
                                                    }
                                                </div>
                                                {applicant.resume
                                                    .experience[0] && (
                                                    <>
                                                        <div
                                                            style={{
                                                                ...{
                                                                    fontSize:
                                                                        "12px",
                                                                    color: "#666",
                                                                },
                                                                ...(applicant._id ===
                                                                selectedApplicantId
                                                                    ? {
                                                                          color: "#FFF",
                                                                      }
                                                                    : {}),
                                                            }}
                                                        >
                                                            {
                                                                applicant.resume
                                                                    .experience[0]
                                                                    ?.position
                                                            }{" "}
                                                            {
                                                                applicant.resume
                                                                    .experience[0]
                                                                    ?.company
                                                            }
                                                        </div>
                                                        <div
                                                            className="text-light"
                                                            style={{
                                                                ...{
                                                                    fontSize:
                                                                        "12px",
                                                                },
                                                                ...(applicant._id ===
                                                                selectedApplicantId
                                                                    ? {
                                                                          color: "#FFF",
                                                                      }
                                                                    : {}),
                                                            }}
                                                        >
                                                            {
                                                                applicant.resume
                                                                    ?.location
                                                                    ?.country
                                                            }
                                                            {!applicant.resume
                                                                ?.location?.city
                                                                ? ""
                                                                : " | " +
                                                                  applicant
                                                                      .resume
                                                                      .location
                                                                      .city}
                                                        </div>
                                                    </>
                                                )}
                                            </Grid>
                                            <Grid item xs={3}>
                                                <div
                                                    style={{
                                                        lineHeight: "20px",
                                                        fontSize: "20px",
                                                        fontWeight: 700,
                                                        margin: "2px",
                                                    }}
                                                    className="d-flex justify-content-end"
                                                >
                                                    {
                                                        applicant.scoring
                                                            .general_score
                                                    }
                                                    /10
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="d-flex justify-content-end flex-wrap"
                                            >
                                                <IconButton
                                                    aria-label="applicants"
                                                    sx={{
                                                        padding: "0",
                                                        margin: "8px 0",
                                                    }}
                                                    onClick={() =>
                                                        flowCodeHandler(
                                                            applicant,
                                                            "offer_sent"
                                                        )
                                                    }
                                                >
                                                    <FlagIcon
                                                        sx={{
                                                            color:
                                                                applicant?.flow_code ===
                                                                "offer_sent"
                                                                    ? "#56C939"
                                                                    : applicant?._id ===
                                                                      selectedApplicant?._id
                                                                    ? "#FFF"
                                                                    : "#999999",
                                                        }}
                                                    />
                                                </IconButton>
                                                <IconButton
                                                    aria-label="finished"
                                                    sx={{
                                                        padding: "0",
                                                        margin: "8px",
                                                    }}
                                                    onClick={() =>
                                                        favoriteHandler(
                                                            applicant
                                                        )
                                                    }
                                                >
                                                    {!!applicant?.favorite ? (
                                                        <FavoriteIcon
                                                            sx={{
                                                                color: "#FF0000",
                                                            }}
                                                        />
                                                    ) : (
                                                        <FavoriteBorderIcon
                                                            sx={{
                                                                color:
                                                                    applicant?._id ===
                                                                    selectedApplicant?._id
                                                                        ? "#FFF"
                                                                        : "#999999",
                                                            }}
                                                        />
                                                    )}
                                                </IconButton>
                                                <IconButton
                                                    aria-label="close"
                                                    sx={{
                                                        padding: "0",
                                                        margin: "8px 0",
                                                    }}
                                                    // onClick={()=>openPop('delete-applicant')}
                                                    onClick={() =>
                                                        flowCodeHandler(
                                                            applicant,
                                                            applicant?.flow_code !==
                                                                "declined"
                                                                ? "declined"
                                                                : "new"
                                                        )
                                                    }
                                                >
                                                    {applicant?.flow_code !==
                                                    "declined" ? (
                                                        <CloseIcon
                                                            sx={{
                                                                color:
                                                                    applicant?._id ===
                                                                    selectedApplicant?._id
                                                                        ? "#FFF"
                                                                        : "#999999",
                                                            }}
                                                        />
                                                    ) : (
                                                        <ReplayIcon
                                                            sx={{
                                                                color:
                                                                    applicant?._id ===
                                                                    selectedApplicant?._id
                                                                        ? "#FFF"
                                                                        : "#999999",
                                                            }}
                                                        />
                                                    )}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                    </div>
                                )
                            )
                        )}
                    </Grid>

                    {/* -------------------------------------------------------------------------------------------------------------------------------------- */}

                    <Grid item xs={6}>
                        <Grid container sx={{ padding: "12px 16px" }}>
                            <Grid item xs={12}>
                                {!selectedApplicant ? (
                                    <div
                                        style={{
                                            fontSize: "18px",
                                            lineHeight: "40px",
                                        }}
                                    >
                                        Applicant not selected
                                    </div>
                                ) : (
                                    <div style={{ height: "40px" }}>
                                        <div
                                            style={{
                                                fontSize: "18px",
                                                lineHeight: "22px",
                                                fontWeight: 600,
                                            }}
                                        >
                                            {`${selectedApplicant?.resume?.personal?.first_name} ${selectedApplicant?.resume?.personal?.last_name}`}
                                        </div>
                                        <div
                                            className="text-light"
                                            style={{
                                                fontSize: "14px",
                                                lineHeight: "18px",
                                            }}
                                        >
                                            {`${selectedApplicant?.resume?.experience?.[0]?.position} in ${selectedApplicant?.resume?.experience?.[0]?.company}`}
                                        </div>
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                        <div
                            className="d-flex align-items-center"
                            style={{
                                background: "#F9F9F9",
                                padding: "16px",
                                marginTop: "16px",
                            }}
                        >
                            <div
                                className="d-flex flex-wrap"
                                onClick={() =>
                                    flowCodeHandler(
                                        selectedApplicant,
                                        selectedApplicant?.flow_code !==
                                            "declined"
                                            ? "declined"
                                            : "new"
                                    )
                                }
                            >
                                {selectedApplicant?.flow_code !== "declined" ? (
                                    <Button
                                        disabled={!selectedApplicant}
                                        startIcon={<CloseIcon />}
                                        variant="text"
                                        color="inherit"
                                        sx={{
                                            textTransform: "none",
                                            fontWeight: 400,
                                            width: "90.52px",
                                        }}
                                    >
                                        Decline
                                    </Button>
                                ) : (
                                    <Button
                                        disabled={!selectedApplicant}
                                        startIcon={<ReplayIcon />}
                                        variant="text"
                                        color="inherit"
                                        sx={{
                                            textTransform: "none",
                                            fontWeight: 400,
                                            width: "90.52px",
                                        }}
                                    >
                                        Restore
                                    </Button>
                                )}
                            </div>
                            <div
                                className="d-flex flex-wrap"
                                onClick={() =>
                                    favoriteHandler(selectedApplicant)
                                }
                            >
                                <Button
                                    disabled={!selectedApplicant}
                                    startIcon={
                                        selectedApplicant?.favorite ? (
                                            <FavoriteIcon
                                                sx={{ color: "#FF0000" }}
                                            />
                                        ) : (
                                            <FavoriteBorderIcon />
                                        )
                                    }
                                    variant="text"
                                    color="inherit"
                                    sx={{
                                        textTransform: "none",
                                        fontWeight: 400,
                                    }}
                                >
                                    Favorite
                                </Button>
                            </div>
                            <div
                                className="d-flex flex-wrap"
                                onClick={() =>
                                    flowCodeHandler(
                                        selectedApplicant,
                                        "offer_sent"
                                    )
                                }
                            >
                                <Button
                                    disabled={!selectedApplicant}
                                    startIcon={
                                        <FlagIcon
                                            sx={{
                                                color:
                                                    selectedApplicant?.flow_code ===
                                                    "offer_sent"
                                                        ? "#56C939"
                                                        : "inherit",
                                            }}
                                        />
                                    }
                                    variant="text"
                                    color="inherit"
                                    sx={{
                                        textTransform: "none",
                                        fontWeight: 400,
                                    }}
                                >
                                    Finalist
                                </Button>
                            </div>
                        </div>
                        {!selectedApplicant ? (
                            <div style={{ padding: "16px" }}>
                                Select Applicant to see info
                            </div>
                        ) : (
                            <div>
                                <div style={{ padding: "32px 16px" }}>
                                    <Tabs
                                        value={applicantWue}
                                        onChange={(_, newValue: number) => {
                                            setApplicantWue(newValue);
                                        }}
                                    >
                                        <Tab
                                            label="Application"
                                            sx={{ textTransform: "none" }}
                                        />
                                        <Tab
                                            label="Experience"
                                            sx={{ textTransform: "none" }}
                                        />
                                    </Tabs>
                                    <Divider sx={{ my: 0.5, margin: "0" }} />
                                </div>
                                {applicantWue === 0 && (
                                    <>
                                        <Grid
                                            container
                                            wrap="wrap"
                                            sx={{
                                                padding: "16px",
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                            }}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{ fontWeight: 700 }}
                                            >
                                                <div
                                                    style={{
                                                        marginBottom: "6px",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    Score
                                                </div>
                                                <div
                                                    style={{
                                                        fontSize: "24px",
                                                        fontFamily: "Roboto",
                                                    }}
                                                >
                                                    {`${selectedApplicant?.scoring?.general_score}/10`}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider
                                                    sx={{
                                                        my: 0.5,
                                                        margin: "16px 0",
                                                    }}
                                                />
                                            </Grid>
                                            {/* <Grid item xs={3} sx={{color: '#333'}}>
                                            Experience: 
                                            <b style={{marginLeft: '8px', fontFamily: 'Roboto'}}>
                                                {applicant?.scoring?.experience_score}/10
                                            </b>
                                        </Grid>
                                        <Grid item xs={7} sx={{color: '#333'}}>
                                            Skills Score: 
                                            <b style={{marginLeft: '8px', fontFamily: 'Roboto'}}>
                                                {applicant?.scoring?.skills_score}/10
                                            </b>
                                        </Grid> */}
                                            <div className="d-flex">
                                                <div
                                                    style={{
                                                        color: "#333",
                                                        marginRight: "32px",
                                                    }}
                                                >
                                                    Experience:
                                                    <b
                                                        style={{
                                                            marginLeft: "8px",
                                                            fontFamily:
                                                                "Roboto",
                                                        }}
                                                    >
                                                        {
                                                            selectedApplicant
                                                                ?.scoring
                                                                ?.experience_score
                                                        }
                                                        /10
                                                    </b>
                                                </div>
                                                <div style={{ color: "#333" }}>
                                                    Skills Score:
                                                    <b
                                                        style={{
                                                            marginLeft: "8px",
                                                            fontFamily:
                                                                "Roboto",
                                                        }}
                                                    >
                                                        {
                                                            selectedApplicant
                                                                ?.scoring
                                                                ?.skills_score
                                                        }
                                                        /10
                                                    </b>
                                                </div>
                                            </div>
                                            <Grid item xs={12}>
                                                <Divider
                                                    sx={{
                                                        my: 0.5,
                                                        margin: "16px 0",
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                Explanation:
                                                <p>
                                                    {
                                                        selectedApplicant
                                                            ?.scoring
                                                            ?.explanation
                                                    }
                                                </p>
                                            </Grid>
                                        </Grid>
                                        {!scoringExp.sended ? (
                                            <Grid
                                                container
                                                sx={{
                                                    background: "#DFDFDF",
                                                    padding: "16px",
                                                    fontFamily: "Inter",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{ marginBottom: "4px" }}
                                                >
                                                    <b>
                                                        How was your scoring
                                                        experience?
                                                    </b>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={1}
                                                    sx={{ fontSize: "24px" }}
                                                >
                                                    <Checkbox
                                                        icon={<div>😡</div>}
                                                        checkedIcon={
                                                            <div>😡</div>
                                                        }
                                                        checked={
                                                            scoringExp.grade ===
                                                            1
                                                        }
                                                        onClick={() =>
                                                            setScoringExp(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    grade: 1,
                                                                })
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={1}
                                                    sx={{ fontSize: "24px" }}
                                                >
                                                    <Checkbox
                                                        icon={<div>😕</div>}
                                                        checkedIcon={
                                                            <div>😕</div>
                                                        }
                                                        checked={
                                                            scoringExp.grade ===
                                                            2
                                                        }
                                                        onClick={() =>
                                                            setScoringExp(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    grade: 2,
                                                                })
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={1}
                                                    sx={{ fontSize: "24px" }}
                                                >
                                                    <Checkbox
                                                        icon={<div>😐</div>}
                                                        checkedIcon={
                                                            <div>😐</div>
                                                        }
                                                        checked={
                                                            scoringExp.grade ===
                                                            3
                                                        }
                                                        onClick={() =>
                                                            setScoringExp(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    grade: 3,
                                                                })
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={1}
                                                    sx={{ fontSize: "24px" }}
                                                >
                                                    <Checkbox
                                                        icon={<div>🙂</div>}
                                                        checkedIcon={
                                                            <div>🙂</div>
                                                        }
                                                        checked={
                                                            scoringExp.grade ===
                                                            4
                                                        }
                                                        onClick={() =>
                                                            setScoringExp(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    grade: 4,
                                                                })
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={1}
                                                    sx={{ fontSize: "24px" }}
                                                >
                                                    <Checkbox
                                                        icon={<div>😀</div>}
                                                        checkedIcon={
                                                            <div>😀</div>
                                                        }
                                                        checked={
                                                            scoringExp.grade ===
                                                            5
                                                        }
                                                        onClick={() =>
                                                            setScoringExp(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    grade: 5,
                                                                })
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    className="d-flex"
                                                    item
                                                    xs={12}
                                                    sx={{ marginTop: "16px" }}
                                                >
                                                    <FormControl
                                                        variant="outlined"
                                                        fullWidth
                                                    >
                                                        <Input
                                                            id="scoring-input-id"
                                                            type="text"
                                                            placeholder="Want to tell more?"
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        disabled={
                                                                            !scoringExp.grade
                                                                        }
                                                                        aria-label="send"
                                                                        onClick={
                                                                            scoringHandler
                                                                        }
                                                                    >
                                                                        <SendOutlinedIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            aria-describedby="scoringHelperTextId"
                                                            value={
                                                                scoringExp.text
                                                            }
                                                            onChange={(e) =>
                                                                setScoringExp(
                                                                    (prev) => ({
                                                                        ...prev,
                                                                        text: e
                                                                            .target
                                                                            .value,
                                                                    })
                                                                )
                                                            }
                                                            onKeyDown={
                                                                scoringHandlerKeyPress
                                                            }
                                                        />
                                                        <FormHelperText
                                                            id="scoringHelperTextId"
                                                            sx={{
                                                                margin: "4px 0",
                                                            }}
                                                        >
                                                            Your opinion is very
                                                            important for us
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid
                                                container
                                                sx={{
                                                    background: "#DFDFDF",
                                                    padding: "16px",
                                                    fontFamily: "Inter",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{
                                                        marginBottom: "16px",
                                                    }}
                                                >
                                                    <b>
                                                        Thank you for the
                                                        feedback!
                                                    </b>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{
                                                        textDecorationLine:
                                                            "underline",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        setScoringExp(
                                                            (prev) => ({
                                                                ...prev,
                                                                sended: false,
                                                            })
                                                        )
                                                    }
                                                >
                                                    Change your feedback
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid
                                            container
                                            sx={{
                                                padding: "16px",
                                                marginTop: "16px",
                                                lineHeight: "24px",
                                            }}
                                        >
                                            <Grid
                                                item
                                                xs={6}
                                                sx={{ marginBottom: "16px" }}
                                            >
                                                <b>Contacts</b>
                                                <div
                                                    className="d-flex wrap"
                                                    style={{
                                                        marginTop: "16px",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: "20%",
                                                            minWidth: "60px",
                                                        }}
                                                    >
                                                        Email:
                                                    </div>
                                                    <div
                                                        className="text-light"
                                                        style={{ width: "70%" }}
                                                    >
                                                        {selectedApplicant?.resume?.emails?.join(
                                                            ", "
                                                        )}
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: "20%",
                                                            minWidth: "60px",
                                                        }}
                                                    >
                                                        Phone:
                                                    </div>
                                                    <div
                                                        className="text-light"
                                                        style={{ width: "70%" }}
                                                    >
                                                        {selectedApplicant?.resume?.phones?.join(
                                                            ", "
                                                        )}
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <b>Personal</b>
                                                <div
                                                    className="d-flex wrap"
                                                    style={{
                                                        marginTop: "16px",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: "30%",
                                                            minWidth: "70px",
                                                        }}
                                                    >
                                                        Age:
                                                    </div>
                                                    <div
                                                        className="text-light"
                                                        style={{ width: "70%" }}
                                                    >
                                                        {
                                                            selectedApplicant
                                                                ?.resume
                                                                ?.personal?.age
                                                        }
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: "30%",
                                                            minWidth: "70px",
                                                        }}
                                                    >
                                                        Location:
                                                    </div>
                                                    <div
                                                        className="text-light"
                                                        style={{ width: "70%" }}
                                                    >
                                                        {`${selectedApplicant?.resume?.location?.city}, ${selectedApplicant?.resume?.location?.country}`}
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: "30%",
                                                            minWidth: "70px",
                                                        }}
                                                    >
                                                        Education:
                                                    </div>
                                                    <div
                                                        className="text-light"
                                                        style={{ width: "70%" }}
                                                    >
                                                        {selectedApplicant
                                                            ?.resume
                                                            ?.education[0]
                                                            ?.institution || ""}
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: "30%",
                                                            minWidth: "70px",
                                                        }}
                                                    >
                                                        Lenguages:
                                                    </div>
                                                    <div
                                                        className="text-light"
                                                        style={{ width: "70%" }}
                                                    >
                                                        {selectedApplicant?.resume?.languages
                                                            ?.map(
                                                                (l) =>
                                                                    l.language
                                                            )
                                                            .join(", ")}
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{ margin: "16px 0" }}
                                            >
                                                <b>Summary</b>
                                                <div
                                                    className="text-light"
                                                    style={{
                                                        marginTop: "16px",
                                                    }}
                                                >
                                                    {selectedApplicant?.resume
                                                        ?.summary ||
                                                        "The CV of the candidate has not included any summary."}
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{ marginTop: "16px" }}
                                            >
                                                <b>Skills</b>
                                                <div
                                                    className="d-flex wrap"
                                                    style={{
                                                        marginTop: "12px",
                                                    }}
                                                >
                                                    {(
                                                        selectedApplicant
                                                            ?.resume?.skills ||
                                                        []
                                                    ).map((s: string, i) => (
                                                        <div key={s + i}>
                                                            <Chip
                                                                label={s}
                                                                sx={{
                                                                    margin: "4px",
                                                                }}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                {applicantWue === 1 && (
                                    <>
                                        {(
                                            selectedApplicant?.resume
                                                ?.experience || []
                                        ).map((ex, i) => (
                                            <div
                                                key={JSON.stringify(ex) + i}
                                                style={{
                                                    padding: "16px",
                                                    fontSize: "16px",
                                                }}
                                            >
                                                <b>{ex.position}</b>
                                                <div className="text-light">
                                                    {ex.company}, {ex.location}
                                                </div>
                                                <br />
                                                <div>
                                                    {ex.start_date} -{" "}
                                                    {ex.end_date}
                                                </div>
                                                <br />
                                                <div>{ex.description}</div>
                                                <Divider
                                                    sx={{
                                                        my: 0.5,
                                                        marginTop: "32px",
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </>
                                )}
                                <div
                                    style={{
                                        padding: "0 16px",
                                        fontSize: "14px",
                                    }}
                                >
                                    <b>Notes</b>
                                    {(selectedApplicant?.notes || []).map(
                                        (note) => (
                                            <Grid
                                                container
                                                style={{ padding: "1rem 0" }}
                                                key={note._id}
                                            >
                                                <Grid
                                                    item
                                                    xs={"auto"}
                                                    style={{
                                                        padding: "0 0 1rem 0",
                                                    }}
                                                >
                                                    <Avatar
                                                        alt={
                                                            (user &&
                                                                user?.data
                                                                    ?.name[0] +
                                                                    user?.data
                                                                        ?.surname[0]) ||
                                                            "User"
                                                        }
                                                        src="/static/images/avatar/2.jpg"
                                                    />
                                                    {/* TODO Пересмотреть divider, он странно расширяется по вертикали */}
                                                    <Divider
                                                        orientation="vertical"
                                                        style={{
                                                            marginTop: "1rem",
                                                            marginRight: "49%",
                                                            height: "60%",
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs
                                                    style={{
                                                        padding:
                                                            "0.5rem 0 0 1rem",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            padding:
                                                                "0 0 1rem 0",
                                                            fontSize: "12px",
                                                            fontWeight: "700",
                                                        }}
                                                    >
                                                        {`${
                                                            user?.data?.name ||
                                                            "user"
                                                        }`}
                                                    </div>
                                                    <div
                                                        style={{
                                                            padding:
                                                                "0 0 1rem 0",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {`${note.text}`}
                                                    </div>
                                                    {/* TODO Вывод времени */}
                                                    <div
                                                        style={{
                                                            fontSize: "12px",
                                                        }}
                                                    >
                                                        Today 11:40
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        )
                                    )}

                                    <div
                                        className="d-flex justify-content-start align-items-end"
                                        style={{
                                            background: "#F9F9F9",
                                            borderRadius: "8px",
                                            padding: "2px",
                                        }}
                                    >
                                        <TextField
                                            multiline
                                            size="small"
                                            sx={{
                                                width: "90%",
                                                border: "none",
                                                "& fieldset": {
                                                    border: "none",
                                                },
                                            }}
                                            value={note || ""}
                                            onChange={(e) =>
                                                setNote(e.target.value)
                                            }
                                            onKeyDown={sendNoteHandlerKeyPress}
                                        />
                                        <IconButton
                                            aria-label="send"
                                            onClick={sendNoteHandler}
                                        >
                                            <SendOutlinedIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </Tile>

            <EntityModal scene="add-vacancy" top="64px" right="0" dark>
                <AddVacancy />
            </EntityModal>
            <EntityModal scene="update-vacancy" top="64px" right="0" dark>
                <AddVacancy vacancy={selectedVacancy} />
            </EntityModal>
            <EntityModal scene="add-applicant" top="64px" right="0" dark>
                <AddApplicant vacancy_id={selectedVacancy?._id} />
            </EntityModal>
            <EntityModal scene="delete-vacancy" top="64px" right="0" dark>
                <AreYouShure
                    handler={() => {
                        if (selectedVacancy?._id)
                            dispatch(removeVacancies([selectedVacancy._id]));
                    }}
                    header="Delete  vacancy"
                    text="Are you sure you what to delete the vacancy?"
                    btn="Delete"
                />
            </EntityModal>
            <EntityModal scene="delete-applicant" top="64px" right="0" dark>
                <AreYouShure
                    handler={() => {
                        if (selectedApplicant?._id)
                            dispatch(
                                removeApplicants({
                                    vacancy_id: selectedVacancy._id,
                                    ids: [selectedApplicant._id],
                                })
                            );
                    }}
                    header="Delete  applicant"
                    text="Are you sure you what to delete the applicant?"
                    btn="Delete"
                />
            </EntityModal>
        </div>
    );
}
