import {Box, Button, Chip, Divider, Grid, IconButton, Skeleton, Tab, Tabs,} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../redux/store';
import {
  favoriteApplicant,
  getApplicants,
  getNotes,
  getVacancies,
  setStats,
  updateApplicants,
  updateScoring,
} from '../../../redux/slices/appSlice';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FlagIcon from '@mui/icons-material/Flag';
import useTo from '../../hooks/useTo';
import Notes from './Notes';
import {addHint} from '../../../redux/slices/hintsSlice';
import {sendFeedback} from '../../../redux/slices/feedbackSlice';
import {GradeSlider} from './GradeSlider';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={
            {
              // p: 3,
            }
          }
        >
          {children}
        </Box>
      )}
    </div>
  );
}

export default function ViewApplication({
  close = () => {},
}: {
  close?: Function;
}) {
  const to = useTo();
  const dispatch = useAppDispatch();
  const [view, setView] = useState(0);
  const vacancy_id = Object.fromEntries(
    new URLSearchParams(window.location.search)
  )?.vacancy_id;
  const applicant_id = Object.fromEntries(
    new URLSearchParams(window.location.search)
  )?.applicant_id;
  const storageLoading = `isLoading_${applicant_id}`;
  const prevApplicant = `prevApplicant_${applicant_id}`;
  const [isLoading, setIsLoading] = useState(() => {
    const value = window.localStorage.getItem(storageLoading);
    return value === 'true';
  });
  const currentUrl = window.location.href;

  const handleCansel = () => {
    if (currentUrl.includes('dashboard')) {
      to('/dashboard');
    } else {
      to('/vacancy', { vacancy_id });
    }
  };

  const vacancies = useAppSelector((state) => state.app).vacancies || [];
  useEffect(() => {
    if (!vacancies.length) dispatch(getVacancies(null));
  }, [dispatch]);

  let vacancy = (vacancies || []).find((v) => v._id === vacancy_id);
  let applicant = (vacancy?.applicants || []).find(
    (a) => a._id === applicant_id
  );

  useEffect(() => {
    if (!applicant_id) to('/dashboard');
  }, [applicant, applicant_id]);

  useEffect(() => {
    if (applicant && !applicant.notes) {
      dispatch(getNotes({ entity_id: applicant._id, vacancy_id: vacancy._id }));
    }
  }, [applicant]);

  useEffect(() => {
    if (vacancy_id) {
      dispatch(getApplicants({ vacancy_id }));
    }
  }, []);

  const veiwTabHandler = (event: React.SyntheticEvent, newValue: number) => {
    setView(newValue);
  };

  const flowCodeHandler = (applicant, code) => {
    if (!applicant?._id) return;
    dispatch(
      updateApplicants({
        applicants: [
          {
            applicant_id: applicant?._id,
            applicant: {
              flow_code: applicant.flow_code === code ? 'new' : code,
            },
          },
        ],
      })
    );
    dispatch(
      setStats({
        vacancy_id: vacancy._id,
        stats: [
          ...(vacancy.stats?.filter((s) => s !== applicant.flow_code) || []),
          ...(vacancy.stats
            ?.filter((s) => s === applicant.flow_code)
            ?.slice(1) || []),
          applicant.flow_code === code ? 'new' : code,
        ],
      })
    );
  };

  const scoringHandler = (scoringExp: {
    grade: number;
    text: string;
    sended: boolean;
  }) => {
    if (!applicant?._id)
      return dispatch(addHint('please choose your experience grade! '));
    dispatch(
      sendFeedback({
        entity_id: applicant?._id,
        type: 'Applicant',
        resultObj: (({ sended, ...res }) => ({ ...res }))(scoringExp),
        vacancy_id,
      })
    );
    dispatch(addHint({ text: 'your opinion was sending', type: 'success' }));
  };

  const favoriteHandler = (applicant) => {
    if (!applicant?._id) return;
    dispatch(favoriteApplicant(applicant?._id));
  };

  const handleScoring = async () => {
    window.localStorage.setItem(
      prevApplicant,
      JSON.stringify(applicant?.scoring)
    );
    window.localStorage.setItem(storageLoading, 'true');
    setIsLoading(true);
    await dispatch(
      updateScoring({
        resume_id: applicant?.resume._id,
        vacancy_id: vacancy._id,
      })
    );

    await dispatch(getApplicants({ vacancy_id }));

    window.localStorage.removeItem(storageLoading);
    window.localStorage.removeItem(prevApplicant);
    setIsLoading(false);
  };

  // Чтобы убрать loader
  useEffect(() => {
    const prevApplicantString = window.localStorage.getItem(prevApplicant);
    if (prevApplicantString) {
      const newApp = JSON.stringify(applicant?.scoring);

      if (prevApplicantString !== newApp) {
        window.localStorage.removeItem(prevApplicant);
        window.localStorage.removeItem(storageLoading);
        setIsLoading(false);
      }
    }
  }, [applicant]);

  return (
    <Box
      sx={{
        minWidth: '350px',
        maxWidth: '100%',
        maxHeight: '90vh',
      }}
    >
      {/*popup header */}

      <Box
        sx={{
          // borderBottom: 1,
          // borderColor: "divider",
          background: 'white',
          width: 'inherit',
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{ p: 4 }}
        >
          <Grid item>
            <h3 style={{ padding: 0, margin: 0 }}>
              {applicant?.resume.personal.first_name}{' '}
              {applicant?.resume.personal.last_name}
            </h3>
            <span style={{ fontSize: '12px', color: '#999999' }}>
              {applicant?.resume.experience[0]?.position}{' '}
              {applicant?.resume.experience[0]?.company}
            </span>
          </Grid>

          <Grid item>
            <CancelIcon
              style={{
                color: '#999999',
                cursor: 'pointer',
                marginLeft: '1rem',
              }}
              onClick={handleCansel}
            />
          </Grid>
        </Grid>

        {/*popup buttons*/}

        <div
          className="d-flex align-items-center"
          style={{ background: '#F9F9F9', padding: '1rem 2rem' }}
        >
          <div
            className="d-flex flex-wrap"
            onClick={() =>
              flowCodeHandler(
                applicant,
                applicant?.flow_code !== 'declined' ? 'declined' : 'new'
              )
            }
          >
            {applicant?.flow_code !== 'declined' ? (
              <Button
                disabled={!applicant}
                startIcon={<CloseIcon />}
                variant="text"
                color="inherit"
                sx={{
                  textTransform: 'none',
                  fontWeight: 400,
                  width: '90.52px',
                }}
              >
                Decline
              </Button>
            ) : (
              <Button
                disabled={!applicant}
                startIcon={<ReplayIcon />}
                variant="text"
                color="inherit"
                sx={{
                  textTransform: 'none',
                  fontWeight: 400,
                  width: '90.52px',
                }}
              >
                Restore
              </Button>
            )}
          </div>
          <div
            className="d-flex flex-wrap"
            onClick={() => favoriteHandler(applicant)}
          >
            <Button
              disabled={!applicant}
              startIcon={
                applicant?.favorite ? (
                  <FavoriteIcon sx={{ color: '#FF0000' }} />
                ) : (
                  <FavoriteBorderIcon />
                )
              }
              variant="text"
              color="inherit"
              sx={{ textTransform: 'none', fontWeight: 400 }}
            >
              Favorite
            </Button>
          </div>
          <div
            className="d-flex flex-wrap"
            onClick={() => flowCodeHandler(applicant, 'offer_sent')}
          >
            <Button
              disabled={!applicant}
              startIcon={
                <FlagIcon
                  sx={{
                    color:
                      applicant?.flow_code === 'offer_sent'
                        ? '#56C939'
                        : 'inherit',
                  }}
                />
              }
              variant="text"
              color="inherit"
              sx={{ textTransform: 'none', fontWeight: 400 }}
            >
              Finalist
            </Button>
          </div>
          <div
            className="d-flex flex-wrap"
            onClick={() => alert('Message_181')}
          >
            {/*<Button
                disabled={!applicant}
                startIcon={
                  <Email
                  // sx={{color: applicant?.flow_code === 'finalist' ? '#56C939' : 'inherit'}}
                  />
                }
                variant="text"
                color="inherit"
                sx={{ textTransform: 'none', fontWeight: 400 }}
              >
                Message
              </Button>*/}
          </div>
        </div>
      </Box>

      <Box
        sx={{
          overflowY: 'auto',
          height: 'calc(100vh - 250px)',
        }}
      >
        {/* popup tab nav */}

        <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 4 }}>
          <Tabs
            value={view}
            onChange={veiwTabHandler}
            aria-label="basic tabs example"
          >
            <Tab
              style={{ textTransform: 'capitalize' }}
              label="Application"
              id={`tab-0`}
              aria-controls={`tab-0`}
            />
            <Tab
              style={{ textTransform: 'capitalize' }}
              label="Profile"
              id={`tab-1`}
              aria-controls={`tab-1`}
            />
            <Tab
              style={{ textTransform: 'capitalize' }}
              label="History"
              id={`tab-2`}
              aria-controls={`tab-2`}
            />
          </Tabs>
        </Box>

        {/* popup tabs */}

        {/* tab 1 Application */}

        <CustomTabPanel value={view} index={0}>
          {/* scores */}

          <Box sx={{ p: '1.5rem 2rem' }}>
            {isLoading ? (
              <div>
                {/*<Skeleton variant="rounded" animation="wave" height={256} />*/}
                <Skeleton
                  variant="text"
                  sx={{ fontSize: '1rem' }}
                  width={128}
                />
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: '1rem' }}
                      width={128}
                    />
                    <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                  </Grid>
                  <Grid item xs={6}>
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: '1rem' }}
                      width={128}
                    />
                    <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                  </Grid>
                </Grid>
                <Skeleton
                  variant="text"
                  sx={{ fontSize: '1rem', paddingTop: '40px' }}
                  height={128}
                />
                <div
                  style={{
                    lineHeight: '21px',
                    textAlign: 'right',
                    width: '100%',
                  }}
                >
                  Candidate scoring...
                </div>
              </div>
            ) : (
              <div>
                <Grid container>
                  <GradeSlider
                    xs={12}
                    style={{
                      marginBottom: '0.5rem',
                      fontSize: '1rem',
                    }}
                    label="Score"
                    grade={applicant?.scoring?.general_score}
                    vacancy_id={vacancy_id}
                    applicant_id={applicant?._id}
                    scoring={applicant?.scoring}
                    propertyName="general_score"
                  />
                  <GradeSlider
                    xs={5.5}
                    style={{
                      marginBottom: '1.5rem 0.5rem',
                      fontSize: '1rem',
                    }}
                    label="Experience"
                    grade={applicant?.scoring?.experience_score}
                    vacancy_id={vacancy_id}
                    applicant_id={applicant?._id}
                    scoring={applicant?.scoring}
                    propertyName="experience_score"
                  />
                  <Grid item xs={1} sx={{ fontWeight: 700 }}></Grid>
                  <GradeSlider
                    xs={5.5}
                    style={{
                      marginBottom: '1.5rem 0.5rem',
                      fontSize: '1rem',
                    }}
                    label="Skills"
                    grade={applicant?.scoring?.skills_score}
                    vacancy_id={vacancy_id}
                    applicant_id={applicant?._id}
                    scoring={applicant?.scoring}
                    propertyName="skills_score"
                  />
                  {/*<Grid item xs={12}>
                    <Divider sx={{ my: 0.5, margin: '16px 0' }} />
                  </Grid>*/}
                  {/*<div className="d-flex">
                            <div style={{color: '#333', marginRight: '32px'}}>
                                Experience:
                                <b style={{marginLeft: '8px', fontFamily: 'Roboto'}}>
                                    {applicant?.scoring?.experience_score || 0}/10
                                </b>
                            </div>
                            <div style={{color: '#333'}}>
                                Skills Score:
                                <b style={{marginLeft: '8px', fontFamily: 'Roboto'}}>
                                    {applicant?.scoring?.skills_score || 0}/10
                                </b>
                            </div>
                        </div>*/}
                  <Grid item xs={12} sx={{ lineHeight: '24px', whiteSpace: 'pre-wrap' }}>
                    <p>{`${applicant?.scoring?.explanation}`}</p>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{
                    lineHeight: '24px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <IconButton
                    onClick={() =>
                      scoringHandler({ grade: 5, text: '', sended: true })
                    }
                    disabled={applicant?.grade === '5'}
                  >
                    {applicant?.grade === '5' ? (
                      <ThumbUpAltIcon color="primary" />
                    ) : (
                      <ThumbUpOutlinedIcon style={{ color: '#999999' }} />
                    )}
                  </IconButton>

                  <IconButton
                    onClick={() =>
                      scoringHandler({ grade: 1, text: '', sended: true })
                    }
                    disabled={applicant?.grade === '1'}
                  >
                    {applicant?.grade === '1' ? (
                      <ThumbDownAltIcon color="primary" />
                    ) : (
                      <ThumbDownOutlinedIcon style={{ color: '#999999' }} />
                    )}
                  </IconButton>

                  {/*<Button
              variant="text"
              sx={{ minWidth: 'inherit' }}
              startIcon={
              applicant?.grade === '1' ? (
              <ThumbDownAltIcon color="primary" />
              ) : (
              <ThumbDownOutlinedIcon style={{ color: '#999999' }} />
              )
            }
              onClick={() =>
              scoringHandler({
              grade: 1,
              text: '',
              sended: true,
            })
            }
              disabled={applicant?.grade === '1'}
              />*/}

                  <IconButton
                    onClick={() => handleScoring()}
                    disabled={isLoading}
                  >
                    <RefreshOutlinedIcon style={{ color: '#999999' }} />
                  </IconButton>

                  {/*<Button
              startIcon={}
              onClick={() => handleScoring()}
              disabled={loading}
              />*/}
                </Grid>
              </div>
            )}

            <Grid item xs={12} sx={{ marginTop: '16px' }}>
              <b>Skills</b>
              <Divider sx={{ margin: '8px 0' }} />
              <div className="d-flex wrap" style={{ marginTop: '12px' }}>
                {(applicant?.resume?.skills || []).map((s: string, i) => (
                  <div key={s + i}>
                    <Chip label={s} sx={{ margin: '4px 4px 4px 0' }} />
                  </div>
                ))}
              </div>
            </Grid>

            <Notes
              name="Applicant"
              entity_id={applicant?._id}
              notes={applicant?.notes || []}
            />
          </Box>
        </CustomTabPanel>

        {/* tab 2 Experience */}

        <CustomTabPanel value={view} index={1}>
          <Box sx={{ p: '1.5rem 2rem' }}>
            <Grid container sx={{ lineHeight: '24px' }} spacing={2}>
              {/*contacts*/}

              <Grid item xs={6} sx={{}}>
                <b>Contacts</b>
                <Divider sx={{ margin: '8px 0' }} />

                <div className="d-flex wrap" style={{ marginTop: '16px' }}>
                  <div
                    style={{
                      width: '20%',
                      minWidth: '60px',
                    }}
                  >
                    Email:
                  </div>
                  <div className="text-light" style={{ width: '70%' }}>
                    {applicant?.resume?.emails?.join(', ')}
                  </div>
                  <div
                    style={{
                      width: '20%',
                      minWidth: '60px',
                    }}
                  >
                    Phone:
                  </div>
                  <div className="text-light" style={{ width: '70%' }}>
                    {applicant?.resume?.phones?.join(', ')}
                  </div>

                  {/*<div
                      style={{
                        width: '20%',
                        minWidth: '60px',
                      }}
                  >
                    Social:
                  </div>
                  <div className="text-light" style={{ width: '70%' }}>
                    {applicant?.resume?.social?.join(', ')}
                  </div>*/}
                </div>

                <Grid container>
                  <Grid item style={{ width: '20%', minWidth: '60px' }}>
                    Social:
                  </Grid>
                  <Grid item xs className="text-light" style={{ width: '70%' }}>
                    {applicant?.resume?.social?.length > 0 ? (
                      applicant.resume.social.map((socialLink, index) => (
                        <div
                          style={{ display: 'inline' }}
                          key={socialLink._id?.$oid || index}
                        >
                          <a
                              href={`${socialLink.url.startsWith('https://') ? '' : 'https://'}${socialLink.url}`}
                              target="_blank"
                              rel="noopener noreferrer"
                          >
                            {socialLink.name}
                          </a>
                          ,&nbsp;
                        </div>
                      ))
                    ) : (
                      <div>Not available</div>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/*personal*/}

              <Grid item xs={6} sx={{}}>
                <b>Personal</b>
                <Divider sx={{ margin: '8px 0' }} />
                <div className="d-flex wrap" style={{ marginTop: '16px' }}>
                  <div
                    style={{
                      width: '30%',
                      minWidth: '70px',
                    }}
                  >
                    Age:
                  </div>
                  <div className="text-light" style={{ width: '70%' }}>
                    {applicant?.resume?.personal?.age}
                  </div>
                  <div
                    style={{
                      width: '30%',
                      minWidth: '70px',
                    }}
                  >
                    Location:
                  </div>
                  <div className="text-light" style={{ width: '70%' }}>
                    {`${applicant?.resume?.location?.city}, ${applicant?.resume?.location?.country}`}
                  </div>
                  <div
                    style={{
                      width: '30%',
                      minWidth: '70px',
                    }}
                  >
                    Education:
                  </div>
                  <div className="text-light" style={{ width: '70%' }}>
                    {(Array.isArray(applicant?.resume?.education) &&
                      applicant?.resume?.education[0]?.institution) ||
                      ''}
                  </div>
                  <div
                    style={{
                      width: '30%',
                      minWidth: '70px',
                    }}
                  >
                    Languages:
                  </div>
                  <div className="text-light" style={{ width: '70%' }}>
                    {applicant?.resume?.languages
                      ?.map((l) => l.language)
                      .join(', ')}
                  </div>
                </div>
              </Grid>

              {/*summary*/}

              <Grid item xs={12} sx={{}}>
                <b>Summary</b>
                <Divider sx={{ margin: '8px 0' }} />
                <div className="text-light" style={{ marginTop: '16px' }}>
                  {applicant?.resume?.summary ||
                    'The CV of the candidate has not included any summary.'}
                </div>
              </Grid>

              {/*skills*/}

              <Grid item xs={12} sx={{}}>
                <b>Skills</b>
                <Divider sx={{ margin: '8px 0' }} />
                <div className="d-flex wrap" style={{ marginTop: '12px' }}>
                  {(applicant?.resume?.skills || []).map((s: string, i) => (
                    <div key={s + i}>
                      <Chip label={s} sx={{ margin: '4px' }} />
                    </div>
                  ))}
                </div>
              </Grid>

              {/*experience*/}

              <Grid item xs={12}>
                <b>Experience</b>
                <Divider sx={{ margin: '8px 0' }} />

                {(applicant?.resume?.experience || []).map((ex, i, a) => (
                  <div key={JSON.stringify(ex) + i}>
                    <b>{ex.position}</b>
                    <div className="text-light">
                      {ex.company}, {ex.location} ({ex.start_date} -{' '}
                      {ex.end_date})
                    </div>
                    <p>{ex.description}</p>
                    {a.length - 1 !== i && (
                      <Divider
                        sx={{
                          my: 1,
                        }}
                      />
                    )}
                  </div>
                ))}
              </Grid>

              <Grid item xs={12}>
                <b>Education</b>
                <Divider sx={{ margin: '8px 0' }} />
                {applicant?.resume?.education?.length > 0 ? (
                  applicant.resume.education.map((educationItem, index) => (
                    <div key={index} style={{}}>
                      <b>{educationItem.institution}</b>
                      <br />
                      {educationItem.degree} (
                      {educationItem.graduation_start_date} -{' '}
                      {educationItem.graduation_end_date}), GPA:
                      {educationItem.gpa ? educationItem.gpa.toFixed(2) : 'N/A'}
                    </div>
                  ))
                ) : (
                  <div>No education information available</div>
                )}
              </Grid>

              <Grid item xs={12}>
                <b>Certifications</b>
                <Divider sx={{ margin: '8px 0' }} />
                {applicant?.resume?.certifications?.length > 0 ? (
                  applicant.resume.certifications.map(
                    (certification, index) => (
                      <div key={index} style={{}}>
                        <b>{certification.title}</b>
                        <br />
                        {certification.issuing_organization} (
                        {certification.issue_date} - {certification.expiry_date}
                        )
                      </div>
                    )
                  )
                ) : (
                  <div>No certifications information available</div>
                )}
              </Grid>
            </Grid>

            {applicant?.resume?.experience?.length ? (
              ''
            ) : (
              <p>{'application has not description'}</p>
            )}
          </Box>
        </CustomTabPanel>

        {/* tab 3 Notes */}

        <CustomTabPanel value={view} index={2}>
          <Box sx={{ p: 4, maxHeight: '60vh', overflowY: 'auto' }}>
            <Notes
              name="Applicant"
              entity_id={applicant?._id}
              notes={applicant?.notes || []}
            />
          </Box>
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
