import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import MainTitle from '../pices/MainTitle';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import useTo from '../../hooks/useTo';

export default function ContactUs() {
  const to = useTo();

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon />} sx={{ mb: '32px' }}>
        <Link
          underline="hover"
          key="2"
          color="inherit"
          href=""
          onClick={(e) => {
            e.preventDefault();
            to('/dashboard');
          }}
        >
          Home
        </Link>
        <Typography key="3" color="text.primary">
          Contact us
        </Typography>
      </Breadcrumbs>

      <MainTitle header="Contact us" />

      <Box sx={{ py: '2rem' }}></Box>
    </>
  );
}
